import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { v4 as uuid } from 'uuid';
import { AppContext } from '../../core/AppContext';
import { PanelComponent } from './panels/PanelComponent';
import { ReviewPanelComponent } from './panels/ReviewPanelComponent';
import { PanelStatuses } from './dashboard-container';

interface panel {
  header: string;
  description: string;
  workflowStepId: string;
  key: string;
}

interface Props {
  panels: panel[];
  isWorkflowComplete: boolean;
  panelStatuses: PanelStatuses;
}

export const DashboardComponent: React.FC<Props> = ({ 
  panels, isWorkflowComplete, panelStatuses
}) => (
  <Grid
    container
    direction="column"
    alignItems="stretch"
    justify="flex-start"
    spacing={24}
  >
    <Grid item>
      <AppContext.Consumer>
        {profileContext => (
          <Typography variant="h5">
            {`Welcome, ${profileContext.user.firstName}! Let’s create your profile.`}
          </Typography>
        )}
      </AppContext.Consumer>
    </Grid>
    <Grid item>
      {panels.map(panel => (
        <PanelComponent
          key={uuid()}
          header={panel.header}
          description={panel.description}
          route={`/profile/workflow/${panel.workflowStepId}`}
          isSummary={false}
          status={panelStatuses[panel.workflowStepId]}
          workflowStepId={panel.workflowStepId}
        />
      ))}
      <ReviewPanelComponent canReview={isWorkflowComplete} />
    </Grid>
  </Grid>
);
