import React, { Component } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './dialog-form.module.scss';
import { PersonInformationField } from '../../areas/crew-profile/workflow/question-forms/question-component';
import { PersonalInformationData } from '../../areas/crew-profile/workflow/question-forms/person-information';
import { InputErrorIcon } from '../input-error-icon';
import { FieldErrors } from '../../areas/account/common/FormErrors';


interface Props {
  showDialog: boolean,
  formTitle: string,
  formHelpText?: string,
  fields: PersonInformationField[],
  handleDialogClose: any,
  handleFieldChanges?: (key: string, value: string) => void,
  currentPerson?: PersonalInformationData,
  fieldErrors: FieldErrors,
  testingKey?: string,
}

interface State {
  person: PersonalInformationData,
}

export class DialogForm extends Component<Props, State> {
  state: State = {
    person: {} as PersonalInformationData,
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
    if (nextProps.currentPerson) {
      if (prevState.person) {
        if (nextProps.currentPerson !== prevState.person) {
          return { person: { ...nextProps.currentPerson } };
        }

        return prevState;
      }

      return { person: { ...nextProps.currentPerson } };
    }

    return { person: {} as PersonalInformationData };
  }

  onChange(key: string, value: string) {
    if (this.props.handleFieldChanges) {
      this.props.handleFieldChanges(key, value);
    }

    const personToUpdate = { ...this.state.person };
    personToUpdate[key] = value;
    this.setState({ person: { ...personToUpdate } });
  }

  render() {
    const { fields, fieldErrors } = this.props;
    const form = fields!.map((field) => {
      const onChangeFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.onChange(field.key, event.target.value);
      };
      const fieldValue = field.key in this.state.person ? this.state.person[field.key] : '';
      switch (field.key) {
        case 'email': {
          return (
            <TextField
              key={field.key}
              label={field.label}
              variant="filled"
              fullWidth
              margin="normal"
              onChange={onChangeFunction}
              value={fieldValue}
              error={!!fieldErrors.email}
              InputProps={{
                ...(fieldErrors.email && { endAdornment: <InputErrorIcon /> })
              }}
              name="email"
              helperText={fieldErrors.email}
              data-test-id={
                `dialogform__textfield--${field.key}${this.props.testingKey ? `--${this.props.testingKey}` : ''}`
              }
            />
          );
        }
        default: {
          return (
            <TextField
              key={field.key}
              label={field.label}
              variant="filled"
              fullWidth
              margin="normal"
              onChange={onChangeFunction}
              value={fieldValue}
              data-test-id={
                `dialogform__textfield--${field.key}${this.props.testingKey ? `--${this.props.testingKey}` : ''}`
              }
            />
          );
        }
      }
    });
    return (
      <React.Fragment>
        <Dialog open={this.props.showDialog} maxWidth="xs">
          <DialogContent className={styles.dialogContent}>
            <Grid container>
              <Grid
                item
                container
                justify="flex-end"
                alignItems="flex-start"
              >
                <IconButton 
                  onClick={this.props.handleDialogClose} 
                  className={styles.iconButton} 
                  data-test-id="dialogform__button--close"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Typography variant="h5">{this.props.formTitle}</Typography>
                {this.props.formHelpText && (
                  <Typography variant="body1" align="center" color="textSecondary">
                    {this.props.formHelpText}
                  </Typography>
                )}
                { form }
              </Grid>
              {this.props.children}
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
