import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

interface Props {
  className: string;
  returnUrl?: string;
  text: string;
  variant?: 'primary' | 'secondary';
  gutterTop?: boolean;
}

export class LogInLink extends Component<Props> {
  static defaultProps = {
    className: '',
    text: 'Already have a LASSO account? ',
    variant: 'primary'
  }

  render() {
    let link = '/account/login';
    if (this.props.returnUrl) {
      link += `?returnUrl=${this.props.returnUrl}`;
    }

    return (
      <Typography
        align="center"
        variant="body1"
        className={this.props.className}
        style={{ color: (this.props.variant === 'primary' ? 'default' : 'white') }}
      >
        {this.props.text}
        <Link
          to={link}
          data-test-id="login__link--login"
          style={{
            color: (this.props.variant === 'primary' ? 'inherit' : 'white'),
            textDecoration: 'underline'
          }}
        >
          Log in here.
        </Link>
      </Typography>
    );
  }
}
