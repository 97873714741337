import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from '../../../config';
import { EmailPhoneComponent } from './EmailPhoneComponent';
import { NewPasswordComponent } from './NewPasswordComponent';
import styles from './password-reset.module.scss';

enum PasswordResetStep {
  Username = 'USERNAME',
  Password = 'PASSWORD',
  Complete = 'COMPLETE'
}

interface State {
  username: string;
  password: string;
  step: PasswordResetStep;
  loading: boolean;
}

export const PasswordResetRequiredWorkflow: React.FC<any> = () => {
  const [state, setState] = useState<State>({
    username: '', password: '', step: PasswordResetStep.Username, loading: false
  });

  useEffect(() => {
    async function postPasswordReset() {
      const data: any = {
        password: state.password
      };
      if (state.username.includes('@')) {
        data.email = state.username;
      } else {
        data.phone = state.username;
      }

      await axios(`${config.apiRoot}/internal_api/auth/reset_password/`, {
        method: 'POST',
        data,
        withCredentials: true
      });

      window.location.reload();
    }

    if (state.password) {
      postPasswordReset();
    }
  }, [state.password]);

  return (
    <PasswordResetRequiredWorkflowComponent>
      {state.step === PasswordResetStep.Username && (
        <EmailPhoneComponent
          onContinue={val => setState(prev => ({ ...prev, username: val, step: PasswordResetStep.Password }))} 
        />
      )}
      {state.step === PasswordResetStep.Password && (
        <NewPasswordComponent onContinue={val => setState(prev => ({ ...prev, password: val }))} />
      )}
    </PasswordResetRequiredWorkflowComponent>
  );
};

const PasswordResetRequiredWorkflowComponent: React.FC<any> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Dialog open maxWidth="xs" BackdropProps={{ classes: { root: styles.backdrop } }}>
      <DialogContent className={styles.dialogContent}>
        <Grid container spacing={16} direction="column" justify="center">
          <Grid item>
            <Typography variant="h5" align="center">
              {t('AREAS.ACCOUNT.PASSWORD_RESET.PASSWORD_RESET_REQUIRED')}
            </Typography>
          </Grid>          
          <Grid item container xs={12} justify="center">
            {children}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
