import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import styles from './requiredness-marker.module.scss';

interface Props {
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6',
  renderAs: 'required'|'optional',
}

export class RequirednessMarker extends Component<Props> {
  render() {
    const Required = () => (
      <Typography
        className={styles.requiredMarker}
        variant={this.props.textVariant}
        color="error"
        component="span"
        inline
      >
        *
      </Typography>
    );
    const Optional = () => (
      <Typography
        variant={this.props.textVariant}
        component="span"
        inline
      >
        &nbsp;(optional)
      </Typography>
    );
    let marker;
    if (this.props.renderAs === 'required') {
      marker = <Required />;
    } else {
      marker = <Optional />;
    }
    return (
      <React.Fragment>
        { marker }
      </React.Fragment>
    );
  }
}
