import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography } from '@material-ui/core';
import { NormalLayout } from '../../../common/layouts/NormalLayout';
import { RegistrationHeader } from '../common/RegistrationHeader';
import { InputErrorIcon } from '../../../common/input-error-icon';
import { Button } from '../../../common/button';
import { Snackbar } from '../../../common/snackbar';
import { SignupErrorComponent } from '../common/SignupErrorComponent';
import { ForgotPasswordForm } from './ForgotPasswordContainer';
import { FieldErrors } from '../common/FormErrors';
import { CreatePasswordInput } from '../common/CreatePasswordInput';
import styles from './forgot-password.module.scss';

interface Props {
  onSubmit(): Promise<void>;
  onChange(change: { [fieldName: string]: string }): void;
  onResendCode(): Promise<void>;
  values: ForgotPasswordForm;
  fieldErrors: FieldErrors;
  error?: string;
  codeDestination: string;
}

interface LocalState {
  showSnackbar: boolean;
  validatedPassword: boolean;
}

export const ForgotPasswordComponent: React.FC<Props> = ({ 
  onSubmit, onChange, onResendCode, values, fieldErrors, error, codeDestination
}) => {
  const [state, setState] = useState<LocalState>({ showSnackbar: false, validatedPassword: false });
  const { t } = useTranslation();

  return (
    <NormalLayout>
      <Grid container direction="column" spacing={8}>
        {error && (
          <SignupErrorComponent
            title={t('AREAS.ACCOUNT.LOGIN.ERRORS.ERROR_TITLE')}
            text={error}
          />
        )}
        <Grid item>
          <RegistrationHeader
            title={t('AREAS.ACCOUNT.LOGIN.FORGOT_PASSWORD_TITLE')}
          />
        </Grid>
        <Grid item>
          <form onSubmit={async (e) => {
            e.preventDefault();
            await onSubmit();
          }}
          >
            <Grid container spacing={16} direction="column" alignItems="stretch">
              <Grid item container direction="row" alignItems="center" justify="center">
                <Typography align="center" variant="body1">
                  {t('AREAS.ACCOUNT.LOGIN.CODE_DESTINATION', { codeDestination })}
                </Typography>
              </Grid>            
              <Grid item>
                <CreatePasswordInput 
                  password={values.password}
                  onChange={password => onChange({ password })}
                  onValid={(value: boolean) => { setState({ ...state, validatedPassword: value }); }}
                />
              </Grid> 
              <Grid item>
                <TextField
                  label={t('AREAS.ACCOUNT.LOGIN.VERIFICATION_CODE')}
                  variant="filled"
                  fullWidth
                  margin="normal"
                  value={values.code}
                  onChange={e => onChange({ code: e.target.value })}
                  helperText={fieldErrors.code}
                  error={!!fieldErrors.code}
                  InputProps={{
                    ...(fieldErrors.code && { endAdornment: <InputErrorIcon /> })
                  }}
                  inputProps={{ maxLength: 6 }}
                />
              </Grid>
              <Grid item container justify="center" className={styles.resendLink}>
                <Button
                  variant="text"
                  size="small"
                  onClick={async () => { 
                    await onResendCode();
                    setState({ ...state, showSnackbar: true });
                  }}
                >
                  {t('AREAS.ACCOUNT.LOGIN.RESEND_CODE')}
                </Button>
              </Grid>

              <Grid item container xs={12} justify="center">
                <Button
                  wide
                  disabled={Object.keys(fieldErrors).length > 0 || !state.validatedPassword}
                  type="submit"
                >
                  {t('AREAS.ACCOUNT.LOGIN.CONTINUE')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Snackbar
        open={state.showSnackbar}
        autoHideDuration={3000}
        message={t('AREAS.ACCOUNT.LOGIN.CODE_RESENT')}
        onClose={() => setState({ ...state, showSnackbar: false })}
      />
    </NormalLayout>
  );
};
