import React, { useEffect } from 'react';
import { LoadingComponent } from '../loading-component';

interface Props {
  to: string;
}

export const ExternalRedirect: React.FC<Props> = ({ to }) => {
  useEffect(() => window.location.assign(to), []);
  return <LoadingComponent />;
};
