import React, { useContext, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import { DashboardContainer } from './dashboard/DashboardContainer';
import { AppContext, WritableAppState } from '../core/AppContext';
import { LoadingComponent } from '../../common/loading-component';
import config from '../../config';


export const ProfileContainer: React.FC<RouteComponentProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const { user, account, setAppContext } = useContext(AppContext);

  useEffect(() => {
    if (user.isAuthenticated) {
      if (!user.accountRelationships.find(aur => aur.account.slug === account.slug)) {
        setLoading(true);
        createAccountUserRelationship(setAppContext)
          .then(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
  }, [user, account]);

  if (loading) {
    return <LoadingComponent />;
  }

  return <DashboardContainer {...props} />;
};


type SetAppContext = (state: WritableAppState, cb?: () => void) => void;
const createAccountUserRelationship = async (setAppContext: SetAppContext) => {
  try {
    const response = await axios(`${config.apiRoot}/internal_api/create_aur`, {
      method: 'POST',
      withCredentials: true
    });
    
    setAppContext({ user: response.data.user });
  } catch (e) {
    console.error(e);
  }
};
