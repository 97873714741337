import React from 'react';
import {
  Typography,
  Paper,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CopyTextInput } from '../../../common/copy-text-input';
import styles from './share-referral-link.module.scss';

interface Props {
  referralLink: string;
}

export const ShareReferralLink: React.FC<Props> = ({ referralLink }) => {
  const { t } = useTranslation();
  return (
    <Paper
      square
      className={styles.paper}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="space-between"
        spacing={16}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justify="space-between"
          spacing={8}
        >
          <Grid item>
            <Typography align="center" variant="h6" className={styles.heading}>
              {t('AREAS.CREW_PROFILE.INVITE')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center" variant="subtitle2">
              {t('AREAS.CREW_PROFILE.INVITE_SECTION_1')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <CopyTextInput includeSnackbar inputText={referralLink} />
        </Grid>
      </Grid>
    </Paper>
  );
};
