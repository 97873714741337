import React, { Component } from 'react';
import {
  SingleSelectAutocomplete,
  SingleSelectAutocompleteProps,
  Option,
} from './SingleSelectAutocomplete';

interface State {
  selected?: Option;
}

export class SingleSelectAutocompleteContainer extends Component<SingleSelectAutocompleteProps, State> {
  state:State = {
    selected: undefined,
  }

  componentWillMount() {
    const selectedOption = this.props.selected;
    this.setState({ selected: selectedOption });
  }

  render() {
    return (
      <SingleSelectAutocomplete
        onChange={this.props.onChange}
        placeholder={this.props.placeholder}
        helpText={this.props.helpText}
        selected={this.state.selected}
        options={this.props.options}
        question={this.props.question}
        textVariant={this.props.textVariant}
        status={this.props.status}
        displayRequirednessMarker={this.props.displayRequirednessMarker}
        testingKey={this.props.testingKey}
      />
    );
  }
}
