import { DisplayValuePair } from '../../../common/types/input-options';
import {
  DropdownSelectOption, PersonInformationField
} from '../workflow/question-forms/question-component';
import { PersonalInformationData } from '../workflow/question-forms/person-information';
import { Option } from '../workflow/question-forms/autocomplete/single-select-autocomplete';
import { FileSubmission } from '../review/submission';

export interface QuestionDetails {
  key: string;
  question: string;
  suffix?: string;
  helpText?: string;
  input: 'shortAnswer'
  | 'multipleSelectCheckbox'
  | 'multipleChoiceRadio'
  | 'fileUpload'
  | 'date'
  | 'dropdownSelect'
  | 'personInformation'
  | 'currency'
  | 'singleSelectAutocomplete'
  | 'airportAutocomplete';
  status: 'required' | 'optional';
  type: 'standard' | 'autocomplete' | 'custom' | 'person';
  options?: DisplayValuePair[] | DropdownSelectOption[] | { [category: string]: DisplayValuePair[] };
  acceptedFileTypes?: string[];
  maxInputs?: number;
  personInformationFields?: PersonInformationField[];
  placeholder?: string;
  followupQuestion?: FollowupQuestionDetails;
}

export interface FollowupQuestionDetails extends QuestionDetails {
  on: string[];
}

export interface Panel {
  questionPageHeader: string;
  header: string;
  helpText?: string;
  description: string;
  workflowStepId: string;
  key: string;
  questions?: QuestionDetails[];
  defaultReponses?: object;
}

export interface ProfileResponse {
  [key: string]: string | string[] | PersonalInformationData[] | FileSubmission | Option;
}

export enum PanelStatus {
  Disabled = 'DISABLED',
  GetStarted = 'GET_STARTED',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE'
}

export interface PanelStatuses {
  [workflowId: string]: PanelStatus;
}

export interface ValidationStatus {
  [key: string]: string;
}

export interface State {
  panels: Panel[];
  loaded: boolean;
  responses: ProfileResponse;
  panelStatuses: PanelStatuses;
  validationStatus: ValidationStatus;
  requirednessMarker: 'required' | 'optional';
  currencySymbol: string;
  referralLink: string;
}

export interface QuestionSetValidationResult {
  validQuestionsKeys: string[],
  questionsWithError: { [key: string]: string }
}
