/*
 * This is intended to be a temporary solution because currently marketplace
 * will only take US (+1) numbers
*/
export const validatePhone = (inputPhone: string, userPhone: string): boolean => {
  if (userPhone === inputPhone) {
    return true;
  }
  // removes spaces, (, ), -
  let strippedInputPhone = inputPhone.replace(/-|\(|\)|\s/g, '');

  if (strippedInputPhone.length === 10) {
    strippedInputPhone = `+1${strippedInputPhone}`;
  } else if (strippedInputPhone.length === 11) {
    strippedInputPhone = `+${strippedInputPhone}`;
  }
  return (strippedInputPhone === userPhone);
};
