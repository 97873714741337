import React, { Component } from 'react';
// eslint-disable-next-line import/no-named-default
import { default as MatButton, ButtonProps } from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import * as History from 'history';
import styles from './button.module.scss';

type Props = {
  to?: History.LocationDescriptor<any>;
  wide?: boolean;
} & ButtonProps

export class Button extends Component<Props> {
  render() {
    let linkProps;
    if (this.props.to) {
      linkProps = {
        component: Link,
        to: this.props.to,
      };
    }
    const buttonProps = { ...this.props, wide: undefined };

    return (
      <MatButton
        {...{ ...linkProps } as any}
        variant="contained"
        size="large"
        color="primary"
        className={this.props.wide ? styles.wide : ''}        
        {...buttonProps}
      >
        {this.props.children}
      </MatButton>
    );
  }
}
