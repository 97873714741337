/* eslint-disable max-len */
import React, { Component } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export class WordFileIcon extends Component<SvgIconProps> {
  render() {
    return (
      <SvgIcon {...this.props}>
        <path d="M18.75,3 C19.9875,3 21,4.0125 21,5.25 L21,18.75 C21,19.9875 19.9875,21 18.75,21 L5.25,21 C4.0125,21 3,19.9875 3,18.75 L3,5.25 C3,4.0125 4.0125,3 5.25,3 L18.75,3 Z M5.75,6.5 L8.08180428,18.058104 L10.3753823,18.058104 L11.8853211,10.6555056 C11.9546061,10.3148509 12.0191131,9.86789265 12.0573394,9.34120616 L12.0764526,9.34120616 C12.0931768,9.81957281 12.1314031,10.2689469 12.2102446,10.6555056 L13.6819572,18.058104 L15.8990826,18.058104 L18.25,6.5 L16.2240061,6.5 L15.0007645,14.2118453 C14.9290905,14.6467238 14.8836966,15.0816023 14.8669725,15.5068168 L14.8478593,15.5068168 C14.807244,14.9632188 14.7666287,14.5549164 14.7140673,14.2698291 L13.2614679,6.5 L11.1207951,6.5 L9.51529052,14.1731894 C9.41255719,14.6636359 9.34088318,15.11301 9.32415902,15.5261447 L9.28593272,15.5261447 C9.26204159,14.9728828 9.22620428,14.5210922 9.17125382,14.1925174 L7.92889908,6.5 L5.75,6.5 Z" />
      </SvgIcon>
    );
  }
}
