import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@material-ui/core';
import { NormalLayout } from '../../../common/layouts/NormalLayout';
import { RegistrationHeader } from '../common/RegistrationHeader';
import { Button } from '../../../common/button';
import { InputErrorIcon } from '../../../common/input-error-icon';
import styles from './password.module.scss';

interface Props {
  onForgotPassword(): void;
  onSubmit(password: string): Promise<void>;
  error?: string;
}

interface LocalState {
  password: string;
}

export const PasswordComponent: React.FC<Props> = ({ onSubmit, onForgotPassword, error }) => {
  const [state, setState] = useState<LocalState>({ password: '' });
  const { t } = useTranslation();

  return (
    <NormalLayout>
      <Grid container direction="column" spacing={8}>
        <Grid item>
          <RegistrationHeader
            title={t('AREAS.ACCOUNT.LOGIN.LOGIN_TITLE')}
          />
        </Grid>
        <Grid item>
          <form onSubmit={async (e) => {
            e.preventDefault();
            await onSubmit(state.password);
          }}
          >
            <Grid container spacing={16} direction="column" alignItems="stretch">
              <Grid item>
                <TextField
                  label={t('AREAS.ACCOUNT.LOGIN.PASSWORD')}
                  variant="filled"
                  fullWidth
                  autoFocus
                  type="password"
                  margin="normal"
                  value={state.password}
                  onChange={e => setState({ ...state, password: e.target.value })}
                  error={!!error}
                  helperText={error}
                  InputProps={{
                    ...(!!error && { endAdornment: <InputErrorIcon /> })
                  }}
                  inputProps={{ maxLength: 128 }}
                />
              </Grid>
              <Grid item container xs={12} justify="center">
                <Button
                  wide
                  disabled={state.password.trim().length === 0}
                  type="submit"
                >
                  {t('AREAS.ACCOUNT.LOGIN.CONTINUE')}
                </Button>
              </Grid>
              <Grid item container justify="center" className={styles.forgotPasswordLink}>
                <Button variant="text" size="small" onClick={() => { onForgotPassword(); }}>
                  {t('AREAS.ACCOUNT.LOGIN.FORGOT_PASSWORD')}
                </Button>                
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </NormalLayout>
  );
};
