import axios, { AxiosResponse } from 'axios';

export async function userConfig(username: string) {
  // If an email address or phone is not not passed in, just return null.
  let qs = null;
  if (username.includes('@')) {
    qs = `email=${username}`;
  } else if (username.startsWith('+')) {
    qs = `phone_number=${username.replace('+', '%2B')}`;
  }
  try {
    const response: AxiosResponse = await axios(`${process.env.REACT_APP_COGNITO_MIGRATION_API}?${qs}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (e) {
    return { error: e.message };
  }
}

export async function migrateUser(username: string, password: string, type: string) {
  try {
    const response: AxiosResponse = await axios(`${process.env.REACT_APP_COGNITO_MIGRATION_API}`, {
      method: 'POST',
      data: { 
        username, password, type, user_pool_id: process.env.REACT_APP_COGNITO_USER_POOL_ID 
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (e) {
    return { error: e.message };
  }
}
