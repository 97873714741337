import { Panel, ProfileResponse } from '../dashboard/dashboard-container';
import { Option } from '../workflow/question-forms/autocomplete/single-select-autocomplete';
import { getCurrentQuestionValueForFollowupQuestion } from '../dashboard/DashboardContainer';

/* eslint-disable camelcase */

export interface RegistrationSubmission {
  custom_fields: CustomFieldSubmission[];
  requested_positions: RequestedPositionSubmission[];
  [key: string]: any;
}

interface CustomFieldSubmission {
  uuid: string;
  value: string;
  file: FileSubmission | null;
}

interface RequestedPositionSubmission {
  position_id: string;
  desired_rate: string;
}

export interface FileSubmission {
  document_id: number;
  name: string;
  type: string;
}

export function createSubmission(panels: Panel[], responses: ProfileResponse) {
  const result: RegistrationSubmission = {
    custom_fields: [],
    requested_positions: [],
    contacts: {}
  };

  panels.forEach((panel) => {
    if (panel.questions) {
      panel.questions.forEach((question) => {
        if (question.key in responses) {
          if (question.key === 'requested_positions') {
            (responses[question.key] as string[]).forEach((position) => {
              result.requested_positions.push({
                position_id: position,
                desired_rate: getCurrentQuestionValueForFollowupQuestion(question, position, responses) as string
              });
            });
          } else if (question.key === 'resume') {
            result[question.key] = responses[question.key];
          } else if (question.type === 'autocomplete') {
            result[question.key] = (responses[question.key] as Option).value;
          } else if (question.type === 'standard') {
            result[question.key] = responses[question.key];
          } else if (question.type === 'person') {
            result.contacts[question.key] = responses[question.key];
          } else {
            result.custom_fields.push(
              {
                uuid: question.key,
                value: question.input === 'fileUpload' ? '' : responses[question.key] as string,
                file: question.input === 'fileUpload' ? responses[question.key] as FileSubmission : null
              }
            );
          }
        }
      });
    }
  });

  return result;
}
