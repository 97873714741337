import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@material-ui/core';
import { Button } from '../../../common/button';
import { InputErrorIcon } from '../../../common/input-error-icon';

interface Props {
  onContinue(password: string): void;
}

interface State {
  password: string;
  canContinue: boolean;
}

export const NewPasswordComponent: React.FC<Props> = ({ onContinue }) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({ password: '', canContinue: false });    

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      setState(prev => ({ ...prev, canContinue: false }));
      onContinue(state.password);
    }}
    >
      <Grid container spacing={16} direction="column" alignItems="stretch">
        <Grid item>
          <PasswordInput onChange={val => setState(prev => ({ ...prev, password: val }))} />
          <PasswordConfirmationInput 
            password1={state.password}
            onChange={isValid => setState(prev => ({ ...prev, canContinue: isValid }))}
          />
        </Grid>
        <Grid item container xs={12} justify="center">
          <Button
            wide
            disabled={!state.canContinue}
            type="submit"
          >
            {t('AREAS.ACCOUNT.PASSWORD_RESET.CONTINUE')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

interface PasswordInputProps {
  onChange(value: string): void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  onChange
}) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const { value } = e.target;
    setPassword(value);
    onChange(value);
  }, []);

  return (
    <TextField
      label={t('AREAS.ACCOUNT.PASSWORD_RESET.PASSWORD')}
      variant="filled"
      fullWidth
      autoFocus
      type="password"
      margin="normal"
      value={password}
      onChange={handleChange}
      inputProps={{ maxLength: 254 }}
    />
  );
};

interface PasswordConfirmationProps {
  password1: string;
  onChange(isValid: boolean): void;
}

const PasswordConfirmationInput: React.FC<PasswordConfirmationProps> = ({
  password1, onChange
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  
  useEffect(() => {
    let isValid: boolean;
    if (value !== '' && password1 !== value) {
      isValid = false;
      setError(t('AREAS.ACCOUNT.PASSWORD_RESET.PASSWORD_MUST_MATCH'));
    } else {
      isValid = true;
      setError('');
    }
    onChange(isValid);
  }, [password1, value]);

  return (
    <TextField
      label={t('AREAS.ACCOUNT.PASSWORD_RESET.REENTER_PASSWORD')}
      variant="filled"
      fullWidth
      type="password" 
      margin="normal"
      value={value}
      name="password2"
      onChange={e => setValue(e.target.value)}
      inputProps={{ maxLength: 254 }}
      helperText={error}
      error={!!error}
      InputProps={{
        ...(error && { endAdornment: <InputErrorIcon /> })
      }}
    />
  );
};
