import React, { Component } from 'react';
import {
  Typography,
  Grid,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import { PolicyAgreementComponent, PolicyObject } from './PolicyAgreementComponent';
import { Button } from '../../../../common/button';
import styles from './policy-agree-modal.module.scss';

interface State {
  isChecked: boolean;
  formSubmitted: boolean;
}

interface Props {
  show: boolean;
  policiesNeeded: Array<PolicyObject>,
  onAcceptPolicies(): void;
}

export class PolicyAgreeModalComponent extends Component<Props, State> {
  state: State = {
    isChecked: false,
    formSubmitted: false,
  };

  handleCheckedbox = () => {
    if (!this.state.isChecked) {
      this.setState({ isChecked: true });
    } else {
      this.setState({ isChecked: false });
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <Dialog open={this.props.show} maxWidth="xs" BackdropProps={{ classes: { root: styles.backdrop } }}>
        <DialogContent className={styles.dialogContent}>
          <Grid container spacing={16} direction="column" justify="center">
            <Grid item>
              <Typography variant="h5" align="center">User Agreements and Policies</Typography>
            </Grid>
            <Grid item>
              <PolicyAgreementComponent
                checked={this.state.isChecked}
                handleCheckedbox={this.handleCheckedbox}
                policiesNeeded={this.props.policiesNeeded}
              />
            </Grid>
            <Grid item container xs={12} justify="center">
              <form onSubmit={(e) => {
                e.preventDefault();
                this.setState({ formSubmitted: true });
                this.props.onAcceptPolicies();
              }}
              >
                <Button
                  wide
                  disabled={!this.state.isChecked || this.state.formSubmitted}
                  type="submit"
                  data-test-id="continue"
                >
                Continue
                </Button>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}
