import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { CloseButton } from '../close-button';

type Props = {
  linkText?: string;
  testingKey?: string;
  onClick(): void;
}

export const ClearButton: React.FC<Props> = ({ linkText, onClick, testingKey }) => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    onClick={onClick}
  >
    {linkText && (
      <Grid item>
        <Typography variant="body1" color="primary">{linkText}</Typography>
      </Grid>
    )}
    <Grid item>
      <CloseButton onClick={onClick} data-test-id={`clearbutton__closebutton--${testingKey}`} />
    </Grid>
  </Grid>
);
