import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { NormalLayout } from '../../../common/layouts/NormalLayout';
import { RegistrationHeader } from '../common/RegistrationHeader';
import { Button } from '../../../common/button';
import { InputErrorIcon } from '../../../common/input-error-icon';


interface Props {
  onSubmit(username: string): void;
  error?: string
}

interface LocalState {
  username: string;
}

export const LoginInitComponent: React.FC<Props> = (props) => {
  const { onSubmit, error } = props;
  const [state, setState] = useState({ username: '' });
  const { t } = useTranslation();

  return (
    <NormalLayout>
      <Grid container direction="column" spacing={8}>
        <Grid item>
          <RegistrationHeader
            title={t('AREAS.ACCOUNT.LOGIN.LOGIN_TITLE')}
          />
        </Grid>
        <Grid item>
          <form onSubmit={(e) => {
            e.preventDefault();
            onSubmit(state.username);
          }}
          >
            <Grid container spacing={16} direction="column" alignItems="stretch">
              <Grid item>
                <TextField
                  label={t('AREAS.ACCOUNT.LOGIN.USERNAME')}
                  variant="filled"
                  fullWidth
                  autoFocus
                  margin="normal"
                  value={state.username}
                  onChange={e => setState({ ...state, username: e.target.value })}
                  helperText={error}
                  error={!!error}
                  InputProps={{
                    ...(error && { endAdornment: <InputErrorIcon /> })
                  }}
                  inputProps={{ maxLength: 254 }}
                />
              </Grid>
              <Grid item container xs={12} justify="center">
                <Button
                  wide
                  disabled={state.username.trim().length === 0}
                  type="submit"
                >
                  {t('AREAS.ACCOUNT.LOGIN.CONTINUE')}
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="body1" align="center">
                  {t('AREAS.ACCOUNT.LOGIN.NO_ACCOUNT')}
                  <Link to="/account/signup">{t('AREAS.ACCOUNT.LOGIN.CREATE_ACCOUNT')}</Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </NormalLayout>
  );
};
