import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '../../../../common/button';

interface Props {
  canReview: boolean;
}

export const ReviewPanelComponent: React.FC<Props> = ({ canReview }) => (  
  <Grid
    container
    direction="column"
    alignItems="stretch"
    justify="flex-start"
    spacing={24}
  >
    <Grid
      item
      container
      direction="column"
      alignItems="stretch"
      justify="flex-start"
      spacing={8}
    >
      <Grid
        item
        container
        direction="row"
        alignItems="flex-start"
        justify="space-between"
      >
        <Grid item xs={10} sm={11}>
          <Typography
            color={canReview ? 'textPrimary' : 'textSecondary'}
            variant="h6"
          >
            <b>Review and submit your application</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={11} sm={11}>
        <Typography color="textSecondary" variant="body1" />
      </Grid>
      {canReview && (
      <Grid item>
        <Button to="/profile/review" data-test-id="continue">Continue</Button>
      </Grid>
      )}
    </Grid>
  </Grid>
);
