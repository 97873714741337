import React from 'react';
import { TextField, Typography, Snackbar } from '@material-ui/core';
import Create from '@material-ui/icons/Create';
import Grid from '@material-ui/core/Grid';
import { Button } from '../../../common/button';
import { InputErrorIcon } from '../../../common/input-error-icon';
import { NormalLayout } from '../../../common/layouts/NormalLayout';
import { RegistrationHeader } from '../common/RegistrationHeader';
import { FormErrors } from '../common/FormErrors';
import { SignupErrorComponent } from '../common/SignupErrorComponent';
import styles from './code-verification.module.scss';

interface Props {
  codeDestination: string;
  code: string;
  canSubmit: boolean;
  error?: FormErrors;
  showSnackbar: boolean;
  handleClose: () => void;
  onChange(code: string): void;
  onSubmit(): void;
  onResendCode(): void;
  editPath: () => void;
}

export const CodeVerificationComponent: React.FC<Props> = (props) => {
  const hasCodeError = showCodeError(props.error);
  const genericError = props.error ? props.error.generic : null;

  return (
    <NormalLayout>
      <Grid container direction="column" spacing={8}>
        {genericError && (
          <SignupErrorComponent
            title={genericError.title}
            text={genericError.text}
          />
        )}
        <Grid item>
          <RegistrationHeader
            title="We sent you a verification code"
          />
        </Grid>
        <Grid item>
          <form onSubmit={(e) => {
            e.preventDefault();
            props.onSubmit();
          }}
          >
            <Grid container spacing={16} direction="column" alignItems="stretch">
              <Grid item container direction="row" alignItems="center" justify="center">
                <Typography align="center" variant="body1">
                  {`Enter the code sent to the number ending in: (xxx) xxx-${props.codeDestination.slice(-4)} `}
                  <Create color="primary" fontSize="small" onClick={props.editPath} className={styles.createIcon} />
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={props.editPath}
                    onKeyDown={props.editPath}
                    className={styles.editText}
                    data-test-id="codeVerification-btn-edit"
                  >
                    Edit
                  </span>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  label="Verification code"
                  type="tel"
                  placeholder="######"
                  variant="filled"
                  fullWidth
                  autoFocus
                  margin="normal"
                  value={props.code}
                  onChange={e => props.onChange(e.target.value)}
                  error={hasCodeError}
                  helperText={hasCodeError && (
                    <span data-test-id="codeVerification-textfield-code-error">
                      You have entered an invalid code. Please try again.
                    </span>
                  )
                  }
                  InputProps={{
                    ...(hasCodeError && { endAdornment: <InputErrorIcon /> })
                  }}
                  inputProps={{ maxLength: 6 }}
                  data-test-id="codeVerification-textfield-code"
                />
              </Grid>
              <Grid item container justify="center" className={styles.resendLink}>
                <Button
                  variant="text"
                  size="small"
                  onClick={(e) => { e.preventDefault(); props.onResendCode(); }}
                  data-test-id="codeVerification-btn-resendCode"
                >
                  Resend code
                </Button>
              </Grid>
              <Grid item container justify="center" xs={12}>
                <Button wide disabled={!props.canSubmit} type="submit" data-test-id="codeVerification-btn-submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Snackbar
        open={props.showSnackbar}
        autoHideDuration={3000}
        onClose={props.handleClose}
        ContentProps={{ className: styles.snackbarContent }}
        message={(
          <Typography variant="body1" color="textSecondary" className={styles.message}>
            The verification code has been resent to your phone
          </Typography>
        )}
      />
    </NormalLayout>
  );
};

function showCodeError(errors?: FormErrors): boolean {
  if (!errors) return false;

  if (errors.fieldErrors && errors.fieldErrors.code) {
    return true;
  }

  return false;
}
