import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { BrowserRouter as Router } from 'react-router-dom';
import styles from './App.module.scss';
import { registerInterceptors } from './csrf-interceptors';
import { InitializeAppContainer } from './InitializeAppContainer';
import { GenericRequestError } from './GenericRequestError';
import { PolicyAcceptanceRequiredError } from './PolicyAcceptanceRequiredError';
import { AppState } from './AppContext';
import { AppRoutes } from './AppRoutes';
import { PasswordResetRequired } from './password-reset';

registerInterceptors();

const themeArgs = {
  palette: {
    primary: {
      main: '#325fff',
      dark: '#325fff',
    },
    text: {
      primary: '#212121',
      secondary: '#616161',
    },
    divider: '#bdbdbd',
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    useNextVariants: true,
    fontSize: 16,
  },
  overrides: {
    MuiButton: { // Name of the component ⚛️ / style sheet
      text: { // Name of the rule
        color: 'white', // Some CSS
      },
      root: {
        fontSize: '18px',
        textTransform: 'none',
        borderRadius: '25px',
        '&:hover': {
          boxShadow:
            '0 5px 3px -2px rgba(33, 33, 33, 0.16), 0 4px 6px 0 rgba(0, 0, 0, 0.18), 0 4px 6px 0 rgba(0, 0, 0, 0.04)',
        },
      },
      sizeLarge: {
        fontSize: '18px',
        fontWeight: 'bold',
        height: '50px',
        // boxShadow: 'none'
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#0036cb',
        },
      },
      outlinedPrimary: {
        border: '1px solid #325fff',
        '&:hover': {
          color: '#0036cb',
          backgroundColor: '#fff',
          border: '1px solid #oo36cb',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'none',
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#f5f5f5',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        color: '#212121',
        fontSize: '18px',
        '&$focused': {
          backgroundColor: '#f5f5f5',
        },
      },
      focused: {},
      input: {
        backgroundColor: '#f5f5f5',
        padding: '26px 12px 9px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#616161',
        fontSize: '18px',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '16px',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '0',
        padding: '0',
        margin: '24px'
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 16px 16px 16px',
      },
    },
    MuiFormHelperText: {
      root: {
        color: '#616161',
      },
      contained: {
        margin: '8px 0',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#616161',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'none',
        },
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '24px',
      },
      h1: {
        fontSize: '4.5rem',
        fontWeight: 'normal',
        lineHeight: 1.19444,
      },
      h2: {
        fontSize: '3.625rem',
        fontWeight: 'normal',
        lineHeight: 1.18965,
      },
      h3: {
        fontSize: '2.875rem',
        fontWeight: 'normal',
        lineHeight: 1.19565,
      },
      h4: {
        fontSize: '2.25rem',
        fontWeight: 'bold',
        lineHeight: 1.2222,
      },
      h5: {
        fontSize: '1.75rem',
        fontWeight: 'bold',
        lineHeight: 1.2142,
      },
      h6: {
        fontSize: '1.375rem',
        fontWeight: 'normal',
        lineHeight: 1.2272,
      },
      subtitle1: {
        fontSize: '1.25rem',
        lineHeight: 1.2222,
      },
      subtitle2: {
        fontSize: '1.125rem',
        lineHeight: 1.33,
      },
      body1: {
        fontSize: '1rem',
        lineHeight: 1.1875,
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: 1.5,
      },
      caption: {
        fontSize: '0.75rem',
        lineHeight: 1.5,
      },
    },
    MuiGrid: {
      'spacing-xs-8': {
        margin: '-4px -4px 0 -4px',
      },
      'spacing-xs-16': {
        margin: '-8px -8px 0 -8px',
      },
      'spacing-xs-24': {
        margin: '-12px -12px 0 -12px',
      },
      'spacing-xs-32': {
        margin: '-16px -16px 0 -16px',
      },
      'spacing-xs-40': {
        margin: '-20px -20px 0 -20px',
      },
    },
    MuiPaper: {
      root: {
        padding: '24px',
      },
    },
    MuiLinearProgress: {
      determinate: {
        backgroundColor: '#e0e0e0',
      },
    },
    MuiListItem: {

    }
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
};
const theme = createMuiTheme(themeArgs as ThemeOptions);

class App extends Component<any> {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router>
            <AppState>
              <PolicyAcceptanceRequiredError />
              <GenericRequestError />
              <PasswordResetRequired>
                <InitializeAppContainer>
                  <main className={styles.app}>
                    <AppRoutes />
                  </main>
                </InitializeAppContainer>
              </PasswordResetRequired>
            </AppState>
          </Router>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
