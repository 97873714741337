import React from 'react';
import { Grid, Typography, FormHelperText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { QuestionHeading } from '../question-heading';
import { Button } from '../../../../../common/button';
import { UploadedFileChipContainer } from './UploadedFileChipContainer';
import styles from './file-upload.module.scss';
import { FileObject } from './FileUploadContainer';


interface Props {
  question: string;
  helpText?: string;
  textVariant: 'body1' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  status: 'required' | 'optional';
  displayRequirednessMarker: boolean;
  acceptedFileTypes: string;
  maxInputs?: number;
  selectedFile?: FileObject;
  handleFileInputChange: any;
  handleChipDelete: any;
  errorMessage?: string;
  testingKey?: string;
}

export const FileUploadComponent: React.FC<Props> = (props) => {
  const { selectedFile } = props;
  return (
    <>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <QuestionHeading
            question={props.question}
            textVariant={props.textVariant}
            status={props.status}
            displayRequirednessMarker={props.displayRequirednessMarker}
          />
          <Typography variant="body1" color="textSecondary">
            {props.helpText}
          </Typography>
        </Grid>
        {selectedFile && (
          <Grid
            item
            container
            spacing={8}
            xs={12}
          >
            <Grid item>
              <UploadedFileChipContainer
                loading={selectedFile.loading}
                fileType={selectedFile.type}
                fileChipLabel={selectedFile.name}
                handleChipDelete={props.handleChipDelete}
              />
            </Grid>
          </Grid>
        )}
        {!selectedFile && (
          <Grid item xs={5}>
            <label htmlFor="fileInput">
              <input
                id="fileInput"
                className={styles.fileInput}
                type="file"
                onChange={e => props.handleFileInputChange(e.target.files)}
                accept={props.acceptedFileTypes}
                value=""
              />
              <Button
                className={styles.uploadButton}
                size="small"
                variant="outlined"
                component="span"
                data-test-id={`fileupload__button--addfile--${props.testingKey}`}
              >
                <AddIcon />
                Add file
              </Button>
            </label>
          </Grid>
        )}
      </Grid>
      {props.errorMessage && (
        <FormHelperText
          error
          data-test-id={`fileupload__error--${props.testingKey}`}
        >
          {props.errorMessage}
        </FormHelperText>
      )}
    </>
  );
};
