import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './end-user-license-agreement.module.scss';

interface Props {
  endUserLicenseAgreementHeadline: string;
  endUserLicenseAgreement: string;
}

export const EndUserLicenseAgreement = class extends Component<Props, {}> {
  static defaultProps = {
    endUserLicenseAgreementHeadline: 'LASSO Terms of Service',
    // eslint-disable-next-line
    endUserLicenseAgreement: 'End User License Agreement\n\nTo be determined. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eget sit amet tellus cras adipiscing enim. \n\nNullam vehicula ipsum a arcu. Diam phasellus vestibulum lorem sed risus ultricies. Consequat semper viverra nam libero justo. Ut lectus arcu bibendum at varius. \n\nRhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Pellentesque eu tincidunt tortor aliquam nulla facilisi. Cursus turpis massa tincidunt dui ut ornare lectus sit amet. Nulla aliquet enim tortor at auctor.',
  };

  render() {
    return (
      <React.Fragment>
        <Typography
          align="center"
          variant="h5"
          gutterBottom
        >
          {this.props.endUserLicenseAgreementHeadline}
        </Typography>
        <Typography
          className={styles.displayLinebreak}
          variant="body1"
          gutterBottom
        >
          {this.props.endUserLicenseAgreement}
        </Typography>
      </React.Fragment>
    );
  }
};
