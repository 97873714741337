import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import { MultipleChoiceComponent } from './MultipleChoiceComponent';
import { OptionObject, DisplayValuePair } from '../../../../../common/types/input-options';

interface Props {
  question: string,
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6',
  status: 'required'|'optional',
  displayRequirednessMarker: boolean,
  options?: DisplayValuePair[],
  onChange?: (event: React.ChangeEvent<{}>, value: string) => void,
  value: string,
  errorMessage?: string,
}

interface State {
  options: OptionObject[],
}

export class MultipleChoiceContainer extends Component<Props, State> {
  componentWillMount() {
    const options = this.props.options!.map(
      (option: DisplayValuePair) => ({ key: uuid(), value: option.value, display: option.display })
    );
    this.setState({ options });
  }

  render() {
    return (
      <MultipleChoiceComponent
        question={this.props.question}
        textVariant={this.props.textVariant}
        status={this.props.status}
        displayRequirednessMarker={this.props.displayRequirednessMarker}
        options={this.state.options}
        onChange={this.props.onChange}
        value={this.props.value}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}
