import React, { Component } from 'react';
import debounce from 'debounce';
import { Omit } from '../../../../../../common/types/omit';
import {
  SingleSelectAutocomplete,
  SingleSelectAutocompleteProps,
  Option,
} from '../single-select-autocomplete';

interface State {
  selected?: Option;
  options: Option[];
}

interface ASSAContainerProps extends Omit<SingleSelectAutocompleteProps, 'options'> {
  getOptions: (inputValue: string) => Promise<Option[]>;
}

export class AsyncSingleSelectAutocomplete extends Component<ASSAContainerProps, State> {
  state: State = {
    selected: this.props.selected,
    options: [],
  }
  
  request?: Promise<Option[]>

  updateOptions = debounce((inputValue: string) => {    
    if (inputValue) {
      this.props.getOptions(inputValue)
        .then((results: Option[]) => this.setState({ options: results }));
    }
  }, 200)
 
  componentWillMount() {
    this.props.getOptions('').then(
      (results: Option[]) => this.setState({ options: results })
    );
  }

  render() {
    return (
      <SingleSelectAutocomplete
        onChange={this.props.onChange}
        onInputChange={this.updateOptions}
        placeholder={this.props.placeholder}
        helpText={this.props.helpText}
        selected={this.state.selected}
        options={this.state.options}
        question={this.props.question}
        textVariant={this.props.textVariant}
        status={this.props.status}
        displayRequirednessMarker={this.props.displayRequirednessMarker}
        testingKey={this.props.testingKey}
      />
    );
  }
}
