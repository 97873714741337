import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { GridJustification } from '@material-ui/core/Grid';
import styles from './edit-link.module.scss';

interface Props {
  linkPath: string;
  justify?: GridJustification;
  testingKey?: string;
}

export const EditLink: React.FC<Props> = ({ 
  linkPath, justify, testingKey, children = 'Edit' 
}) => (
  <Link to={linkPath} data-test-id={testingKey}>
    <Grid
      container
      direction="row"
      alignItems="center"
      justify={justify}
    >
      <Grid item>
        <Edit fontSize="small" className={styles.editIcon} />
      </Grid>
      <Grid item>
        <Typography variant="body1" color="inherit">
          {children}
        </Typography>
      </Grid>
    </Grid>
  </Link>
);
