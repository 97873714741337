/* eslint-disable react/no-unused-state */
import React from 'react';
import config, { Config, reloadConfig } from '../../config';
import { local } from '../../common/local-storage';

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  avatar: string;
  isAuthenticated: boolean;
  accountRelationships: Array<AccountUserRelationship>;
  hasMarketplaceCrewProfile: boolean;
}

export interface AccountUserRelationship {
  account: Account;
  isCrew: boolean;
  isUser: boolean;
  isAdmin: boolean;
  isSysAdmin: boolean;
  registrationCompleted: boolean;
}

export interface Account {
  id: string;
  slug: string;
  name: string;
}

export interface IAppContext {
  setAppContext(state: WritableAppState, cb?: () => void): void;
  reloadConfig(): void;
  user: User;
  account: Account;
  config: Config;
}

export type WritableAppState = Partial<IAppContext>;

const defaultUser: User = {
  firstName: '',
  lastName: '',
  avatar: '',
  email: '',
  phone: '',
  isAuthenticated: false,
  accountRelationships: [],
  hasMarketplaceCrewProfile: false
};

const defaultAccount: Account = {
  id: '',
  slug: '',
  name: ''
};

export const AppContext = React.createContext<IAppContext>({
  setAppContext: () => {},
  reloadConfig: () => {},
  user: { ...defaultUser },
  account: { ...defaultAccount },
  config: { ...config }
});

export class AppState extends React.Component<any, IAppContext> {
  constructor(props: any) {
    super(props);
    
    this.state = {
      user: { ...defaultUser },
      account: { ...defaultAccount },
      config: { ...config },
      setAppContext: this.setAppContext,
      reloadConfig: this.reloadConfig
    };
  }  

  public reloadConfig = () => {
    reloadConfig();
    this.setState({
      config: { ...config }
    });
  }

  public setAppContext = (state: WritableAppState, callback?: () => void) => {    
    const newState: Partial<IAppContext> = {};
    if (state.user) {
      newState.user = {
        isAuthenticated: true,
        ...state.user
      };
    }

    if (state.account) {
      local.setItem('account', state.account.slug);
      newState.account = { ...state.account };
    }

    reloadConfig();
    newState.config = { ...config };
    
    this.setState(newState as any, () => {
      if (callback) { callback(); }
    });    
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
/* eslint-enable react/no-unused-state */
