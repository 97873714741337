import React from 'react';
import i18next from 'i18next';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { LoginInitComponent } from './LoginInitComponent';
import { LoginStep } from './LoginContainer';
import { CognitoSignInResponse } from './cognito';
import { migrateUser, userConfig } from './user-migration';

interface Props {
  onComplete(username: string, nextStep: LoginStep, migrateUser: boolean, user?: CognitoUser): void;
}

interface State {
  error?: string;
}

export class LoginInitContainer extends React.PureComponent<Props, State> {  
  state: State = {}

  private initializeLogin = async (username: string) => {
    try {
      await this.signIn(username);
    } catch (e) {
      if (e.code === 'UserNotFoundException') {
        // Call migrate API (GET) to determine legacy user's config.
        const config = await userConfig(username);
        if (!config.error) {
          if (config.saml_idp) {
            // This may be handled entirely on the client-side.
            // TODO
          } else if (config.passwordless_enabled) {
            // User is passwordless. Do not ask for the password, just start migration process.
            const results = await migrateUser(username, '', 'passwordless');
            if (results.error) {
              this.setState({ error: i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.INVALID_USERNAME') });    
            } else {
              await this.signIn(username);
            }
          } else {
            // Ask for the password first, then start migration.
            this.props.onComplete(username, LoginStep.Password, true);
          }
        } else {
          this.setState({
            error: i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.INVALID_USERNAME')
          });  
        }
      } else {
        // Something went wrong.
        this.setState({
          error: i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.LOGIN', { message: e.message })
        });
      }
    }
  }

  private signIn = async (username: string) => {
    const user: CognitoSignInResponse = await Auth.signIn(username);
    const nextStep: LoginStep = (user.challengeParam.type === 'passwordless') ? LoginStep.Code : LoginStep.Password;
    this.props.onComplete(username, nextStep, false, user);
  }

  render() {
    return (
      <LoginInitComponent 
        onSubmit={this.initializeLogin}
        error={this.state.error}
      />
    );
  }
}
