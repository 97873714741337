import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from '../../../../common/button';

interface Props {
  handler(confirmed:boolean): any;
  headlineMessage: string;
  declineMessage: string;
}

export const ConfirmDecline = class extends Component<Props, {}> {
  static defaultProps = {
    headlineMessage: 'Are you sure you want to decline?',
    declineMessage: 
      'Acceptance of this agreement is required to register. By declining, you’re opting out of LASSO Marketplace.',
  };

  render() {
    return (
      <Grid
        container
        spacing={16}
        direction="column"
        justify="center"
        alignItems="stretch"
      >
        <Grid item>
          <Typography align="center" variant="h5">{this.props.headlineMessage}</Typography>
        </Grid>
        <Grid item>
          <Typography align="center" variant="body1">{this.props.declineMessage}</Typography>
        </Grid>
        <Grid item container spacing={8} alignItems="stretch" direction="column">
          <Grid item>
            <Button wide onClick={this.props.handler(true)}>Yes, decline</Button>
          </Grid>
          <Grid item>
            <Button wide variant="outlined" onClick={this.props.handler(false)}>Cancel</Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};
