import React from 'react';
import { 
  Dialog, DialogContent, Grid, Typography, List, ListItem, ListItemText, Divider 
} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useTranslation } from 'react-i18next';
import { Account } from '../core/AppContext';
import styles from './switch-business.module.scss';

interface Props {
  accounts: Account[];
  onSelectBusiness(account: Account): void;
}

export const SwitchBusinessComponent: React.FC<Props> = ({ accounts, onSelectBusiness }) => { 
  const { t } = useTranslation();

  return (
    <Dialog open maxWidth="xs" BackdropProps={{ classes: { root: styles.backdrop } }}>
      <DialogContent>
        <Grid container spacing={16} direction="column" justify="center">
          <Grid item>
            <Typography variant="h5" align="center">{t('AREAS.ACCOUNT.SWITCH_BUSINESS.TITLE')}</Typography>
          </Grid>
          <Grid item>
            <List component="nav">
              {accounts.map((account, i) => (
                <React.Fragment key={account.slug}>
                  {i === 0 && (
                  <Divider />
                  )}
                  <ListItem button onClick={() => onSelectBusiness(account)} disableGutters>
                    <ListItemText primary={account.name} />
                    <ChevronRight />
                  </ListItem>
                  {i < accounts.length && (
                  <Divider />
                  )}
                </React.Fragment>
              ))}
            </List>
          </Grid>      
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
