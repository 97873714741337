import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { PasswordResetRequiredWorkflow } from './PasswordResetRequiredWorkflow';


export const PasswordResetRequired: React.FC<any> = ({ children }) => {
  const [resetRequired, setResetRequired] = useState(false);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      undefined, interceptPasswordResetError(setResetRequired)
    );

    return eject(responseInterceptor);
  }, []);

  if (resetRequired) {
    return <PasswordResetRequiredWorkflow />;
  }

  return children;
};

const interceptPasswordResetError = (
  setResetRequired: React.Dispatch<React.SetStateAction<boolean>>
) => (
  error: AxiosError
) => {
  if (error.response
    && error.response.status === 403
    && error.response.data.error === 'password_reset_required'
  ) {
    setResetRequired(true);
  }

  return Promise.reject(error);
};

const eject = (responseInterceptor: number) => () => {
  axios.interceptors.response.eject(responseInterceptor);
};
