import React, { Component } from 'react';
import axios, { AxiosResponse } from 'axios';
import config from '../../../../../config';
import {
  Option,
  SingleSelectAutocompleteProps,
} from './single-select-autocomplete';
import {
  AsyncSingleSelectAutocomplete,
} from './async-single-select-autocomplete';

interface State {
  selected?: Option;
}

let source = axios.CancelToken.source();

export class AirportAutocompleteContainer extends Component<SingleSelectAutocompleteProps, State> {
  state: State = {
    selected: this.props.selected,
  }

  requestInProgress: boolean = false;

  airportResults: Option[] = [];

  componentWillUnmount() {
    if (this.requestInProgress) {
      source.cancel();
    }
  }

  getOptions = async (inputValue: string) => {
    if (this.requestInProgress) {
      source.cancel();      
    }
    source = axios.CancelToken.source();
    this.requestInProgress = true;
    
    try {
      const response: AxiosResponse = await axios(
        `${config.apiRoot}/internal_api/autocomplete/airport/?term=${inputValue}`, {
          method: 'GET',
          withCredentials: true,
          cancelToken: source.token
        }
      );
      this.requestInProgress = false;
      this.airportResults = response.data.airport_data;
      return this.airportResults;
    } catch (e) {      
      this.requestInProgress = false;      
      if (!axios.isCancel(e)) {        
        throw e;
      }
      return this.airportResults;
    }
  }

  render() {
    return (
      <AsyncSingleSelectAutocomplete
        onChange={this.props.onChange}
        placeholder={this.props.placeholder}
        helpText={this.props.helpText}
        selected={this.state.selected}
        getOptions={this.getOptions}
        question={this.props.question}
        textVariant={this.props.textVariant}
        status={this.props.status}
        displayRequirednessMarker={this.props.displayRequirednessMarker}
        testingKey={this.props.testingKey}
      />
    );
  }
}
