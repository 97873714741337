import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { NormalLayout } from '../../../common/layouts/NormalLayout';
import { RegistrationHeader } from '../common/RegistrationHeader';
import { Button } from '../../../common/button';
import { InputErrorIcon } from '../../../common/input-error-icon';


interface OwnProps {
  onChange(username: string): void;
  onContinue(): void;
  phoneEmail: string;
  canSubmit: boolean;
  error?: string
}

export const LoginComponent: React.FC<OwnProps> = (props) => {
  const {
    onChange, onContinue, phoneEmail, canSubmit, error
  } = props;

  return (
    <NormalLayout>
      <Grid container direction="column" spacing={8}>
        <Grid item>
          <RegistrationHeader
            title="Log in"
          />
        </Grid>
        <Grid item>
          <form onSubmit={(e) => {
            e.preventDefault();
            onContinue();
          }}
          >
            <Grid container spacing={16} direction="column" alignItems="stretch">
              <Grid item>
                <TextField
                  label="Email or Mobile Number"
                  variant="filled"
                  fullWidth
                  autoFocus
                  margin="normal"
                  value={phoneEmail}
                  onChange={e => onChange(e.target.value)}
                  helperText={
                    error && <span data-test-id="login-textfield-emailOrNumber-error">{error}</span>
                  }
                  error={!!error}
                  InputProps={{
                    ...(error && { endAdornment: <InputErrorIcon /> })
                  }}
                  inputProps={{ maxLength: 254 }}
                  data-test-id="login-textfield-emailOrNumber"
                />
              </Grid>
              <Grid item container xs={12} justify="center">
                <Button
                  wide
                  disabled={!canSubmit}
                  type="submit"
                  data-test-id="login-btn-continue"
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </NormalLayout>
  );
};
