import React, { Component, FormEvent } from 'react';
import { TextField } from '@material-ui/core';
import { QuestionHeading } from '../question-heading';


interface Props {
  question: string,
  placeholder?: string,
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6',
  status: 'required'|'optional',
  displayRequirednessMarker: boolean,
  autoSave?: React.EventHandler<FormEvent>,
  onChange?: React.EventHandler<FormEvent>,
  value: string,
  errorMessage?: string,
  type?: string,
  maxLength?: number,
  testingKey?: string
}

export class ShortAnswerComponent extends Component<Props> {
  handleBlur = (event:React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.autoSave) {
      this.props.autoSave(event);
    }
  };

  render() {
    let placeholder = 'Your answer';
    let inputProps: any;
    if (this.props.placeholder !== undefined) {
      placeholder = this.props.placeholder!;
    }
    if (this.props.maxLength && this.props.maxLength > 0) {
      inputProps = {
        maxLength: this.props.maxLength
      };
    }

    return (
      <React.Fragment>
        <QuestionHeading
          question={this.props.question}
          textVariant={this.props.textVariant}
          status={this.props.status}
          displayRequirednessMarker={this.props.displayRequirednessMarker}
        />
        <TextField
          fullWidth
          margin="normal"
          type={this.props.type}
          placeholder={placeholder}
          onBlur={this.handleBlur}
          onChange={this.props.onChange}
          value={this.props.value}
          error={this.props.errorMessage !== undefined}
          helperText={this.props.errorMessage}
          inputProps={inputProps}
          data-test-id={`shortanswer__textfield${this.props.testingKey ? `--${this.props.testingKey}` : ''}`}
        />
      </React.Fragment>
    );
  }
}
