import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import { LinearProgress as LinearProgressMUI } from '@material-ui/core';
import styles from './linear-progress-container.module.scss';
import { PanelStatuses, PanelStatus } from '../../areas/crew-profile/dashboard/dashboard-container';

interface Props {
  panelStatuses: PanelStatuses;
  nextRoute?: string;
}

const recalculateProgressPercentage = (panelStatuses: PanelStatuses) => {
  const total = Object.keys(panelStatuses).length;
  const completed = (Object.values(panelStatuses).filter(s => s === PanelStatus.Complete).length) * 100;
  const percentage = total !== 0 ? (completed / total) - 5 : 0;

  return percentage;
};

function usePrevious(props: Props) {
  const ref = useRef({ ...props });
  useEffect(() => {
    ref.current = props;
  });
  return ref.current;
}

export function LinearProgressContainer(props: Props) {
  const [progressPercentage, setProgressPercentage] = useState(recalculateProgressPercentage(props.panelStatuses));
  const previous = usePrevious(props);

  useEffect(
    () => {
      if (
        props.nextRoute !== previous.nextRoute
        || (Object.keys(previous.panelStatuses).length === 0 && props.panelStatuses !== previous.panelStatuses)
      ) {
        setProgressPercentage(recalculateProgressPercentage(props.panelStatuses));
      }
    },
    [props.nextRoute, props.panelStatuses],
  );

  return <LinearProgressMUI variant="determinate" value={progressPercentage} className={styles.linearProgress} />;
}
