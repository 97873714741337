import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import styles from './desktop-banner-layout.module.scss';
import { BaseLayout } from '../BaseLayout';
import { DesktopBanner } from '../../banners/desktop-banner';

export class DesktopBannerLayout extends Component {
  render() {
    return (
      <BaseLayout>
        <Hidden smDown>
          <DesktopBanner />
        </Hidden>
        <Paper
          square
          elevation={0}
          className={styles.paper}
        >
          <Grid
            container
            direction="column"
            alignItems="stretch"
            spacing={24}
          >
            {this.props.children}
          </Grid>
        </Paper>
      </BaseLayout>
    );
  }
}
