import { local } from '../common/local-storage';

export interface Config {
  apiRoot: string;
  enableCognito: boolean;
  accountSlug: string;
  environment: 'local' | 'test' | 'sandbox' | 'prod';
  lassoUrlBase: string;
  mobileAppUrl: string;
  accountsWithNewRegistration: string[];
}

const config: Config = {} as Config;

export const reloadConfig = () => {
  const currentAccount = currentAccountSlug();
  const apiRoot = (process.env.REACT_APP_API_ROOT || '').replace('_', currentAccount || 'api');
  const environment = process.env.REACT_APP_ENVIRONMENT || '';

  Object.assign(config, {
    apiRoot,
    enableCognito: asBool(process.env.REACT_APP_ENABLE_COGNITO, false),
    accountSlug: currentAccount,
    environment,
    lassoUrlBase: lassoUrlBase(environment, currentAccount),
    mobileAppUrl: mobileAppUrl(environment),
    accountsWithNewRegistration: ['marketplace']
  });
};

export default config;

reloadConfig();

function asBool(value: string = '', def: boolean = false) {
  switch (value.toLowerCase()) {
    case 'true':
    case '1':
    case 'yes':
      return true;
    case 'false':
    case '0':
    case 'no':
      return false;
    default:
      return def;
  }
}

function lassoUrlBase(env: string, accountSlug: string) {
  switch (env) {
    case 'local': 
      return `http://${accountSlug}.local.lasso.io:8000`;
    case 'sandbox':
    case 'test':
      return `https://${accountSlug}.${env}.lasso.io`;
    default:
      return `https://${accountSlug}.lasso.io`;
  }
}

function currentAccountSlug(): string {
  const path = window.location.pathname;
  const savedSlug = local.getItem('account') || '';
  let slug = savedSlug;
  if (includesSlug(path)) {
    const parts = path.split('/');
    slug = parts[parts.length - 1];
  }
  
  if (savedSlug !== slug) {
    local.setItem('account', slug);    
  }  

  return slug;
}

function mobileAppUrl(env: string): string {
  switch (env) {
    case 'local':
      return 'http://m.local.lasso.io:8000';
    case 'test':
    case 'sandbox':
      return `https://m.${env}.lasso.io`;
    default:
      return 'https://m.lasso.io';
  }
}

function includesSlug(path: string): boolean {
  return path.includes('/account/welcome')
    || path.includes('/account/signup')
    || path.includes('/account/legacy/welcome')
    || path.includes('/account/legacy/signup');
}
