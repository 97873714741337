import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { FormHelperText } from '@material-ui/core';
import { ShortAnswerComponent } from '../short-answer';
import { QuestionHeading } from '../question-heading';

interface Props {
  question?: string,
  placeholder?: string,
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6',
  status: 'required'|'optional',
  displayRequirednessMarker: boolean,
  onValueChange?: (value: string) => void,
  value: string,
  prefix?: string,
  suffix?: string,
  errorMessage?: string,
  testingKey?: string
}

export const CurrencyComponent: React.FC<Props> = (props) => {
  let errorMessage = null;
  if (props.errorMessage !== undefined) {
    errorMessage = <FormHelperText error>{props.errorMessage!}</FormHelperText>;
  }

  let placeholder = 'Your answer';
  if (props.placeholder !== undefined) {
    placeholder = props.placeholder!;
  }

  let heading = null;
  if (props.question) {
    heading = (
      <QuestionHeading
        question={props.question}
        textVariant={props.textVariant}
        status={props.status}
        displayRequirednessMarker={props.displayRequirednessMarker}
      />
    );
  }

  return (
    <>
      {heading}
      <NumberFormat
        prefix={props.prefix}
        suffix={props.suffix}
        placeholder={placeholder}
        decimalScale={2}
        fixedDecimalScale
        allowNegative={false}
        customInput={ShortAnswerComponent}
        onValueChange={(values: NumberFormatValues) => { 
          if (props.onValueChange) { props.onValueChange(values.value); } 
        }}
        value={props.value}
        type="tel"
        data-test-id={`currency__numberformat--${props.testingKey}`}
      />
      {errorMessage}
    </>
  );
};
