export const localStorageWrapper = (getLocalStorage: () => Storage): Storage => {
  let inMemoryStorage: { [key: string]: string } = {};

  const isAvailable = (): boolean => {
    try {
      const sampleKey = 'lasso_test_key';
      getLocalStorage().setItem(sampleKey, sampleKey);
      getLocalStorage().removeItem(sampleKey);
      return true;
    } catch (e) {
      return false;
    }
  };

  const clear = (): void => {
    if (isAvailable()) {
      getLocalStorage().clear();
    } else {
      inMemoryStorage = {};
    }
  };

  const getItem = (name: string): string | null => {
    if (isAvailable()) {
      return getLocalStorage().getItem(name);
    }
    if (name in inMemoryStorage) {
      return inMemoryStorage[name];
    }
    return null;
  };

  const key = (index: number): string | null => {
    if (isAvailable()) {
      return getLocalStorage().key(index);
    } 
    return Object.keys(inMemoryStorage)[index] || null;
  };

  const removeItem = (name: string): void => {
    if (isAvailable()) {
      getLocalStorage().removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  };

  const setItem = (name: string, value: string): void => {
    if (isAvailable()) {
      getLocalStorage().setItem(name, value);
    } else {
      inMemoryStorage[name] = String(value);
    }
  };

  const getLength = (): number => {
    if (isAvailable()) {
      return getLocalStorage().length;
    }
    return Object.keys(inMemoryStorage).length;
  };

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return getLength();
    },
  };
};
