import React, { Component } from 'react';
import { BackgroundImageLayout } from '../BackgroundImageLayout';

export class BaseLayout extends Component {
  render() {
    return (
      <BackgroundImageLayout>
        {this.props.children}
      </BackgroundImageLayout>
    );
  }
}
