import React, { MouseEventHandler } from 'react';
import { Card } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Edit from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import {
  PersonalInformationData
} from '../../areas/crew-profile/workflow/question-forms/person-information/PersonInformationComponent';
import styles from './person-card.module.scss';

interface Props {
  data: PersonalInformationData,
  display: string,
  onEdit?: MouseEventHandler<any>,
  onDelete?: MouseEventHandler<any>,
  testingKey?: string;
}

export const PersonCard: React.FC<Props> = (props) => {
  const { testingKey } = props;

  return (
    <>
      <Card className={styles.card}>
        <CardHeader
          className={styles.cardHeader}
          avatar={<PersonIcon className={styles.personIcon} />}
          titleTypographyProps={{ style: { fontSize: '16px', fontWeight: 'bold' } }}
          title={props.display}
          data-test-id={`personcard__card${testingKey ? `${testingKey}` : ''}`}
          action={(
            <>
              <IconButton
                className={styles.iconButton}
                onClick={props.onEdit}
                data-test-id={`person__button--edit${testingKey ? `--${testingKey}` : ''}`}
              >
                <Edit className={styles.editIcon} />
              </IconButton>
              <IconButton 
                className={styles.iconButton} 
                onClick={props.onDelete} 
                data-test-id={`personcard__button--clear${testingKey ? `--${testingKey}` : ''}`}
              >
                <ClearIcon className={styles.clearIcon} />
              </IconButton>
            </>
          )}
        />
      </Card>
    </>
  );
};
