import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

const TOKEN_NAME = 'X-CSRFToken'.toLowerCase();

let csrf: string;

function addCSRFToRequest(conf: AxiosRequestConfig): AxiosRequestConfig {
  const config = Object.assign({}, conf);

  if (csrf) {
    config.headers = config.headers || {};
    config.headers[TOKEN_NAME] = csrf;
  }
  
  return config;
}

function parseCSRFFromResponse(response: AxiosResponse): AxiosResponse {
  csrf = response.headers[TOKEN_NAME];
  return response;
}

function parseCSRFFromError(e: AxiosError) {
  if (e.response && e.response.headers[TOKEN_NAME]) {
    csrf = e.response.headers[TOKEN_NAME];
  }
  throw e;
}

export function registerInterceptors() {
  axios.interceptors.request.use(addCSRFToRequest);
  axios.interceptors.response.use(parseCSRFFromResponse, parseCSRFFromError);
}
