import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import styles from './close-button.module.scss';

type Props = {
  onClick: () => void;
  linkText?: string;
}

export class CloseButton extends Component<Props> {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-end"
      >
        {this.props.linkText && (
          <Grid item>
            <Typography variant="body1" color="inherit" className={styles.linkText}>{this.props.linkText}</Typography>
          </Grid>
        )}
        <Grid item>
          <IconButton 
            color="primary" 
            className={styles.iconButton} 
            {...this.props} 
            data-test-id="closebutton__button--close"
          >
            <Clear fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
}
