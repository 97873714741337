import React from 'react';
import Auth from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import config from '../../../config';
import { AppContext, User } from '../../core/AppContext';
import { LoadingComponent } from '../../../common/loading-component';
import { ExternalRedirect } from '../../../common/external-redirect';

export const createDjangoSession = async (): Promise<User> => {
  const session: CognitoUserSession = await Auth.currentSession();
  const token = session.getIdToken();

  try {
    const response = await axios(`${config.apiRoot}/internal_api/auth/cognito_login/`, {
      method: 'POST',
      withCredentials: true,
      data: {
        jwt: token.getJwtToken()
      }
    });
    
    return response.data.user;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

interface Props {
  returnUrl: string;
}

interface State {
  loading: boolean;
}

export class DjangoSessionContainer extends React.PureComponent<Props, State> {  
  static contextType = AppContext;

  state: State = {
    loading: true
  }

  async componentDidMount() {
    const user = await createDjangoSession();
    this.setState({ loading: false }, () => {
      this.context.setAppContext({ user });
    });
  }

  render() {
    if (this.state.loading) {
      return <LoadingComponent />;
    }

    if (this.props.returnUrl[0] === '/') {
      return <Redirect to={this.props.returnUrl} />;
    }
    
    return <ExternalRedirect to={this.props.returnUrl} />;
  }  
}
