import React from 'react';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PhoneInput from 'react-phone-number-input';
import { Button } from '../../../common/button';
import { LogInLink } from '../../../common/login-link';
import { RegistrationHeader } from '../common/RegistrationHeader';
import { NormalLayout } from '../../../common/layouts/NormalLayout';
import { SignupState } from './SignupContainer';
import { SignupErrorComponent } from '../common/SignupErrorComponent';
import { InputErrorIcon } from '../../../common/input-error-icon';
import { PolicyAgreementComponent, PolicyObject } from './service-terms/PolicyAgreementComponent';
import { FormErrors } from '../common/FormErrors';
import 'react-phone-number-input/style.css';
import styles from './signup.module.scss';
import { PhoneNumberInput } from '../../../common/phone-number-input';

interface Props {
  onSubmit(): void;
  onChange(key: string, value: any): void;
  toggleBoolean(key: string): void;
  canSubmit: boolean;
  state: SignupState;
  error?: FormErrors;
  policiesNeeded: Array<PolicyObject>;
  changeCountry: (countryCode?: string | undefined) => void;
}


export const SignupComponent: React.FC<Props> = (props) => {
  const genericError = props.error ? props.error.generic : null;
  return (
    <NormalLayout>
      <Grid container justify="center">
        <Grid
          item
          container
          direction="column"
          justify="flex-start"
        >
          {genericError && (
            <SignupErrorComponent
              title={genericError.title}
              text={genericError.text}
            />
          )}
          <Grid item>
            <RegistrationHeader
              title="Join Crew Marketplace"
            />
          </Grid>
        </Grid>
        <Grid item>
          <form noValidate onSubmit={(e) => { e.preventDefault(); props.onSubmit(); }}>
            <TextField
              label="First name"
              variant="filled"
              fullWidth
              margin="normal"
              value={props.state.firstName}
              onChange={e => props.onChange('firstName', e.target.value)}
              inputProps={{ maxLength: 100 }}
              name="first_name"
              data-test-id="signup__textfield--firstname"
            />
            <TextField
              label="Last name"
              variant="filled"
              fullWidth
              margin="normal"
              value={props.state.lastName}
              onChange={e => props.onChange('lastName', e.target.value)}
              inputProps={{ maxLength: 100 }}
              name="last_name"
              data-test-id="signup__textfield--lastname"
            />
            <TextField
              type="email"
              label="Email"
              variant="filled"
              fullWidth
              margin="normal"
              value={props.state.email}
              onChange={e => props.onChange('email', e.target.value)}
              error={showEmailError(props.error)}
              InputProps={{
                ...(showEmailError(props.error) && { endAdornment: <InputErrorIcon /> })
              }}
              inputProps={{ maxLength: 254 }}
              name="email"
              helperText={
                props.error && (
                <span data-test-id="signup__textfield--email--error">
                  {emailHelperText(props.error)}
                </span>
                )
              }
              data-test-id="signup__textfield--email"
            />
            <PhoneInput
              className={styles.phoneNumber}
              inputComponent={PhoneNumberInput as any}
              onChange={value => props.onChange('phone', value)}
              value={props.state.phone}
              country={props.state.country}
              countries={['US', 'CA', 'GB']}
              limitMaxLength
              international={false}
              onCountryChange={props.changeCountry}
              data-test-id="signup__phoneinput--phone"
            />
            <Grid container spacing={24} direction="column" justify="center">
              <Grid item xs={12}>
                <PolicyAgreementComponent
                  handleCheckedbox={() => props.toggleBoolean('policiesAccepted')}
                  policiesNeeded={props.policiesNeeded}
                  checked={props.state.policiesAccepted}
                />
              </Grid>
              <Grid item container xs={12} justify="center">
                <Button
                  wide
                  disabled={!props.canSubmit}
                  type="submit"
                  data-test-id="continue"
                >
                  Continue
                </Button>
              </Grid>
              <Grid item>
                <LogInLink />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </NormalLayout>
  );
};

function showEmailError(errors?: FormErrors) {
  if (!errors) return false;

  if (errors.generic) {
    return true;
  }

  if (errors.fieldErrors && errors.fieldErrors.email) {
    return true;
  }

  return false;
}

function emailHelperText(errors?: FormErrors) {
  if (!errors) return '';

  if (errors.fieldErrors && errors.fieldErrors.email) {
    return errors.fieldErrors.email[0];
  }

  return '';
}
