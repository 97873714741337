/* eslint-disable max-len */
import React, { Component } from 'react';

export class TickInsideCircleSVG extends Component<React.HTMLAttributes<HTMLOrSVGElement>> {
  render() {
    return (
      <svg width="106" height="106" viewBox="0 0 106 106">
        <g fill="#3BB273" fillRule="nonzero">
          <path
            d="M52.858 105.716C23.713 105.716 0 82.005 0 52.858S23.713 0 52.858 0c29.145 0 52.858 23.711 52.858 52.858s-23.713 52.858-52.858 52.858zm0-102.306C25.59 3.41 3.41 25.593 3.41 52.858s22.181 49.448 49.448 49.448 49.448-22.183 49.448-49.448S80.125 3.41 52.858 3.41z"
          />
          <path d="M43.086 75.404L24.19 56.508l2.411-2.411 16.485 16.485 35.881-35.88 2.412 2.411z" />
        </g>
      </svg>
    );
  }
}
