import React, { Component } from 'react';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Button } from '@material-ui/core';
import config from '../../../config';
import { LassoLogo } from '../../icons/lasso-logo';
import styles from './mobile-banner.module.scss';
import { AppContext, IAppContext } from '../../../areas/core/AppContext';

export class MobileBanner extends Component {
  static contextType = AppContext;

  private logout = async () => {
    try {
      await axios(`${config.apiRoot}/internal_api/auth/logout/`, {
        method: 'POST',
        withCredentials: true,
      }).then(this.reload);
    } catch (e) {
      console.warn(e);
    }
  };

  private reload = () => {
    const { account } = this.context as IAppContext;
    window.location.href = `/account/welcome/${account.slug}`;
  }


  render() {
    return (
      <AppBar className={styles.appBar} position="static">
        <Toolbar className={styles.toolbar}>
          <LassoLogo className={styles.logo} width={160} height={50} />
          <Button
            className={styles.logoutButton}
            onClick={this.logout}
            data-test-id="mobilebanner__button--logout"
          >
            Log out
          </Button>
        </Toolbar>
      </AppBar>
    );
  }
}
