import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import { DesktopBannerLayout } from '../desktop-banner-layout';
import { MobileBanner } from '../../banners/mobile-banner';

export class DesktopAndMobileBannerLayout extends Component {
  render() {
    return (
      <DesktopBannerLayout>
        <Grid
          item
          container
          direction="row"
          alignItems="stretch"
        >
          <Hidden mdUp>
            <Grid item xs={12}>
              <MobileBanner />
            </Grid>
          </Hidden>
        </Grid>
        <Grid item>
          {this.props.children}
        </Grid>
      </DesktopBannerLayout>
    );
  }
}
