import React from 'react';
import i18next from 'i18next';
import Auth from '@aws-amplify/auth';
import { ForgotPasswordComponent } from './ForgotPasswordComponent';
import { FieldErrors } from '../common/FormErrors';
import { migrateUser } from './user-migration';

interface Props {
  username: string;
  migrateUser: boolean;
  onComplete(): void;
}

interface State {
  codeDestination: string;
  values: ForgotPasswordForm;
  error?: string;
  fieldErrors: FieldErrors;
}

export interface ForgotPasswordForm {
  password: string;
  code: string;
}

export class ForgotPasswordContainer extends React.PureComponent<Props, State> {
  state: State = {
    codeDestination: '',
    values: {
      password: '',
      code: ''
    },
    fieldErrors: {}
  }

  async componentDidMount() {
    if (this.props.migrateUser) {
      await this.migrate();
    } else {
      await this.forgotPassword();      
    }
  }

  private forgotPassword = async () => {
    try {
      const data = await Auth.forgotPassword(this.props.username);
      this.setState({ codeDestination: data.CodeDeliveryDetails.Destination });
    } catch (e) {
      this.setState({ error: i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.RESET_PASSWORD', { message: e.message }) });
    }
  }

  private submitNewPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(this.props.username, this.state.values.code, this.state.values.password);
      this.props.onComplete();
    } catch (e) {
      this.setState({
        error: i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.SETTING_PASSWORD', { message: e.message })
      });
    }
  }  

  private resendCode = async () => {
    await this.forgotPassword();
  }

  private handleInputChange = (change: { [field: string]: string }) => {
    this.setState((prevState) => {
      const newValues = { ...prevState.values, ...change };
      const errors = this.validateForm(newValues);
      return {  
        values: newValues,
        fieldErrors: errors 
      };
    });
  }

  private validateForm = (values: ForgotPasswordForm): FieldErrors => {
    const errors: FieldErrors = {};
    const password = values.password.trim();
    const code = values.code.trim();

    Object.entries(values).forEach(([key, value]) => {
      if (value.trim() === '') {
        errors[key] = i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.REQUIRED');
      }
    });

    if (password.length < 8) {
      errors.password = i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.MIN_PASSWORD_LENGTH');
    }

    if (code.length !== 6) {
      errors.code = i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.MIN_CODE_LENGTH');
    }

    return errors;
  }

  private migrate = async () => {
    const results = await migrateUser(this.props.username, '', 'forgotpassword');
    if (results.error) {
      this.setState({
        error: i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.MIGRATE_USER')
      });
    } else {
      await this.forgotPassword();
    }
  }

  render() {
    return (
      <ForgotPasswordComponent
        values={this.state.values}
        onSubmit={this.submitNewPassword}
        onChange={this.handleInputChange}
        onResendCode={this.resendCode}
        error={this.state.error}
        fieldErrors={this.state.fieldErrors}
        codeDestination={this.state.codeDestination}
      />
    );
  }
}
