import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import styles from './policy-agreement.module.scss';


export interface PolicyObject {
  [key: string]: string;
}

interface Props {
  checked: boolean;
  handleCheckedbox: () => void;
  policiesNeeded: Array<PolicyObject>;
}

export class PolicyAgreementComponent extends Component<Props, {}> {
  render() {
    return (
      <div className={styles.mainGrid}>
        <Checkbox
          onChange={this.props.handleCheckedbox}
          value="checkbox"
          color="primary"
          classes={{ root: styles.checkbox }}
          checked={this.props.checked}
        />
        <div role="presentation">
          <Typography onClick={() => this.props.handleCheckedbox()} variant="body2">
            I have read and agree to the following:
          </Typography>
          {this.props.policiesNeeded.map(
            policy => (
              <Typography
                variant="body2"
                className={styles.linkA}
                key={policy.uuid}
              >
                <a
                  href={policy.document__storage_key}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {policy.name}
                </a>
              </Typography>
            )
          )}
        </div>
      </div>
    );
  }
}
