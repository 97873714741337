import React, { ChangeEvent, Component } from 'react';
import { TextField } from '@material-ui/core';
import
{
  parseIncompletePhoneNumber,
  formatIncompletePhoneNumber,
  CountryCode,
  Metadata
}
  from 'libphonenumber-js/core';

interface Props {
  value: string;
  onChange: (arg0: string) => string;
  onFocus: () => void;
  country: CountryCode;
  metadata: Metadata;
}

export class PhoneNumberInput extends Component<Props, object, any> {
  state = { value: this.props.value };
  
  private ref: React.RefObject<any>;

  constructor(Props: Readonly<Props>) {
    super(Props);
    this.ref = React.createRef();
  }

  static getDerivedStateFromProps({ value } : { value: any}) { return { value }; }

  onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { onChange } = this.props;
    const { value } = this.state;

    let newValue = parseIncompletePhoneNumber(event.target.value);

    if (newValue === value) {
      if (this.format(newValue).indexOf(event.target.value) === 0) {
        newValue = newValue.slice(0, -1);
      }
    }

    this.setState({ value: newValue }, () => onChange(newValue));
  }

  focus = () => this.ref.current.focus();

  format(value: string) {
    const { country, metadata } = this.props;

    return formatIncompletePhoneNumber(value, country, metadata);
  }

  render() {
    const
      {
        onFocus,
        ...rest
      } = this.props;

    return (
      <TextField
        type="tel"
        variant="filled"
        label="Mobile number"
        autoComplete="tel"
        {...rest}
        value={this.format(this.state.value)}
        onChange={this.onChange}
        onFocus={onFocus}
        inputRef={this.ref}
      />
    );
  }
}
