import React, { Component } from 'react';

export class Checkmark extends Component<React.HTMLAttributes<HTMLOrSVGElement>> {
  render() {
    return (
      <svg width="23" height="18" viewBox="0 0 23 18">
        <path 
          fill="#F6C03C" 
          fillRule="nonzero" 
          d="M7.401 13.823L2.186 8.607.41 10.371l6.991 6.991L22.41 2.354 20.646.59z" 
        />
      </svg>
    );
  }
}
