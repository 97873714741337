import React from 'react';
import Paper from '@material-ui/core/Paper';
import styles from './normal-layout.module.scss';
import { BaseLayout } from '../BaseLayout';

interface Props{
  square?: boolean;
}

export const NormalLayout: React.FC<Props> = props => (
  <BaseLayout>
    <Paper
      square={props.square != null ? props.square : true}
      elevation={0}
      className={styles.paper}
    >
      {props.children}
    </Paper>
  </BaseLayout>
);
