import React, { Component } from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { LassoLogo } from '../icons/lasso-logo';
import { Button } from '../button';
import styles from './not-found-page.module.scss';

export class NotFoundPage extends Component<any> {
  render() {
    return (
      <Paper 
        square
        elevation={0}
        className={styles.paper}
      >
        <Grid container direction="column" justify="center" alignItems="center" spacing={40}>
          <Grid item className={styles.logo}>
            <LassoLogo />
          </Grid>
          <Grid item container direction="column" justify="center" alignItems="center" spacing={24}>
            <Grid item>
              <Typography variant="h5" align="center">We couldn&rsquo;t find this page</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" align="center">
                The page you are looking for was moved or doesn&rsquo;t exist.
              </Typography>
            </Grid>
            <Grid item>
              <Button wide onClick={() => window.location.assign('/')}>Return to dashboard</Button>
            </Grid>
            <Grid item>
              <a href="https://lassoworkforce.zendesk.com/hc/en-us/requests/new">Report error to Customer Success</a>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
