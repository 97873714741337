import React from 'react';
import { Chip, createMuiTheme, CircularProgress } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import PhotoIcon from '@material-ui/icons/Photo';
import { WordFileIcon } from '../icons/word-file';
import { PDFFileIcon } from '../icons/pdf-file';
import styles from './uploaded-file-chip.module.scss';

const themeArgs = {
  overrides: {
    MuiChip: {
      root: {
        borderRadius: 5,
        backgroundColor: '#eeeeee',
      },
      deleteIcon: {
        color: '#616161',
      },
    },
  },
  typography: {
    useNextVariants: true
  }
};
const theme = createMuiTheme(themeArgs as ThemeOptions);

interface Props {
  fileType: string;
  fileChipLabel: string;
  handleChipDelete: any;
  loading: boolean;
}
const textFilesArray = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'text/plain',
];
const pdfFile = 'application/pdf';
const imageFilesArray = [
  'image/png',
  'image/jpeg',
  'image/tiff',
];

export const UploadedFileChip: React.FC<Props> = (props) => {
  const {
    fileType,
    handleChipDelete,
    loading,
    fileChipLabel
  } = props;

  let fileChipIcon;
  if (textFilesArray.includes(fileType)) {
    fileChipIcon = <WordFileIcon className={styles.blueIcon} />;
  } else if (fileType === pdfFile) {
    fileChipIcon = <PDFFileIcon className={styles.redIcon} />;
  } else if (imageFilesArray.includes(fileType)) {
    fileChipIcon = <PhotoIcon className={styles.redIcon} />;
  }    

  const deleteIcon = loading ? <CircularProgress size={16} /> : <ClearIcon />;
  const handleDelete = loading ? () => {} : handleChipDelete;

  return (
    <MuiThemeProvider theme={theme}>
      <Chip
        icon={fileChipIcon}
        label={fileChipLabel}
        onDelete={handleDelete}
        deleteIcon={deleteIcon}
        className={styles.fileChip}        
      />
    </MuiThemeProvider>
  );
};  
