import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import { MultipleSelectorComponent } from './MultipleSelectorComponent';
import { OptionObject, DisplayValuePair } from '../../../../../common/types/input-options';
import { QuestionObject, DropdownSelectQuestionObject }
  from '../question-component/QuestionComponent';


interface Props {
  question: string;
  enableSelectAll?: boolean;
  helpText?: string;
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6';
  status: 'required'|'optional';
  displayRequirednessMarker: boolean;
  options?: DisplayValuePair[] | { [category: string]: DisplayValuePair[] };
  categoryOrder?: string[];
  onChange?: (event: React.ChangeEvent<{}>, checked: boolean) => void;
  value?: string[];
  followupQuestions?: { [optionId: string]: (QuestionObject | DropdownSelectQuestionObject) };
  errorMessage?: string;
  maxInputs?: number;
}

interface State {
  options: OptionObject[] | { [category: string]: OptionObject[] };
  numSelected: number;
}

export class MultipleSelectorContainer extends Component<Props, State> {
  state: State = {
    options: [],
    numSelected: (this.props.value && this.props.value.length) || 0
  }

  componentWillMount() {
    if (!this.props.options) {
      return;
    }

    if (this.props.options instanceof Array) {
      const options = this.props.options!.map(
        (option: DisplayValuePair) => ({ key: uuid(), value: option.value, display: option.display })
      );
      this.setState({ options });
    } else {
      const options = {} as { [category: string]: OptionObject[] };
      const propOptions = (this.props.options! as { [category: string]: DisplayValuePair[] });
      const categoryKeys = this.props.categoryOrder || Object.keys(this.props.options!);

      categoryKeys.forEach((key: string) => {
        if (Object.prototype.hasOwnProperty.call(propOptions, key)) {
          options[key] = propOptions[key].map(
            (displayValuePair: DisplayValuePair) => ({
              key: uuid(),
              value: displayValuePair.value,
              display: displayValuePair.display
            })
          );
        }
      });

      this.setState({ options });
    }    
  }

  onChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    const diff = checked ? 1 : -1;
    
    this.setState(prev => ({
      numSelected: prev.numSelected + diff
    }));

    if (this.props.onChange) {
      this.props.onChange(event, checked);
    }
  }

  render() {
    return (
      <MultipleSelectorComponent
        question={this.props.question}
        enableSelectAll={this.props.enableSelectAll}
        followupQuestions={this.props.followupQuestions}
        textVariant={this.props.textVariant}
        status={this.props.status}
        options={this.state.options}
        displayRequirednessMarker={this.props.displayRequirednessMarker}
        onChange={this.onChange}
        value={this.props.value}
        helpText={this.props.helpText}
        errorMessage={this.props.errorMessage}
        atMaxCapacity={!!(this.props.maxInputs && this.state.numSelected >= this.props.maxInputs)}
      />
    );
  }
}
