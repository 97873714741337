import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './dialog-confirmation.module.scss';
import { CloseButton } from '../close-button';

interface Props {
    showDialog: boolean,
    dialogTitle: string,
    dialogBody: string,
    handleDialogClose: any,
  }

export class DialogConfirmation extends Component<Props> {
  render() {
    return (
      <React.Fragment>
        <Dialog open={this.props.showDialog} maxWidth="xs" PaperProps={{ style: { margin: 28 } }}>
          <DialogContent className={styles.dialogContent}>
            <Grid container spacing={16}>
              <Grid
                item
                container
                justify="flex-end"
                alignItems="flex-start"
              >
                <Grid item>
                  <CloseButton onClick={this.props.handleDialogClose} />
                </Grid>
              </Grid>
              <Grid item container justify="center" alignItems="center" spacing={16}>
                <Grid item>
                  <Typography variant="h5" align="center">{this.props.dialogTitle}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" align="center">{this.props.dialogBody}</Typography>
                </Grid>
              </Grid>
              {this.props.children}
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
