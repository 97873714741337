import React, { ReactNode } from 'react';
import Error from '@material-ui/icons/Error';
import { Grid, Typography } from '@material-ui/core';
import styles from './signuperror.module.scss';


export interface SignupErrorProps {
  title: string;
  text: string | ReactNode;
}

export class SignupErrorComponent extends React.PureComponent<SignupErrorProps> {
  private ref: React.RefObject<any>

  constructor(props: SignupErrorProps) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.ref.current.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { title, text } = this.props;
    return (
      <Grid item className={styles.signupError}>
        <div className={styles.container} ref={this.ref}>
          <Error className={styles.errorIcon} fontSize="large" />
          <div>
            <Typography variant="body1" className={styles.title}>
              {title}
            </Typography>
            <Typography variant="body2" className={styles.text}>
              {text}
            </Typography>
          </div>
        </div>
        <div className={styles.triangle} />
      </Grid>
    );
  }
}
