import React, { Component } from 'react';
import { 
  Switch, Route, RouteComponentProps, Redirect 
} from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { ServiceTermsComponent } from './service-terms/ServiceTermsComponent';
import { LoginContainer, LoginState } from './LoginContainer';
import { SignupContainer, SignupState } from './SignupContainer';
import { NotFoundPage } from '../../../common/not-found-page';
import { InitAuthResponse } from './InitAuthResponse';
import { SignupCodeVerificationContainer } from './SignupCodeVerificationContainer';
import { LoginCodeVerificationContainer } from './LoginCodeVerificationContainer';
import { WelcomeContainer } from '../WelcomeContainer';
import { PasswordContainer } from './PasswordContainer';
import { FormErrors } from '../common/FormErrors';
import config from '../../../config';
import { AppContext, IAppContext } from '../../core/AppContext';
import { ExternalRedirect } from '../../../common/external-redirect';
import { AccountRestrictedRoute } from '../../../common/account-restricted-route';

const INVALID_OTP = 'The login code you entered is incorrect. Please try again.';
const INVALID_PASSWORD_OR_OTP = 'The password or code you entered is incorrect. Please try again.';
const INVALID_PASSWORD = 'The password you entered is incorrect. Please try again.';

interface Props {
  returnUrl?: string;
}

interface AccountContainerState {
  signupData?: SignupState;
  loginData?: LoginState;
  authInitData?: InitAuthResponse;
  password?: string;
  otp?: string;
  error?: FormErrors;
  submitting?: boolean;
  redirect: string;
  returnUrl?: string;
}

export class LegacyAccountContainer extends Component<Props & RouteComponentProps, AccountContainerState> {
  static contextType = AppContext; // use below with this.context

  state: AccountContainerState = {
    redirect: '',
    returnUrl: this.props.returnUrl
  }

  private afterSignup = (signUpState: SignupState) => {
    const { initAuthResponse } = signUpState;
    this.setState({
      signupData: signUpState,
      authInitData: initAuthResponse as InitAuthResponse
    });

    if (initAuthResponse && initAuthResponse.passwordRequired) {
      this.setState({
        loginData: {
          email: signUpState.email,
          phone: signUpState.phone
        },
        authInitData: initAuthResponse,
        returnUrl: '/profile'
      });
      this.props.history.push(this.getRedirectUrl('/account/legacy/password'));
    } else {
      this.props.history.push(this.getRedirectUrl('/account/legacy/code'));
    }
  }

  private afterInitLogin = (loginState: LoginState) => {
    const initAuthResponse = loginState.initAuthResponse as InitAuthResponse;

    this.setState({
      loginData: loginState,
      authInitData: initAuthResponse,
      
    });

    if (this.state.authInitData && this.state.authInitData.passwordRequired) {
      this.props.history.push(this.getRedirectUrl('/account/legacy/password'));
    } else {
      this.props.history.push(this.getRedirectUrl('/account/legacy/code'));
    }
  }

  private afterSignupCodeVerification = async () => {
    await this.acceptPolicies();
    this.setState({ redirect: '/' });
  }

  private handlePasswordSubmit = (password: string) => {
    if (this.state.authInitData && this.state.authInitData.otpRequired) {
      this.setState({ password }, () => this.otpRedirect());
    } else {
      this.setState({ password, submitting: true }, this.login);
    }
  }

  private otpRedirect = () => {
    this.props.history.replace(this.getRedirectUrl('/account/legacy/code'));
  }

  private handleOTPSubmit = (otp: string) => {
    this.setState({ otp, submitting: true }, this.login);
  }

  private login = async () => {
    try {
      const response: AxiosResponse = await axios(`${config.apiRoot}/internal_api/auth/login/`, {
        method: 'POST',
        withCredentials: true,
        data: {
          email: this.state.loginData!.email,
          phone: this.state.loginData!.phone,
          otp: this.state.otp,
          password: this.state.password
        }
      });

      if (this.state.signupData && this.state.signupData.policiesAccepted) {
        await this.acceptPolicies();
      }

      this.context.setAppContext({ 
        user: response.data.user, 
      });
      this.setState(prev => ({ redirect: prev.returnUrl || '/' }));
    } catch (e) {
      console.warn(e);
      const responseError = e.response.data.error;
      this.parseLoginErrors(responseError);
    }
  }

  private acceptPolicies = async () => {
    try {
      await axios(`${config.apiRoot}/internal_api/policies/accept/`, {
        method: 'POST',
        withCredentials: true,
        data: {
          policies: this.state.signupData!.policiesNeeded.map(p => p.id)
        }
      });
    } catch (e) {
      console.warn(e);
    }
  }

  private editLoginPhoneOrEmailOnVerification = () => {
    this.props.history.replace(this.getRedirectUrl('/account/legacy/login'));
  }

  private editSignupPhoneOrEmailOnVerification = () => {
    const appState = this.context as IAppContext;
    this.props.history.replace(this.getRedirectUrl(`/account/legacy/signup/${appState.account.slug}`));
  }

  private getRedirectUrl = (redirect: string) => {
    if (this.state.returnUrl) {
      return `${redirect}?returnUrl=${this.state.returnUrl}`;
    }
    return redirect;
  }

  private parseLoginErrors(responseError: string) {
    if (this.state.authInitData && this.state.authInitData.passwordRequired) {
      if (responseError === 'incorrect_otp') {
        this.setState({
          error: {
            fieldErrors: {
              code: INVALID_PASSWORD_OR_OTP
            }
          },
          submitting: false
        });
        this.props.history.push('/account/legacy/password');
      } else if (responseError === 'incorrect_password') {
        this.setState({
          error: {
            fieldErrors: {
              code: INVALID_PASSWORD
            }
          },
          submitting: false
        });
        this.props.history.push('/account/legacy/password');
      } else {
        this.setState({
          error: {
            generic: {
              text: 'Whoops.',
              title: 'There was a problem with your request. Please try again.'
            }
          },
          submitting: false
        });
        this.props.history.push('/account/legacy/password');
      }
    } else if (responseError === 'incorrect_otp') {
      this.setState({
        error: {
          fieldErrors: {
            code: INVALID_OTP
          }
        },
        submitting: false
      });
    } else {
      this.setState({
        error: {
          generic: {
            text: 'Whoops.',
            title: 'There was a problem with your request. Please try again.'
          }
        },
        submitting: false
      });
    }
  }

  render() {
    if (this.state.redirect) {
      if (this.state.redirect.startsWith('http')) {
        return <ExternalRedirect to={this.state.redirect} />;
      }
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <Switch location={this.props.location}>
        <AccountRestrictedRoute
          accounts={config.accountsWithNewRegistration}
          redirect={`${config.lassoUrlBase}/register`} 
          exact 
          path="/account/legacy/welcome/:slug" 
          component={WelcomeContainer} 
        />
        <AccountRestrictedRoute
          accounts={config.accountsWithNewRegistration}
          redirect={`${config.lassoUrlBase}/register`} 
          path="/account/legacy/signup/:slug"
          render={props => (
            <SignupContainer 
              onContinue={this.afterSignup} 
              signupState={this.state.signupData} 
              {...props} 
            />
          )}
        />
        <Route
          path="/account/legacy/login"
          render={() => <LoginContainer onComplete={this.afterInitLogin} loginState={this.state.loginData} />}
        />
        {(this.state.loginData) && (
        <Route
          path="/account/legacy/code"
          render={() => (
            <LoginCodeVerificationContainer
              email={this.state.loginData!.email}
              phone={this.state.loginData!.phone}
              edit={this.editLoginPhoneOrEmailOnVerification}
              lastFour={this.state.authInitData!.lastFour}
              error={this.state.error!}
              onSubmit={this.handleOTPSubmit}
              isSubmitting={this.state.submitting}
            />
          )}
        />
        )}
        {(this.state.signupData) && (
        <Route
          path="/account/legacy/code"
          render={() => (
            <SignupCodeVerificationContainer
              onComplete={this.afterSignupCodeVerification}
              phone={this.state.signupData!.phone}
              email={this.state.signupData!.email}
              firstName={this.state.signupData!.firstName}
              lastName={this.state.signupData!.lastName}
              edit={this.editSignupPhoneOrEmailOnVerification}
            />
          )}
        />
        )}        
        {(this.state.authInitData && this.state.authInitData.passwordRequired) && (
        <Route
          path="/account/legacy/password"
          render={() => (
            <PasswordContainer
              onContinue={this.handlePasswordSubmit}
              error={this.state.error!}
              isContinuing={this.state.submitting}
            />
          )}
        />
        )}
        <Route path="/account/legacy/service-terms" component={ServiceTermsComponent} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}
