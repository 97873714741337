import React, { Component } from 'react';
import { DatePicker } from 'material-ui-pickers';
import { QuestionHeading } from '../question-heading';

interface Props {
  question: string,
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6',
  status: 'required'|'optional',
  displayRequirednessMarker: boolean,
  selectedDate: string,
  handleDateChange: any,
  errorMessage?: string,
}

export class DateComponent extends Component<Props> {
  render() {
    return (
      <React.Fragment>
        <QuestionHeading
          question={this.props.question}
          textVariant={this.props.textVariant}
          status={this.props.status}
          displayRequirednessMarker={this.props.displayRequirednessMarker}
        />
        <DatePicker
          keyboard
          placeholder="mm/dd/yyyy"
          margin="normal"
          label="Date"
          format="MM/DD/YYYY"
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          value={this.props.selectedDate}
          onChange={this.props.handleDateChange}
          openTo="year"
          error={this.props.errorMessage !== undefined}
          helperText={this.props.errorMessage}
        />
      </React.Fragment>
    );
  }
}
