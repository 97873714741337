import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { CountryCode } from 'libphonenumber-js/core';
import { SignupComponent } from './SignupComponent';
import { PolicyObject } from './service-terms/PolicyAgreementComponent';
import config from '../../../config';
import { SignupErrorProps } from '../common/SignupErrorComponent';
import { InitAuthResponse } from './InitAuthResponse';
import { LoadingComponent } from '../../../common/loading-component';
import { FormErrors } from '../common/FormErrors';


interface Props {
  onContinue(identifiers: SignupState): void;
  signupState?: SignupState;
}

export interface SignupState {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  country: CountryCode,
  initAuthResponse?: InitAuthResponse
  error?: FormErrors
  policiesNeeded: Array<PolicyObject>,
  policiesAccepted: boolean,
  policiesLoading: boolean,
  submitting: boolean
}

export class SignupContainer extends React.Component<Props> {
  state: SignupState = {
    firstName: this.props.signupState ? this.props.signupState.firstName : '',
    lastName: this.props.signupState ? this.props.signupState.lastName : '',
    email: this.props.signupState ? this.props.signupState.email : '',
    phone: this.props.signupState ? this.props.signupState.phone : '',
    country: this.props.signupState ? this.props.signupState.country : 'US',
    error: undefined,
    policiesNeeded: [],
    policiesAccepted: false,
    policiesLoading: true,
    submitting: false
  };

  async componentDidMount() {
    await this.loadNeededPolicies();
  }

  private loadNeededPolicies = async () => {
    try {
      const response: AxiosResponse = await axios(`${config.apiRoot}/internal_api/policies/`, {
        method: 'GET',
        withCredentials: true,
      });
      this.setState({ policiesNeeded: response.data.policies, policiesLoading: false });
    } catch (e) {
      console.warn(e);
    }
  };

  private handleSubmit = async () => {
    this.setState({ 
      submitting: true,
      error: undefined
    });
    
    let response: AxiosResponse;
    try {
      response = await axios(`${config.apiRoot}/internal_api/auth/register_init/`, {
        method: 'POST',
        withCredentials: true,
        data: {
          phone: this.state.phone,
          email: this.state.email
        }
      });
    } catch (e) {
      if (e.response && e.response.status === 400) {
        const { data } = e.response;      
        let formErrors: FormErrors;  
        if (data.error === 'invalid') {
          formErrors = {
            fieldErrors: data.error_detail
          };
        } else {
          formErrors = {
            generic: {
              title: 'Whoops! You entered an incorrect email address or mobile number.',
              text: `If you already have a LASSO profile, click the Login link below. 
              For help, contact customer support at support@lasso.io.`
            } as SignupErrorProps
          };
        }
        
        this.setState({
          error: formErrors,
          showError: true
        });        
      }

      this.setState({ submitting: false });
      return;
    }

    const data: InitAuthResponse = {
      otpRequired: response.data.otp_required,
      passwordRequired: response.data.password_required,
      newAccount: response.data.new_account,
      lastFour: response.data.last_four
    };            
    this.setState({ initAuthResponse: data });

    this.props.onContinue(this.state);
  }

  private handleChange = (key: string, value: any): void => {
    this.setState({ [key]: value });
  }

  private toggleBoolean = (checkboxKey: string) => {
    const currentToggleState: boolean = (this.state as any)[checkboxKey];
    this.setState({ [checkboxKey]: !currentToggleState });
  }

  private canSubmit = (): boolean => {
    const state = { ...this.state, error: undefined };
    return !Object.values(state).includes('') 
      && (state.policiesAccepted || state.policiesNeeded.length === 0)
      && !state.submitting;
  }

  private selectedCountry = (countryCode?: string | undefined) => {
    this.setState({ country: countryCode });
  }

  render() {
    if (this.state.policiesLoading) {
      return <LoadingComponent />;
    }
    
    return (
      <SignupComponent
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        toggleBoolean={this.toggleBoolean}
        state={{ ...this.state }}
        canSubmit={this.canSubmit()}
        error={this.state.error}
        policiesNeeded={this.state.policiesNeeded}
        changeCountry={this.selectedCountry}
      />
    );
  }
}
