import React, { useContext, Suspense } from 'react';
import {
  Switch, Route, Redirect
} from 'react-router-dom';
import { AccountContainer } from '../account/AccountContainer';
import { ProfileContainer } from '../crew-profile/ProfileContainer';
import { NotFoundPage } from '../../common/not-found-page';
import { AppContext } from './AppContext';
import { DetermineRedirectContainer } from './DetermineRedirectContainer';
import { LoadingComponent } from '../../common/loading-component';


export const AppRoutes: React.FC<any> = () => {
  const { user } = useContext(AppContext);

  return (
    <Suspense fallback={<LoadingComponent />}>
      <Switch>
        <Route path="/account" component={AccountContainer} />
        {user.isAuthenticated && <Route path="/profile" component={ProfileContainer} />}
        {user.isAuthenticated && <Route path="/" component={DetermineRedirectContainer} />}
        {user.isAuthenticated && <Route component={NotFoundPage} />}
        {!user.isAuthenticated && <Route path="/" render={() => <Redirect to="/account/login" />} />}
      </Switch>
    </Suspense>
  );
};
