import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import {
  FormGroup,
  Typography,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import { QuestionHeading } from '../question-heading';
import { OptionObject } from '../../../../../common/types/input-options';
import { CheckBoxElement } from '../check-box-element';
import styles from './multiple-selector.module.scss';
import { QuestionObject, DropdownSelectQuestionObject } from '../question-component/QuestionComponent';

interface Props {
  question: string;
  enableSelectAll?: boolean;
  helpText?: string;
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6';
  status: 'required'|'optional';
  displayRequirednessMarker: boolean;
  options: OptionObject[] | { [category: string]: OptionObject[] };
  onChange?: (event: React.ChangeEvent<{}>, checked: boolean) => void;
  value?: string[];
  followupQuestions?: { [optionId: string]: (QuestionObject | DropdownSelectQuestionObject) };
  errorMessage?: string;
  atMaxCapacity: boolean;
}

export class MultipleSelectorComponent extends Component<Props> {
  renderInputs() {
    if (this.props.options instanceof Array) {
      return (
        <Grid container item direction="column" className={this.props.enableSelectAll ? styles.indent : undefined}>
          {
            this.props.options.map((option) => {
              let followupQuestion = null;
              if (this.props.followupQuestions && option.value in this.props.followupQuestions) {
                followupQuestion = this.props.followupQuestions[option.value];
              }

              const checked = this.props.value !== null ? this.props.value!.indexOf(option.value) > -1 : false;
              return (
                <Grid item key={option.key}>
                  <CheckBoxElement
                    onChange={this.props.onChange}
                    option={option}
                    checked={checked}
                    followupQuestion={followupQuestion as (QuestionObject | DropdownSelectQuestionObject)}
                    disabled={!checked && this.props.atMaxCapacity}
                  />
                </Grid>
              );
            })
          }
        </Grid>
      );
    }

    const result: JSX.Element[] = [];
    Object.keys(this.props.options).forEach((key: string) => {
      const options = this.props.options as { [category: string]: OptionObject[] };
      result.push(
        <Grid item className={styles.category} key={key}>
          <MultipleSelectorComponent
            question={key.toUpperCase()}
            textVariant="body1"
            status={this.props.status}
            options={options[key]}
            displayRequirednessMarker={false}
            onChange={this.props.onChange}
            value={this.props.value}
            followupQuestions={this.props.followupQuestions}
            atMaxCapacity={this.props.atMaxCapacity}
          />
        </Grid>
      );
    });

    return result;
  }

  render() {
    let errorMessage = null;
    if (this.props.errorMessage !== undefined) {
      errorMessage = <FormHelperText error>{this.props.errorMessage!}</FormHelperText>;
    }
    let selectAllCheckbox = null;
    if (this.props.enableSelectAll) {
      selectAllCheckbox = (
        <CheckBoxElement
          key={uuid()}
          onChange={this.props.onChange}
          option={{ key: uuid(), value: 'selectAllCheckbox', display: 'Select All' }}
          checked={this.props.value!.length === this.props.options.length}
          disabled={false}
        />
      );
    }

    return (
      <>
        <QuestionHeading
          question={this.props.question}
          textVariant={this.props.textVariant}
          status={this.props.status}
          displayRequirednessMarker={this.props.displayRequirednessMarker}
        />
        <Typography variant="body1" color="textSecondary">
          {this.props.helpText}
        </Typography>
        <FormGroup>
          {selectAllCheckbox}
          {this.renderInputs()}
          {errorMessage}
        </FormGroup>
      </>
    );
  }
}
