import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Hidden } from '@material-ui/core';
import { LassoLogo } from '../../../common/icons/lasso-logo';
import styles from './registration-header.module.scss';

type Props = {
  title?: string;
  message?: string | React.ReactNode;
}

export class RegistrationHeader extends Component<Props> {
  render() {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        spacing={32}
      >
        <div className={styles.headerSpcace} />
        <Grid item>
          <LassoLogo />
        </Grid>
        {this.props.title && (
          <Grid item>
            <Typography align="center" variant="h5">{this.props.title}</Typography>
          </Grid>
        )}
        {this.props.message && (
          <Hidden smDown>
            <Grid item>
              {this.props.message}
            </Grid>
          </Hidden>
        )}
      </Grid>
    );
  }
}
