import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Button } from '../../common/button';
import { MarketplaceLogo } from '../../common/icons/marketplace/MarketplaceLogo';
import { LogInLink } from '../../common/login-link';
import styles from './welcome.module.scss';
import { NormalLayout } from '../../common/layouts/NormalLayout';
import { SignupErrorProps, SignupErrorComponent } from './common/SignupErrorComponent';

interface Props {
  onContinue(): Promise<void> | void;
  hideLogin?: boolean;
  error?: SignupErrorProps;
  loginRedirect?: string;
}

export const WelcomeComponent: React.FC<Props> = ({
  onContinue, hideLogin = false,
  error, loginRedirect
}) => {
  const [clicked, setClicked] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <NormalLayout square={false}>
        <Grid container justify="center" alignItems="stretch" spacing={32}>
          {error && (
            <Grid
              item
              container
              direction="column"
              justify="flex-start"
            >
              <SignupErrorComponent
                title={error.title}
                text={error.text}
              />
            </Grid>
          )}
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justify="space-evenly"
            className={styles.welcomeContainer}
            data-id="container?"
          >
            <Grid item>
              <MarketplaceLogo />
            </Grid>
            <div className={styles.iframeWrapper}>
              { /* url="https://www.youtube.com/embed/Hqo580VrQqk" /> */}
              <iframe
                title="10 Reasons"
                src="https://www.youtube.com/embed/Hqo580VrQqk"
                allowFullScreen
                frameBorder="0"
              />
            </div>
            <Grid
              item
              container
              direction="column"
              spacing={24}
              alignItems="center"
              className={styles.content}
              wrap="nowrap"
            >
              <Grid item xs={10} sm={11}>
                
                <Typography
                  variant="h5"
                  align="left"
                  gutterBottom
                  style={{ marginTop: '30px' }}
                >
                  {t('AREAS.ACCOUNT.COMMUNITY_TITLE')}
                </Typography>
                
                <p>{t('AREAS.ACCOUNT.GIG_ECONOMY')}</p>

                <p>
                  <Trans i18nKey="AREAS.ACCOUNT.INTRO" />
                </p>
                <article className={styles.mpTalkPoints}>
                  <div className={styles.colLeft}>
                    <img 
                      className={styles.mpRegIcon} 
                      src="/icons/welcome-images/briefcase_icn.svg" 
                      alt={t('AREAS.ACCOUNT.IMAGE_ALTS.BRIEFCASE')} 
                    />
                    <img 
                      className={styles.mpRegIcon} 
                      src="/icons/welcome-images/people_icn.svg" 
                      alt={t('AREAS.ACCOUNT.IMAGE_ALTS.PEOPLE')} 
                    />
                    <img 
                      className={styles.mpRegIcon} 
                      src="/icons/welcome-images/mobile_phone_icn.svg" 
                      alt={t('AREAS.ACCOUNT.IMAGE_ALTS.PHONE')} 
                    />
                  </div>
                  <div className={styles.colMid}>
                    <Typography 
                      variant="h5" 
                      align="center" 
                      gutterBottom
                    >
                      {t('AREAS.ACCOUNT.WHY.HEADER')}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      align="center" 
                      gutterBottom 
                      className={styles.reasonText}
                    >
                      {t('AREAS.ACCOUNT.WHY.LAND_MORE_GIGS')}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      align="center" 
                      gutterBottom 
                      className={styles.reasonText}
                    >
                      {t('AREAS.ACCOUNT.WHY.GET_PAID_WEEKLY')}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      align="center" 
                      gutterBottom 
                      className={styles.reasonText}
                    >
                      {t('AREAS.ACCOUNT.WHY.GAIN_EXPOSURE')}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      align="center" 
                      gutterBottom 
                      className={styles.reasonText}
                    >
                      {t('AREAS.ACCOUNT.WHY.CONTROL_SCHEDULE')}
                    </Typography>
                    <Typography 
                      variant="h6" 
                      align="center" 
                      gutterBottom 
                      className={styles.reasonText}
                    >
                      {t('AREAS.ACCOUNT.WHY.MANAGE')}
                    </Typography>
                  </div>
                  <div className={styles.colRight}>
                    <img 
                      className={styles.mpRegIcon} 
                      src="/icons/welcome-images/money_icn.svg" 
                      alt={t('AREAS.ACCOUNT.IMAGE_ALTS.HAND_DOLLAR')} 
                    />  
                    <img 
                      className={styles.mpRegIcon} 
                      src="/icons/welcome-images/calendar_icn.svg" 
                      alt={t('AREAS.ACCOUNT.IMAGE_ALTS.CALENDAR')} 
                    />
                  </div>
                </article>

                <p>
                  <Typography variant="subtitle2" align="center">
                    {t('AREAS.ACCOUNT.HAVE_QUESTIONS')}
                    <a
                      href="https://www.lasso.io/lasso-marketplace-frequently-asked-questions/"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-test-id="welcome__link--FAQ"
                    >
                      {t('AREAS.ACCOUNT.FAQ')}
                    </a>
                  </Typography>
                </p>
                <p>
                  <Typography variant="subtitle2" align="center">
                    {t('AREAS.ACCOUNT.CONTACT')} 
                    <a href="mailto:marketplace@lasso.io">marketplace@lasso.io</a>
                  </Typography>
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </NormalLayout>
      <div className={styles.footer}>
        <Button
          wide
          disabled={clicked}
          onClick={() => {
            setClicked(true);
            onContinue();
          }}
          style={{ backgroundColor: '#F4C43D', color: '#020122' }}
          data-test-id="welcome__button--applynow"
        >
          {t('AREAS.ACCOUNT.APPLY_NOW')}
        </Button>
        {!hideLogin && (
          <div className={styles.loginText}>
            <LogInLink
              text={t('AREAS.ACCOUNT.ALREADY_HAVE_ACCOUNT')}
              returnUrl={loginRedirect}
              variant="secondary"
            />
          </div>
        )}
      </div>
    </>
  );
};
