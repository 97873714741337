import React, { Component } from 'react';
import {
  Input,
  Snackbar,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import styles from './copy-text-input.module.scss';

interface Props {
  inputText: string;
  includeSnackbar: boolean;
}

interface State {
  linkCopied: boolean;
}

export class CopyTextInput extends Component<Props, State> {
  state: State = {
    linkCopied: false,
  }

  handleClose = () => {
    this.setState({ linkCopied: false });
  };

  copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = this.props.inputText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    if (this.props.includeSnackbar) {
      this.setState({ linkCopied: true });
    }
  }

  render() {
    const adornment = (
      <InputAdornment className={styles.adornment} position="end">
        <LinkIcon fontSize="inherit" onClick={this.copyToClipboard} />
      </InputAdornment>
    );

    return (
      <React.Fragment>
        <Input
          readOnly
          disableUnderline
          className={styles.input}
          inputProps={{ className: styles.inputText }}
          endAdornment={adornment}
          value={this.props.inputText}
          data-test-id="copytextinput__input"
        />
        {this.props.includeSnackbar && (
          <Snackbar
            open={this.state.linkCopied}
            autoHideDuration={3000}
            onClose={this.handleClose}
            ContentProps={{ className: styles.snackbarContent }}
            message={<Typography variant="body1" className={styles.message}>Link copied to clipboard</Typography>}
            data-test-id="copytextinput__snackbar--copytextmessage"
          />
        )}
      </React.Fragment>
    );
  }
}
