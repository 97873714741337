import React, { Component, FormEvent } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MaterialUiPickersDate } from 'material-ui-pickers/typings/date';
import { ValueType, ActionMeta } from 'react-select/lib/types';
import { ShortAnswerComponent } from '../short-answer';
import { CurrencyComponent } from '../currency';
import { FileUploadContainer } from '../file-upload';
import { MultipleChoiceContainer } from '../multiple-choice';
import { MultipleSelectorContainer } from '../multiple-selector';
import { DateContainer } from '../date';
import { PersonInformationComponent, PersonalInformationData } from '../person-information';
import { DropdownSelectComponent } from '../dropdown-select';
import { DisplayValuePair, OptionObject } from '../../../../../common/types/input-options';
import {
  SingleSelectAutocompleteContainer,
  Option
} from '../autocomplete/single-select-autocomplete';

import {
  AirportAutocompleteContainer,
} from '../autocomplete/AirportAutocompleteContainer';
import styles from './question-component.module.scss';
import { FileSubmission } from '../../../review/submission';


export interface PersonInformationField {
  key: string;
  label: string;
}

export interface QuestionObject {
  key: string;
  question: string;
  placeholder?: string;
  prefix?: string;
  suffix?: string;
  helpText?: string;
  input: 'shortAnswer' |
  'multipleSelectCheckbox' |
  'multipleChoiceRadio' |
  'fileUpload' |
  'date' |
  'personInformation' |
  'currency';
  status: 'required' | 'optional';
  enableSelectAll?: boolean;
  options?: DisplayValuePair[] | { [category: string]: DisplayValuePair[] };
  categoryOrder?: string[];
  acceptedFileTypes?: string[];
  personInformationFields?: PersonInformationField[];
  maxInputs?: number;
  onChangeFunction?: React.EventHandler<FormEvent>
  | ((event: React.ChangeEvent<{}>, value: string | any) => void)
  | ((event: React.ChangeEvent<{}>, checked: boolean) => void)
  | ((date: MaterialUiPickersDate) => void)
  | ((event: React.ChangeEvent<HTMLSelectElement>) => void)
  | ((event: React.ChangeEvent<HTMLInputElement>) => void)
  | ((personalInformation: PersonalInformationData, action: 'update' | 'delete') => void),
  value?: string | string[] | PersonalInformationData[] | Option | FileSubmission,
  followupQuestions?: { [optionId: string]: (QuestionObject | DropdownSelectQuestionObject) },
  error?: string,
  maxLength?: number;
  type: 'custom' | 'standard';
}

export interface DropdownSelectOption {
  key: string;
  value: string | number;
  display: string | number;
}

export interface DropdownSelectQuestionObject {
  key: string,
  question: string,
  input: 'dropdownSelect',
  status: 'required' | 'optional',
  placeholder: string,
  options: DropdownSelectOption[],
  onChangeFunction?: ((event: React.ChangeEvent<HTMLSelectElement>) => void),
  value?: string,
  error?: string,
  type?: 'custom' | 'standard';
}

export interface SingleSelectAutocompleteQuestionObject {
  key: string,
  question: string,
  input: 'singleSelectAutocomplete' | 'airportAutocomplete',
  status: 'required' | 'optional',
  placeholder: string,
  options: Option[],
  onChangeFunction?: ((value: ValueType<Option>, action: ActionMeta) => void),
  value?: Option,
  error?: string;
}

interface Props {
  question: (QuestionObject | DropdownSelectQuestionObject | SingleSelectAutocompleteQuestionObject),
  lesserQuestionType: 'required' | 'optional', // todo research the origin of this field
  renderAsFollowup?: boolean
  testingKey?: string
  disableButton?: () => void
  enableButton?: () => void
}

export class QuestionComponent extends Component<Props> {
  render() {
    const { question, lesserQuestionType, renderAsFollowup } = this.props;
    let questionComponent;
    switch (question.input) {
      case 'shortAnswer': {
        questionComponent = (
          <ShortAnswerComponent
            question={question.question}
            placeholder={question.placeholder}
            textVariant="h6"
            status={question.status}
            displayRequirednessMarker={question.status === lesserQuestionType}
            onChange={question.onChangeFunction as React.EventHandler<FormEvent>}
            value={question.value as string}
            errorMessage={question.error}
            maxLength={question.maxLength}
            testingKey={this.props.testingKey}
          />
        );
        break;
      }
      case 'currency': {
        questionComponent = (
          <CurrencyComponent
            question={question.question}
            placeholder={question.placeholder}
            prefix={question.prefix}
            suffix={question.suffix}
            textVariant="h6"
            status={question.status}
            displayRequirednessMarker={question.status === lesserQuestionType}
            onValueChange={question.onChangeFunction as (value: string) => void}
            value={question.value as string}
            errorMessage={question.error}
            testingKey={this.props.testingKey}
          />
        );
        break;
      }
      case 'multipleChoiceRadio': {
        questionComponent = (
          <MultipleChoiceContainer
            question={question.question}
            textVariant="h6"
            status={question.status}
            displayRequirednessMarker={question.status === lesserQuestionType}
            options={question.options as OptionObject[]}
            onChange={question.onChangeFunction as ((event: React.ChangeEvent<{}>, value: string) => void)}
            value={question.value as string}
            errorMessage={question.error}
          />
        );
        break;
      }
      case 'fileUpload': {
        questionComponent = (
          <FileUploadContainer
            question={question.question}
            helpText={question.helpText}
            textVariant="h6"
            status={question.status}
            displayRequirednessMarker={question.status === lesserQuestionType}
            acceptedFileTypes={question.acceptedFileTypes}
            onChange={question.onChangeFunction as (value: any) => void}
            maxInputs={question.maxInputs}
            value={question.value as FileSubmission}
            errorMessage={question.error}
            disableButton={this.props.disableButton}
            enableButton={this.props.enableButton}
            testingKey={this.props.testingKey}
          />
        );
        break;
      }
      case 'multipleSelectCheckbox': {
        questionComponent = (
          <MultipleSelectorContainer
            question={question.question}
            enableSelectAll={question.enableSelectAll}
            followupQuestions={question.followupQuestions}
            helpText={question.helpText}
            textVariant="h6"
            status={question.status}
            options={question.options}
            categoryOrder={question.categoryOrder}
            displayRequirednessMarker={question.status === lesserQuestionType}
            onChange={question.onChangeFunction as ((event: React.ChangeEvent<{}>, checked: boolean) => void)}
            value={question.value as string[]}
            errorMessage={question.error}
            maxInputs={question.maxInputs}
          />
        );
        break;
      }
      case 'date': {
        questionComponent = (
          <DateContainer
            question={question.question}
            textVariant="h6"
            status={question.status}
            displayRequirednessMarker={question.status === lesserQuestionType}
            onChange={question.onChangeFunction as (date: MaterialUiPickersDate) => void}
            originalValue={question.value as string}
            errorMessage={question.error}
          />
        );
        break;
      }
      case 'personInformation': {
        questionComponent = (
          <PersonInformationComponent
            question={question.question}
            helpText={question.helpText}
            textVariant="h6"
            status={question.status}
            displayRequirednessMarker={question.status === lesserQuestionType}
            personInformationFields={question.personInformationFields}
            maxInputs={question.maxInputs}
            existingData={question.value as PersonalInformationData[]}
            onSave={question.onChangeFunction as (personalInformation: PersonalInformationData) => void}
            errorMessage={question.error}
            testingKey={this.props.testingKey}
          />
        );
        break;
      }
      case 'dropdownSelect': {
        questionComponent = (
          <DropdownSelectComponent
            question={question.question}
            textVariant="h6"
            status={question.status}
            displayRequirednessMarker={question.status === this.props.lesserQuestionType}
            placeholder={question.placeholder}
            options={question.options}
            onChange={question.onChangeFunction as (event: React.ChangeEvent<HTMLSelectElement>) => void}
            originalValue={question.value as string}
            errorMessage={question.error}
          />
        );
        break;
      }
      case 'singleSelectAutocomplete': {
        questionComponent = (
          <SingleSelectAutocompleteContainer
            question={question.question}
            textVariant="h6"
            status={question.status}
            displayRequirednessMarker={question.status === this.props.lesserQuestionType}
            placeholder={question.placeholder}
            options={question.options}
            onChange={
              question.onChangeFunction as (value: ValueType<Option>, action: ActionMeta) => void
            }
            selected={question.value as Option}
            testingKey={this.props.testingKey}
          />
        );
        break;
      }
      case 'airportAutocomplete': {
        questionComponent = (
          <AirportAutocompleteContainer
            question={question.question}
            textVariant="h6"
            status={question.status}
            displayRequirednessMarker={question.status === this.props.lesserQuestionType}
            placeholder={question.placeholder}
            options={question.options}
            onChange={
              question.onChangeFunction as (value: ValueType<Option>, action: ActionMeta) => void
            }
            selected={question.value as Option}
            testingKey={this.props.testingKey}
          />
        );
        break;
      }
      default: questionComponent = (
        <Typography variant="h6" data-test-id={`question__typeunavailable--${this.props.testingKey}`}>
          Question type not available.
        </Typography>
      );
    }

    const classnames = [];
    if (renderAsFollowup) {
      classnames.push(styles.followupQuestion);
    }

    if (question.error !== undefined) {
      classnames.push(styles.error);
    }

    return (
      <Grid item key={question.key} className={classnames.join(' ')}>
        {questionComponent}
      </Grid>
    );
  }
}
