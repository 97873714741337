/* eslint-disable max-len */
import React from 'react';

interface Props {
  width?: number;
  height?: number;
}


export const MarketplaceLogo: React.FC<Props> = props => (
  <svg
    width={props.width ? props.width : 257}
    height={props.height ? props.height : 25}
    viewBox="0 0 592 57"
  >
    <g id="Artboard-Copy">
      <path
        id="Fill-7-Copy-2"
        fillRule="evenodd"
        fill="#FFBF00"
        d="M16.93,36.11c1.32,3.56,3.52,6.73,6.4,9.21
          c2.85,2.62,6.59,4.03,10.46,3.93c2.42-0.19,4.67-1.34,6.24-3.2c1.24-1.39,1.87-3.23,1.73-5.09c-0.55-3.38-2.79-6.25-5.95-7.58
          C29.63,30.74,21.98,33.08,16.93,36.11z M2.76,56.55H2.47c-1.5-0.17-2.59-1.5-2.46-3.01c0.81-7.7,4.61-14.78,10.59-19.7
          c-1.11-6.59,0.31-13.35,3.97-18.93C19.98,6.77,28.68,1.4,38.39,0.2c19.19-2.69,35.6,8.8,38.12,26.39c1.02,8.2-1.28,16.47-6.4,22.96
          c-3.31,4.19-8.29,6.73-13.62,6.94c-1.52,0.05-2.79-1.14-2.85-2.65c-0.05-1.52,1.14-2.79,2.65-2.85C60.08,50.82,63.59,49,65.9,46
          c4.09-5.29,5.95-11.97,5.18-18.61C68.71,10.69,52.78,3.72,39.09,5.63C31,6.62,23.74,11.08,19.2,17.85
          c-2.42,3.74-3.62,8.15-3.42,12.6c6.2-3.2,14.87-5.28,22.39-2.01c4.95,2.13,8.43,6.69,9.18,12.03c0.32,3.33-0.73,6.64-2.91,9.18
          c-2.55,2.96-6.15,4.8-10.04,5.12c-5.3,0.2-10.47-1.7-14.39-5.28c-3.2-2.68-5.76-6.05-7.48-9.85c-3.92,3.88-6.4,8.98-7.04,14.46
          C5.33,55.48,4.16,56.54,2.76,56.55z"
      />
    </g>
    <g>
      <path fill="#444444" d="M94.63,38.78l13.06,0.02l-0.01,3.64l-17.15-0.03l0.04-29.27l4.09,0.01L94.63,38.78z" />
      <path
        fill="#444444"
        d="M117.07,42.45l-4.55-0.01l12.73-29.25l3.72,0.01l12.52,29.29l-4.63-0.01l-2.97-7.2l-13.89-0.02
            L117.07,42.45z M121.47,31.67l10.95,0.02l-5.44-13.73L121.47,31.67z"
      />
      <path
        fill="#444444"
        d="M164.06,18.67c-0.58-0.8-1.36-1.46-2.35-1.97c-0.99-0.51-2.11-0.77-3.35-0.77c-0.66,0-1.33,0.09-2.01,0.27
          c-0.68,0.18-1.29,0.47-1.84,0.86c-0.55,0.4-1,0.89-1.35,1.49c-0.35,0.59-0.52,1.3-0.52,2.13c0,0.8,0.16,1.47,0.47,2.01
          c0.32,0.54,0.74,0.99,1.28,1.37c0.54,0.37,1.17,0.69,1.9,0.95c0.73,0.26,1.51,0.53,2.33,0.81c0.99,0.31,2,0.65,3.02,1.04
          c1.02,0.39,1.94,0.9,2.77,1.53c0.83,0.64,1.5,1.43,2.02,2.38c0.52,0.95,0.78,2.16,0.78,3.62c0,1.52-0.29,2.83-0.85,3.95
          c-0.57,1.12-1.32,2.04-2.26,2.77c-0.94,0.73-2.02,1.27-3.25,1.63c-1.23,0.36-2.49,0.53-3.78,0.53c-1.85,0-3.63-0.36-5.35-1.08
          s-3.15-1.78-4.27-3.19l3.19-2.68c0.69,1.02,1.61,1.85,2.77,2.48c1.16,0.64,2.41,0.96,3.76,0.96c0.69,0,1.38-0.09,2.07-0.29
          c0.69-0.19,1.32-0.49,1.88-0.91c0.57-0.41,1.03-0.93,1.39-1.55c0.36-0.62,0.54-1.37,0.54-2.25c0-0.88-0.18-1.62-0.55-2.21
          c-0.37-0.59-0.87-1.1-1.51-1.51c-0.63-0.41-1.37-0.77-2.21-1.06c-0.84-0.29-1.73-0.59-2.66-0.89c-0.94-0.28-1.86-0.62-2.77-1.02
          c-0.91-0.4-1.72-0.91-2.44-1.53c-0.72-0.62-1.29-1.39-1.73-2.3c-0.44-0.91-0.66-2.04-0.66-3.39c0-1.46,0.3-2.71,0.89-3.76
          c0.59-1.05,1.37-1.91,2.32-2.6c0.95-0.69,2.03-1.19,3.23-1.5c1.2-0.31,2.4-0.47,3.62-0.47c1.71,0,3.31,0.31,4.79,0.92
          c1.49,0.61,2.68,1.42,3.59,2.44L164.06,18.67z"
      />
      <path
        fill="#444444"
        d="M191.8,18.71c-0.58-0.8-1.36-1.46-2.35-1.97c-0.99-0.51-2.11-0.77-3.35-0.77c-0.66,0-1.33,0.09-2.01,0.27
          c-0.68,0.18-1.29,0.47-1.84,0.87c-0.55,0.4-1,0.89-1.35,1.49c-0.35,0.59-0.52,1.3-0.52,2.13c0,0.8,0.16,1.47,0.47,2.01
          c0.32,0.54,0.74,0.99,1.28,1.37c0.54,0.37,1.17,0.69,1.9,0.95c0.73,0.26,1.51,0.53,2.33,0.81c0.99,0.3,2,0.65,3.02,1.04
          c1.02,0.39,1.94,0.9,2.77,1.53c0.83,0.64,1.5,1.43,2.02,2.38c0.52,0.95,0.78,2.16,0.78,3.62c0,1.52-0.29,2.83-0.85,3.95
          s-1.32,2.04-2.26,2.77c-0.94,0.73-2.02,1.27-3.25,1.63c-1.23,0.36-2.49,0.53-3.78,0.53c-1.85,0-3.63-0.36-5.35-1.08
          c-1.72-0.72-3.15-1.78-4.27-3.19l3.19-2.68c0.69,1.02,1.61,1.85,2.77,2.48c1.16,0.64,2.41,0.95,3.76,0.96
          c0.69,0,1.38-0.09,2.07-0.29c0.69-0.19,1.32-0.49,1.88-0.91c0.57-0.41,1.03-0.93,1.39-1.55c0.36-0.62,0.54-1.37,0.54-2.25
          c0-0.88-0.18-1.62-0.55-2.21c-0.37-0.59-0.87-1.1-1.51-1.51c-0.63-0.41-1.37-0.77-2.21-1.06c-0.84-0.29-1.73-0.59-2.66-0.89
          c-0.94-0.28-1.86-0.62-2.77-1.02c-0.91-0.4-1.72-0.91-2.44-1.53c-0.72-0.62-1.29-1.39-1.73-2.3c-0.44-0.91-0.66-2.04-0.66-3.39
          c0-1.46,0.3-2.71,0.89-3.76c0.59-1.05,1.37-1.91,2.32-2.6c0.95-0.69,2.03-1.19,3.23-1.5c1.2-0.31,2.4-0.47,3.62-0.47
          c1.71,0,3.31,0.31,4.79,0.92c1.49,0.61,2.68,1.42,3.59,2.44L191.8,18.71z"
      />
      <path
        fill="#444444"
        d="M234.49,27.95c0,2.26-0.4,4.33-1.19,6.22c-0.79,1.89-1.87,3.51-3.25,4.87c-1.38,1.36-3.02,2.42-4.92,3.17
          c-1.9,0.75-3.97,1.13-6.2,1.13c-2.23,0-4.29-0.39-6.18-1.15c-1.89-0.76-3.52-1.83-4.89-3.19c-1.38-1.37-2.45-2.99-3.22-4.88
          s-1.15-3.96-1.15-6.22c0-2.29,0.39-4.37,1.17-6.26c0.77-1.89,1.85-3.5,3.23-4.83c1.38-1.33,3.01-2.37,4.9-3.11
          c1.89-0.74,3.95-1.11,6.18-1.11c2.23,0,4.3,0.38,6.2,1.13c1.9,0.75,3.54,1.79,4.91,3.13c1.38,1.34,2.46,2.95,3.24,4.84
          C234.1,23.58,234.49,25.67,234.49,27.95z M230.06,27.95c0-1.6-0.26-3.11-0.78-4.53c-0.52-1.42-1.26-2.67-2.23-3.74
          c-0.96-1.08-2.13-1.93-3.49-2.55c-1.36-0.62-2.9-0.93-4.61-0.94c-1.68,0-3.2,0.31-4.57,0.92c-1.36,0.62-2.52,1.46-3.48,2.54
          c-0.95,1.07-1.69,2.32-2.22,3.74c-0.53,1.42-0.79,2.93-0.79,4.53c0,1.63,0.26,3.16,0.78,4.59c0.52,1.43,1.26,2.68,2.23,3.74
          c0.96,1.06,2.12,1.9,3.47,2.51c1.35,0.61,2.86,0.91,4.55,0.92c1.68,0,3.21-0.3,4.59-0.9c1.38-0.6,2.55-1.44,3.52-2.5
          c0.97-1.06,1.71-2.3,2.24-3.74C229.8,31.1,230.06,29.57,230.06,27.95z"
      />
      <path
        fill="#444444"
        d="M273.95,35.91l0.08,0l8.67-22.47l6.37,0.01l-0.04,29.27l-4.09-0.01l0.04-24.47h-0.08l-9.75,24.46l-2.6,0
          L262.9,18.2h-0.08l-0.04,24.47l-3.97-0.01l0.04-29.27l6.37,0.01L273.95,35.91z"
      />
      <path
        fill="#444444"
        d="M301.55,42.73l-4.55-0.01l12.73-29.25l3.72,0.01l12.52,29.29l-4.63-0.01l-2.97-7.2l-13.89-0.02
        L301.55,42.73z M305.95,31.95l10.95,0.02l-5.44-13.73L305.95,31.95z"
      />
      <path
        fill="#444444"
        d="M338.05,42.79l-4.09-0.01l0.04-29.27l9.38,0.01c1.41,0,2.74,0.15,4.01,0.44c1.27,0.29,2.38,0.75,3.33,1.39
          c0.95,0.64,1.7,1.47,2.25,2.5s0.82,2.3,0.82,3.78c0,2.15-0.67,3.88-1.99,5.21s-3.04,2.16-5.13,2.51l8.16,13.45l-4.96-0.01
          l-7.42-13.03l-4.38-0.01L338.05,42.79z M338.08,26.33l4.71,0.01c0.96,0,1.86-0.08,2.69-0.24c0.83-0.16,1.55-0.43,2.17-0.8
          c0.62-0.37,1.11-0.85,1.47-1.45c0.36-0.59,0.54-1.33,0.54-2.21c0-0.88-0.18-1.62-0.53-2.21c-0.36-0.59-0.83-1.07-1.42-1.43
          c-0.59-0.36-1.29-0.62-2.09-0.77c-0.8-0.15-1.64-0.23-2.52-0.23l-5-0.01L338.08,26.33z"
      />
      <path
        fill="#444444"
        d="M367.67,26.46h0.08l13.08-12.88l5.66,0.01l-14.24,13.37l15.06,15.9l-5.87-0.01l-13.7-15.15h-0.08
        l-0.02,15.13l-4.09-0.01l0.04-29.27l4.09,0.01L367.67,26.46z"
      />
      <path
        fill="#444444"
        d="M399.11,39.24l15.29,0.02l-0.01,3.64l-19.39-0.03l0.04-29.27l18.77,0.03l0,3.6l-14.67-0.02l-0.01,8.76
          l13.81,0.02l-0.01,3.51l-13.81-0.02L399.11,39.24z"
      />
      <path
        fill="#444444"
        d="M434.33,42.93l-4.09-0.01l0.04-25.67l-9.38-0.01l0-3.6l22.86,0.03l0,3.6l-9.38-0.01L434.33,42.93z"
      />
      <path
        fill="#444444"
        d="M451.85,13.69l8.93,0.01c1.57,0,3,0.16,4.3,0.48c1.29,0.32,2.4,0.81,3.31,1.47c0.91,0.66,1.61,1.5,2.1,2.52
        c0.5,1.02,0.74,2.25,0.74,3.68c0,1.46-0.27,2.71-0.81,3.76c-0.54,1.05-1.28,1.9-2.24,2.56c-0.95,0.66-2.08,1.15-3.39,1.46
        c-1.31,0.32-2.72,0.47-4.24,0.47l-4.63-0.01l-0.02,12.86l-4.09-0.01L451.85,13.69z M455.93,26.64l4.34,0.01
        c0.99,0,1.91-0.09,2.75-0.26c0.84-0.18,1.56-0.45,2.17-0.82c0.61-0.37,1.08-0.86,1.41-1.46c0.33-0.61,0.5-1.35,0.5-2.23
        c0-0.88-0.17-1.62-0.51-2.21c-0.34-0.59-0.81-1.08-1.4-1.45c-0.59-0.37-1.3-0.64-2.13-0.79c-0.83-0.15-1.72-0.23-2.69-0.23
        l-4.42-0.01L455.93,26.64z"
      />
      <path
        fill="#444444"
        d="M484.72,39.37l13.06,0.02l-0.01,3.64L480.62,43l0.04-29.27l4.09,0.01L484.72,39.37z"
      />
      <path
        fill="#444444"
        d="M507.16,43.04l-4.55-0.01l12.74-29.25l3.72,0.01l12.52,29.29l-4.63-0.01l-2.96-7.2l-13.89-0.02
        L507.16,43.04z M511.56,32.26l10.95,0.02l-5.44-13.73L511.56,32.26z"
      />
      <path
        fill="#444444"
        d="M564.67,38.58c-1.16,1.57-2.67,2.84-4.51,3.82c-1.85,0.98-4.07,1.46-6.66,1.46c-2.23,0-4.29-0.39-6.18-1.15
          c-1.89-0.76-3.51-1.82-4.87-3.19c-1.36-1.37-2.43-2.99-3.2-4.88c-0.77-1.89-1.15-3.96-1.15-6.22c0-2.29,0.4-4.37,1.19-6.26
          c0.79-1.89,1.88-3.5,3.27-4.83c1.39-1.33,3.04-2.37,4.94-3.11c1.9-0.74,3.97-1.11,6.2-1.11c0.99,0,2,0.1,3.02,0.29
          c1.02,0.19,2,0.49,2.93,0.87c0.94,0.39,1.79,0.86,2.56,1.43c0.77,0.57,1.43,1.22,1.98,1.97l-3.23,2.48
          c-0.71-0.99-1.73-1.81-3.03-2.46c-1.31-0.65-2.72-0.98-4.24-0.98c-1.71,0-3.25,0.31-4.61,0.92s-2.53,1.46-3.5,2.54
          s-1.71,2.32-2.24,3.74c-0.53,1.42-0.79,2.93-0.79,4.52c0,1.65,0.25,3.2,0.76,4.63c0.51,1.43,1.24,2.68,2.18,3.74
          c0.95,1.06,2.11,1.9,3.47,2.51s2.89,0.91,4.57,0.92c1.65,0,3.16-0.33,4.51-0.99c1.35-0.66,2.47-1.62,3.35-2.89L564.67,38.58z"
      />
      <path
        fill="#444444"
        d="M577.15,39.51l15.29,0.02l-0.01,3.64l-19.39-0.03l0.04-29.27l18.77,0.03l0,3.6l-14.67-0.02l-0.01,8.76
          l13.81,0.02l-0.01,3.51l-13.81-0.02L577.15,39.51z"
      />
    </g>
  </svg>

);
