import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

export const ScrollToTop = withRouter(({ children, location: { pathname } }: any) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return children || null;
});
