import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ExternalRedirect } from '../../common/external-redirect';

export const DetermineRedirectContainer: React.FC<any> = () => {
  const { user } = useContext(AppContext);

  let redirect: string = '';
  if (!user.isAuthenticated) { 
    redirect = '/account/login';
  } else {
    redirect = '/profile';
  }

  if (redirect.startsWith('http')) {
    return <ExternalRedirect to={redirect} />;
  }
  return <Redirect to={redirect} />;
};
