import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { 
  PersonalInformationData 
} from '../../areas/crew-profile/workflow/question-forms/person-information/PersonInformationComponent';

interface Props {
  person: PersonalInformationData;
}

export class PersonSummary extends Component<Props> {
  // todo for now just show the person's name, but this will likely need to get expanded to show more details
  render() {
    const personFields = this.props.person;
    return (
      <>
        <Grid
          item
          container
          direction="column"
          spacing={8}
        >
          <Typography variant="body1">
            {`${personFields.first_name} ${personFields.last_name}`}
          </Typography>
        </Grid>
      </>
    );
  }
}
