import React, { ChangeEvent, Component } from 'react';
import { Translation } from 'react-i18next';
import { 
  IconButton,
  InputAdornment,
  Grid, 
  TextField, 
  Typography 
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import { VisibilityIcon } from '../../../common/icons/visibility';
import { VisibilityOffIcon } from '../../../common/icons/visibility-off';
import styles from './create-password-input.module.scss';

interface Props {
  password: string;
  onChange(value: string): void;
  onValid(value: Boolean): void;
}

interface ValidationProps {
  valid: boolean;
  label: string;
}

interface PasswordValidation {
  length: boolean;
  specialCharacter: boolean;
  letters: boolean;
  numbers: boolean;
  uppercase: boolean;
  lowercase: boolean;
}

interface State {
  showPassword: boolean;
  validation: PasswordValidation
}

const ValidationMessage: React.FC<ValidationProps> = ({ valid, label }) => (
  <Grid 
    item 
    container 
    direction="row" 
    justify="flex-start" 
    alignItems="center"
    className={styles.validateMessage}
  >
    {valid ? (
      <DoneIcon className={styles.valid} />
    ) : (
      <ClearIcon className={styles.invalid} />
    )}
    <Typography variant="body1" inline>{label}</Typography>
  </Grid>
);

export class CreatePasswordInput extends Component<Props, State> {
  state: State = {
    showPassword: false,
    validation: {
      length: false,
      specialCharacter: false,
      letters: false,
      numbers: false,
      uppercase: false,
      lowercase: false
    }
  };

  async componentDidMount() {
    this.validate(this.props.password);
  }

  onChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const password = event.target.value;    
    this.validate(password);
    this.props.onChange(password);
  }

  validate = (password: string) => {
    const regex = {
      digits: /\d+/,
      uppercase: /[A-Z]+/,
      lowercase: /[a-z]+/,
      symbols: /[`~!@#$%^&*()\-_=+[{}\]\\|;:'",<.>/?€£¥₹]+/
    };

    const validLen = (password.length >= 8 && password.length < 99);
    const hasNumbers = new RegExp(regex.digits).test(password);
    const hasUppercase = new RegExp(regex.uppercase).test(password);
    const hasLowercase = new RegExp(regex.lowercase).test(password);
    const hasSpecialChar = new RegExp(regex.symbols).test(password);

    this.setState({ 
      validation: {
        length: validLen,
        specialCharacter: hasSpecialChar,
        letters: (hasUppercase || hasLowercase),
        numbers: hasNumbers,
        uppercase: hasUppercase,
        lowercase: hasLowercase
      }
    });

    this.props.onValid(validLen && hasNumbers && hasUppercase && hasLowercase && hasSpecialChar);
  }

  render() {
    const { showPassword, validation } = this.state;

    return (
      <Translation>
        {t => (
          <Grid
            item
            container
            direction="column"
            justify="flex-start"
          >
            <TextField
              label={t('AREAS.ACCOUNT.CREATE_PASSWORD.PASSWORD')}
              variant="filled"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              value={this.props.password}
              onChange={this.onChange}
              inputProps={{ maxLength: 100 }}
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className={styles.visibilityButton}
                      onClick={() => { this.setState({ showPassword: !showPassword }); }}
                      onMouseDown={(event) => { event.preventDefault(); }}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Grid container spacing={24} direction="column" justify="center" className={styles.messageContainer}>
              <ValidationMessage 
                valid={validation.length} 
                label={t('AREAS.ACCOUNT.CREATE_PASSWORD.VALIDATION.LENGTH')} 
              />
              <ValidationMessage 
                valid={validation.specialCharacter} 
                label={t('AREAS.ACCOUNT.CREATE_PASSWORD.VALIDATION.SPECIAL_CHAR')} 
              />
              <ValidationMessage 
                valid={validation.letters && validation.numbers} 
                label={t('AREAS.ACCOUNT.CREATE_PASSWORD.VALIDATION.LETTERS_NUMBERS')} 
              />
              <ValidationMessage 
                valid={validation.uppercase && validation.lowercase} 
                label={t('AREAS.ACCOUNT.CREATE_PASSWORD.VALIDATION.UPPER_LOWERCASE')} 
              />
            </Grid>
          </Grid>
        )}
      </Translation>
    );
  }
}
