import React, { Component } from 'react';
import { 
  Grid, 
  MenuItem, 
  Select, 
  FormHelperText 
} from '@material-ui/core';
import { QuestionHeading } from '../question-heading';
import styles from './dropdown-select.module.scss';

interface DropdownSelectOption {
  key: string;
  value: string | number;
  display: string | number;
}

interface Props {
  question: string,
  textVariant: 'body1' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  status: 'required' | 'optional',
  displayRequirednessMarker: boolean,
  placeholder?: string;
  options: DropdownSelectOption[];
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  originalValue?: string;
  errorMessage?: string,
}

interface State {
  selectValue: string | number;
  isEmpty: boolean;
}

export class DropdownSelectComponent extends Component<Props, State> {
  state: State = {
    selectValue: 'placeholder',
    isEmpty: true,
  }

  componentWillMount(): void {
    const valueToSelect: string = this.props.originalValue ? this.props.originalValue! : 'placeholder';
    this.setState({ selectValue: valueToSelect });
  }

  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selectValue: event.target.value,
      isEmpty: event.target.value === undefined,
    });

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  render() {
    let errorMessage = null;
    if (this.props.errorMessage !== undefined) {
      errorMessage = <FormHelperText error>{this.props.errorMessage!}</FormHelperText>;
    }

    const placeholder = this.props.placeholder ? (
      <MenuItem value="placeholder" disabled className={styles.placeholder}>
        {this.props.placeholder}
      </MenuItem>
    ) : null;
    const selectOptions = this.props.options.map((option: DropdownSelectOption) => (
      <MenuItem key={option.key} value={option.value} className={styles.menuItem}>{option.display}</MenuItem>
    ));
    return (
      <React.Fragment>
        <Grid
          container
          item
          direction="column"
          spacing={24}
        >
          <Grid item>
            <QuestionHeading
              question={this.props.question}
              textVariant={this.props.textVariant}
              status={this.props.status}
              displayRequirednessMarker={this.props.displayRequirednessMarker}
            />
          </Grid>
          <Grid item>
            <Select
              value={this.state.selectValue}
              onChange={this.handleChange}
              className={this.state.isEmpty ? styles.emptySelect : ''}
              MenuProps={{ classes: { paper: styles.selectPaper } }}
              displayEmpty
              fullWidth
              error={this.props.errorMessage !== undefined}
            >
              {placeholder}
              {selectOptions}
            </Select>
            {errorMessage}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
