import React from 'react';
import {
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import { SummaryPanel, QuestionResponse } from './SummaryPanel';
import { ClearButton } from '../../../common/clear-button';
import { Button } from '../../../common/button';
import { LinearProgressContainer } from '../LinearProgressContainer';
import { PanelStatuses } from '../dashboard/dashboard-container';

export interface Panel {
  key: string;
  header: string;
  linkPath: string;
  responses: QuestionResponse[];
}

interface Props {
  panels: Panel[];
  allowProgress: boolean;
  panelStatuses: PanelStatuses;
  showModal(): void;
  onExit(): void;
}

export const ReviewComponent: React.FC<Props> = ({
  panels, allowProgress, showModal, panelStatuses, onExit
}) => (
  <>
    <LinearProgressContainer panelStatuses={panelStatuses} />
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <Grid
        container
        direction="column"
        alignItems="flex-end"
        spacing={24}
      >
        <Grid item>
          <ClearButton onClick={onExit} linkText="Save and exit" data-test-id="review__button--saveandexit" />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        spacing={16}
      >
        <Grid item>
          <Typography variant="h5">Review your profile</Typography>
        </Grid>
        {panels.map((panel, i) => {
          const panelComponent = (
            <SummaryPanel
              header={panel.header}
              linkPath={panel.linkPath}
              responses={panel.responses}
              testingKey={panel.key}
            />
          );

          if (panels.length === i + 1) {
            return (
              <React.Fragment key={panel.key}>
                {panelComponent}
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={panel.key}>
              {panelComponent}
              <Grid
                item
                container
                direction="column"
                alignItems="stretch"
                justify="center"
                spacing={24}
              >
                <Grid item>
                  <Divider variant="fullWidth" light />
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <Button wide onClick={showModal} disabled={!allowProgress} data-test-id="review__button--looksgood">
        Looks good!
      </Button>
    </Grid>
  </>
);
