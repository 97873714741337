import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid, Typography, FormHelperText } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { QuestionHeading } from '../question-heading';
import { Button } from '../../../../../common/button';
import styles from './person-information.module.scss';
import { PersonCard } from '../../../../../common/person-card';
import { DialogForm } from '../../../../../common/dialog-form';
import { PersonInformationField } from '../question-component';
import { FieldErrors } from '../../../../account/common/FormErrors';

interface Props {
  question: string,
  helpText?: string,
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6',
  status: 'required'|'optional',
  displayRequirednessMarker: boolean,
  personInformationFields?: PersonInformationField[],
  maxInputs?: number,
  onSave?: (personalInformation: PersonalInformationData, action: 'update'|'delete') => void,
  existingData: PersonalInformationData[],
  errorMessage?: string;
  testingKey?: string;
}

export interface PersonalInformationData {
  id: string;
  [key: string]: string;
}

interface State {
  showAddPersonForm: boolean;
  disableAddPersonFormSubmission: boolean;
  currentPerson: PersonalInformationData;
  idOfPersonInModal: string;
  fieldErrors: FieldErrors;
}

export class PersonInformationComponent extends Component<Props, State> {
  state: State = {
    idOfPersonInModal: '',
    showAddPersonForm: false,
    disableAddPersonFormSubmission: true,
    currentPerson: {} as PersonalInformationData,
    fieldErrors: {},
  };

  initializeAddPersonForm = () => {
    this.setState({
      showAddPersonForm: true,
      idOfPersonInModal: uuid(),
      currentPerson: {} as PersonalInformationData,
      disableAddPersonFormSubmission: true
    });
  };

  handleDialogClose = () => {
    this.setState({
      showAddPersonForm: false,
      idOfPersonInModal: '',
      currentPerson: {} as PersonalInformationData,
      fieldErrors: {}
    });
  };

  handleFieldChange = (fieldKey: string, value: string) => {
    const person = { ...this.state.currentPerson };
    person[fieldKey] = value;
    this.setState({
      currentPerson: { ...person },
      disableAddPersonFormSubmission: !this.canSubmitAddPersonForm(person)
    });
  }

  handleSaveClick = () => {
    const person = { ...this.state.currentPerson };
    const validPerson = this.validatePersonFields(person);
    if (validPerson) {
      person.id = this.state.idOfPersonInModal;
      if (this.props.onSave) {
        this.props.onSave(person, 'update');
      }
      this.handleDialogClose();
    }
    return validPerson;
  }

  handlePersonEdit = (id: string) => {
    const indexToUpdate = this.props.existingData.findIndex(record => record.id === id);
    let currentPerson = {} as PersonalInformationData;
    if (indexToUpdate > -1) {
      currentPerson = this.props.existingData[indexToUpdate];
    }

    this.setState({
      showAddPersonForm: true,
      idOfPersonInModal: id,
      currentPerson: { ...currentPerson },
      disableAddPersonFormSubmission: !this.canSubmitAddPersonForm(currentPerson)
    });
  }

  handlePersonDelete = (id: string) => {
    const indexToDelete = this.props.existingData.findIndex(record => record.id === id);
    if (indexToDelete > -1 && this.props.onSave) {
      this.props.onSave(this.props.existingData[indexToDelete], 'delete');
    }

    this.setState({ showAddPersonForm: false, idOfPersonInModal: '', currentPerson: {} as PersonalInformationData });
  }

  handleSaveAndAddAnotherClick = () => {
    const validPersonAdded = this.handleSaveClick();
    if (validPersonAdded) {
      this.initializeAddPersonForm();
    }
  }

  validatePersonFields = (person: PersonalInformationData) => {
    let personFormIsValid = false;
    if (person.email && !/^[^\s@]+@[^\s@]+\.[^\s@]{2,63}$/.test(person.email)) {
      this.setState(prevState => ({
        fieldErrors: {
          ...prevState.fieldErrors,
          email: 'Enter a valid email address'
        }
      }));
    } else {
      personFormIsValid = true;
      this.setState({ fieldErrors: {} });
    }
    return personFormIsValid;
  }

  showAddPersonButton = (maxNumber: number|undefined) => {
    const { existingData, maxInputs } = this.props;
    const { currentPerson } = this.state;
    if (existingData && maxNumber) {
      // if editing a person
      if (existingData.some(e => e.id === currentPerson.id)) {
        if (maxInputs) {
          return existingData.length < maxInputs;
        }
        return ![undefined, 0].includes(maxInputs);
      }
      return existingData.length < maxNumber;
    }
    // maxNumber should always be defined and > 0 for PersonInformation questions but just in case
    return ![undefined, 0].includes(maxNumber);
  }

  canSubmitAddPersonForm = (person: PersonalInformationData) => !!(person.first_name
    && person.last_name
    && (person.phone || person.email));

  render() {
    const { maxInputs } = this.props;
    const canAddPerson = this.showAddPersonButton(maxInputs);
    const showAddAdditionalPersonButton = maxInputs !== undefined
      && maxInputs > 1 ? this.showAddPersonButton(maxInputs - 1) : false;
    let errorMessage = null;
    if (this.props.errorMessage !== undefined) {
      errorMessage = <FormHelperText error>{this.props.errorMessage!}</FormHelperText>;
    }

    let personCards = null;
    if (this.props.existingData) {
      personCards = (
        <Grid item xs={12}>
          {this.props.existingData.map((personalInformationData, idx) => (
            <PersonCard
              onEdit={() => this.handlePersonEdit(personalInformationData.id)}
              onDelete={() => this.handlePersonDelete(personalInformationData.id)}
              key={personalInformationData.id}
              data={personalInformationData}
              display={`${personalInformationData.first_name} ${personalInformationData.last_name}`}
              testingKey={`${this.props.testingKey ? `--${this.props.testingKey}` : ''}--${idx.toString()}`}
            />
          ))}
        </Grid>
      );
    }

    return (
      <React.Fragment>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <QuestionHeading
              question={this.props.question}
              textVariant={this.props.textVariant}
              status={this.props.status}
              displayRequirednessMarker={this.props.displayRequirednessMarker}
            />
            <Typography variant="body1" color="textSecondary">
              {this.props.helpText}
            </Typography>
          </Grid>
          <Grid
            item
            container
            spacing={8}
            xs={12}
          >
            {personCards}
          </Grid>
          <Grid item>
            {canAddPerson && (
              <Button
                size="small"
                variant="outlined"
                className={styles.addPersonButton}
                onClick={this.initializeAddPersonForm}
                data-test-id={
                  `personinformation__button--addperson${this.props.testingKey ? `--${this.props.testingKey}` : ''}`
                }
              >
                <PersonAddIcon />
                &nbsp;Add person
              </Button>
            )}
          </Grid>
        </Grid>
        {errorMessage}
        <DialogForm
          showDialog={this.state.showAddPersonForm}
          formTitle="Add person"
          formHelpText="First name, last name and either email address or mobile number are required."
          fields={this.props.personInformationFields!}
          handleDialogClose={this.handleDialogClose}
          handleFieldChanges={this.handleFieldChange}
          currentPerson={this.state.currentPerson}
          fieldErrors={this.state.fieldErrors}
          testingKey={this.props.testingKey}
        >
          <Grid
            item
            container
            direction="row"
            justify="center"
            spacing={8}
            className={styles.actionSection}
          >
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                onClick={this.handleSaveClick}
                disabled={this.state.disableAddPersonFormSubmission}
                data-test-id="personinfo__button--save"
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              {showAddAdditionalPersonButton && (
                <Button
                  size="small"
                  className={styles.addAnotherButton}
                  onClick={this.handleSaveAndAddAnotherClick}
                  disabled={this.state.disableAddPersonFormSubmission}
                  data-test-id="personinfo__button--saveandadd"
                >
                  Save and add another person
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogForm>
      </React.Fragment>
    );
  }
}
