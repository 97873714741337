import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { RequirednessMarker } from '../requiredness-marker';

interface Props {
  question: string,
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6',
  status: 'required'|'optional',
  displayRequirednessMarker: boolean,
}

export class QuestionHeading extends Component<Props> {
  render() {
    const requirednessMarker = this.props.displayRequirednessMarker
      ? <RequirednessMarker textVariant={this.props.textVariant} renderAs={this.props.status} />
      : null;
    return (
      <Typography variant={this.props.textVariant}>
        {this.props.question}
        {requirednessMarker}
      </Typography>
    );
  }
}
