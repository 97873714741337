import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { ReviewComponent } from './ReviewComponent';
import { ProfileConfirmationComponent } from './ProfileConfirmationComponent';
import { QuestionResponse } from './SummaryPanel';
import config from '../../../config';
import { createSubmission, FileSubmission } from './submission';
import { PersonalInformationData } from '../workflow/question-forms/person-information';
import { Option } from '../workflow/question-forms/autocomplete/single-select-autocomplete';
import { DisplayValuePair } from '../../../common/types/input-options';
import {
  Panel, ProfileResponse, QuestionDetails, PanelStatuses
} from '../dashboard/dashboard-container';


export interface PanelSummary {
  key: string;
  header: string;
  linkPath: string;
  responses: QuestionResponse[];
}

interface Props {
  panels: Panel[];
  responses: ProfileResponse;
  allStepsComplete: boolean;
  panelStatuses: PanelStatuses;
  currencySymbol: string;
  onExit(): void;
  onComplete(referralLink: string): void;
}

interface State {
  isModalOpen: boolean;
  submitted: boolean;
}

export class ReviewContainer extends Component<Props, State> {
  state: State = {
    isModalOpen: false,
    submitted: false
  };

  private getQuestionByKey = (key: string, panel: Panel) => (
    panel.questions!.find(qd => qd.key === key)
  );

  private getCurrentQuestionValueForSummary(
    key: string, inputType: string
  ): (string | string[] | PersonalInformationData[] | Option | FileSubmission) {
    if (key in this.props.responses) {
      if (inputType === 'multipleSelectCheckbox') {
        const displayNames = [] as string[];
        const optionsDict = {} as {[key:string]: string};
        let questionDets = {} as QuestionDetails;

        for (let i = 0; i < this.props.panels.length; i += 1) {
          const panel = this.props.panels[i];
          const details = this.getQuestionByKey(key, panel);
          if (details) {
            questionDets = details;
            break;
          }
        }

        if (key === 'requested_positions') {
          const questionOptions = questionDets.options! as { [category: string]: DisplayValuePair[] };
          Object.keys(questionOptions).forEach((category) => {
            questionOptions[category].forEach((qo) => {
              const desiredRateKey = `requested_positions___desired_rate___${qo.value}`;
              optionsDict[qo.value] = Object.prototype.hasOwnProperty.call(this.props.responses, desiredRateKey)
                ? `${qo.display} (${this.props.currencySymbol}${this.props.responses[desiredRateKey]}/hr)`
                : qo.display;
            });
          });
        } else {
          const questionOptions = questionDets.options! as DisplayValuePair[];
          questionOptions.forEach((qo) => { optionsDict[qo.value] = qo.display; });
        }

        if (Object.keys(optionsDict).length !== 0) {
          const questionResponses = this.props.responses[key] as string[];
          for (let n = 0; n < questionResponses.length; n += 1) {
            const response = questionResponses[n] as string;
            displayNames.push(optionsDict[response]);
          }

          displayNames.sort();
          return displayNames;
        }
      }

      if (inputType === 'singleSelectAutocomplete' || inputType === 'airportAutocomplete') {
        return (this.props.responses[key] as Option).label;
      }

      if (inputType === 'fileUpload') {
        if ((this.props.responses[key] as FileSubmission) !== undefined) {
          const fileName = (this.props.responses[key] as FileSubmission).name;
          return fileName.length > 18 ? `${fileName.substring(0, 18)} ...` : fileName;
        }

        return '';
      }

      return this.props.responses[key];
    }

    if (inputType === 'multipleSelectCheckbox') {
      return [] as string[];
    }

    if (inputType === 'personInformation') {
      return [] as PersonalInformationData[];
    }

    return '';
  }

  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  hideModal = () => {
    this.setState({ isModalOpen: false });
  };

  private submit = async () => {
    try {
      const response = await axios(`${config.apiRoot}/internal_api/complete_registration/`, {
        method: 'POST',
        withCredentials: true,
        data: createSubmission(this.props.panels, this.props.responses)
      });

      this.props.onComplete(response.data.referral_link);

      this.setState({
        submitted: true
      });
    } catch (e) {
      // todo flesh out error handling
      throw e;
    }
    try {
      axios(`${config.apiRoot}/internal_api/autosave/marketplace_registration/`, {
        method: 'POST',
        data: { complete_response_submitted: this.state.submitted },
        withCredentials: true,
      });
    } catch (e) {
      console.warn(e);
    }
  }

  private generateSummaryPanels(): PanelSummary[] {
    return this.props.panels.map((panel) => {
      const result = {} as PanelSummary;

      result.header = panel.header;
      result.key = panel.key;
      result.linkPath = `/profile/workflow/${panel.workflowStepId}`;

      result.responses = [] as QuestionResponse[];
      if (panel.questions) {
        result.responses = panel.questions.map((question) => {
          const response = {} as QuestionResponse;
          response.key = question.key;
          response.question = question.question;
          response.type = question.input;
          response.answer = this.getCurrentQuestionValueForSummary(question.key, question.input);

          return response;
        }) as QuestionResponse[];
      }
      return result;
    });
  }

  render() {
    if (this.state.submitted) {
      return <Redirect to="/profile/profilecreationsuccess" />;
    }

    return (
      <div>
        <ReviewComponent
          panels={this.generateSummaryPanels()}
          showModal={this.showModal}
          allowProgress={this.props.allStepsComplete}
          panelStatuses={this.props.panelStatuses}
          onExit={this.props.onExit}
        />

        <ProfileConfirmationComponent
          show={this.state.isModalOpen}
          onClose={this.hideModal}
          onConfirm={this.submit}
        />
      </div>
    );
  }
}
