import React from 'react';
import i18next from 'i18next';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { PasswordComponent } from './PasswordComponent';
import { LoginStep } from './LoginContainer';
import { CognitoSignInResponse } from './cognito';
import { migrateUser } from './user-migration';

interface Props {
  user: any;
  username: string;
  migrateUser: boolean;
  onComplete(user: CognitoUser, password: string, nextStep: LoginStep, migrateUser: boolean): void;
}

interface State {
  error?: string;
}


export class PasswordContainer extends React.PureComponent<Props, State> {
  state: State = {}

  private submitPassword = async (password: string) => {
    if (this.props.migrateUser) {
      const results = await migrateUser(this.props.username, password, 'username-password');
      if (results.error) {
        this.setState({ error: i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.MIGRATE_USER') });
      } else if (results.type === 'forgotpassword') {
        this.props.onComplete(this.props.user, '', LoginStep.Forgot, false); 
      } else {
        await this.signIn(this.props.username, password);
      }
    } else {
      await this.signIn(this.props.username, password);
    }
  }

  private signIn = async (username: string, password: string) => {
    try {
      const user: CognitoSignInResponse = await Auth.signIn(this.props.username, password);
      
      if (user.challengeName === 'SMS_MFA') {
        // Need to ask for MFA code.
        this.props.onComplete(user, password, LoginStep.Code, false);
      } else {
        this.props.onComplete(user, password, LoginStep.Complete, false);
      }  
    } catch (e) {
      this.setState({
        error: i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.INVALID_USERNAME_PASSWORD')
      });
    }
  }

  render() {
    return (
      <PasswordComponent
        onForgotPassword={() => { 
          this.props.onComplete(this.props.user, '', LoginStep.Forgot, this.props.migrateUser); 
        }}
        onSubmit={this.submitPassword}
        error={this.state.error}
      />
    );
  }
}
