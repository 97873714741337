import React from 'react';
import axios, { AxiosError } from 'axios';
import { PolicyAgreeModalComponent }
  from '../account/django-auth/service-terms/PolicyAgreeModalComponent';
import config from '../../config';


interface State {
  show: boolean;
  policiesNeeded: Array<any>;
}

export class PolicyAcceptanceRequiredError extends React.Component<any, State> {
  state: State = {
    show: false,
    policiesNeeded: []
  };

  private responseInterceptor: number | undefined;

  componentDidMount() {
    this.responseInterceptor = axios.interceptors.response.use(undefined, this.interceptPolicyError);
  }

  componentWillUnmount() {
    if (this.responseInterceptor !== undefined) {
      axios.interceptors.response.eject(this.responseInterceptor);
    }
  }

  private interceptPolicyError = (error: AxiosError) => {
    if (error.response
      && error.response.status === 403
      && error.response.data.error === 'unaccepted_policies'
      && error.response.data.unaccepted_policies) {
      this.setState({
        show: true,
        policiesNeeded: error.response.data.unaccepted_policies
      });
    }

    return Promise.reject(error);
  };

  private handleAcceptPolicies = async () => {
    try {
      await axios(`${config.apiRoot}/internal_api/policies/accept/`, {
        method: 'POST',
        withCredentials: true,
        data: {
          policies: this.state.policiesNeeded.map(p => p.id)
        }
      });
      window.location.reload();
    } catch (e) {
      console.warn(e);
    }
  }

  render() {
    return (
      <PolicyAgreeModalComponent 
        show={this.state.show} 
        policiesNeeded={this.state.policiesNeeded} 
        onAcceptPolicies={this.handleAcceptPolicies}
      />
    );
  }
}
