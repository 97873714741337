import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { CodeVerificationComponent } from './CodeVerificationComponent';
import config from '../../../config';
import { FormErrors } from '../common/FormErrors';
import { SignupErrorProps } from '../common/SignupErrorComponent';
import { AppContext } from '../../core/AppContext';

const INVALID_OTP = 'The login code you entered is incorrect. Please try again.';

interface State {
  code: string;
  submitting: boolean;
  error?: FormErrors;
  showSnackbar: boolean;
}

interface Props {
  onComplete(): void;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  edit: () => void;
}

export class SignupCodeVerificationContainer extends React.Component<Props, State> {
  static contextType = AppContext; // use below with this.context

  state: State = {
    code: '',
    submitting: false,
    showSnackbar: false
  }

  private handleSubmit = async () => {
    this.setState({ submitting: true });

    try {
      await this.signup();
    } catch (e) {
      this.setState({ submitting: false });
      return;
    }

    this.props.onComplete();
  }

  private signup = async () => {
    try {
      const response: AxiosResponse = await axios(`${config.apiRoot}/internal_api/auth/register/`, {
        method: 'POST',
        withCredentials: true,
        data: {
          first_name: this.props.firstName,
          last_name: this.props.lastName,
          phone: this.props.phone,
          email: this.props.email,
          otp: this.state.code
        }
      });
      this.context.setAppContext({ user: response.data.user });
    } catch (e) {
      console.warn(e);
      if (e.response.data.error === 'invalid_otp') {
        this.setState({
          error: {
            fieldErrors: { code: INVALID_OTP }
          }
        });
      } else if (e.response.data.error === 'ambiguous_user') {
        this.setState({
          error: {
            generic: {
              title: 'Whoops! There was a problem with your information.',
              text: `If you already have a LASSO profile, click the Login link below. 
              For help, contact customer support at support@lasso.io.`
            } as SignupErrorProps
          }
        });
      } else {
        this.setState({
          error: {
            generic: {
              text: 'Whoops.',
              title: 'There was a problem with your request. Please try again.'
            }
          }
        });
      }

      throw e;
    }
  }

  private resendCode = async () => {
    try {
      await axios(`${config.apiRoot}/internal_api/auth/register_init/`, {
        method: 'POST',
        withCredentials: true,
        data: {
          email: this.props.email,
          phone: this.props.phone
        }
      }).then(() => true);
      this.setState({ showSnackbar: true });
    } catch (e) {
      console.warn(e);
    }
  }

  handleClose = () => { this.setState({ showSnackbar: false }); }

  private canSubmit() {
    return !this.state.submitting && this.state.code !== '';
  }

  render() {
    return (
      <CodeVerificationComponent
        onChange={code => this.setState({ code })}
        onSubmit={this.handleSubmit}
        onResendCode={this.resendCode}
        codeDestination={this.props.phone}
        error={this.state.error}
        code={this.state.code}
        canSubmit={this.canSubmit()}
        editPath={this.props.edit}
        showSnackbar={this.state.showSnackbar}
        handleClose={this.handleClose}
      />
    );
  }
}
