import React from 'react';
import {
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { components } from 'react-select';
import { ArrowDropDown } from '@material-ui/icons';
import colors from '../../../../../common/colors.module.scss';
import styles from './react-select-custom-components.module.scss';

function inputComponent({ inputRef, ...props }: any) {
  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      justify="space-between"
      style={{ padding: 0 }}
      {...props}
    />
  );
}

function Control(props: any) {
  const { dataTestId, ...rest } = props.selectProps.textFieldProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      data-test-id={dataTestId}
      {...rest}
    />
  );
}

function IndicatorSeparator() {
  return null;
}

function DropdownIndicator(props: any) {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator
        className={styles.dropdownIndicator}
        {...props}
      >
        <ArrowDropDown className={styles.arrowDropDown} />
      </components.DropdownIndicator>
    )
  );
}

function ClearIndicator() {
  return null;
}

function Option(props: any) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isSelected}
      component="div"
      style={{
        backgroundColor: props.isFocused ? '#eeeeee' : '',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

export const ReactSelectCustomComponents = {
  Control,
  ClearIndicator,
  IndicatorSeparator,
  DropdownIndicator,
  Option,
};

export const customStyles = {
  menu: (base: any) => {
    const overrideProps = {
      borderRadius: 0,
    };
    return Object.assign(base, overrideProps);
  },
  valueContainer: (base: any) => {
    const overrideProps = {
      paddingLeft: 0,
    };
    return Object.assign(base, overrideProps);
  },
  placeholder: (base: any) => {
    const overrideProps = {
      color: colors.gray2,
    };
    return Object.assign(base, overrideProps);
  },
};
