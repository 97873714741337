import React, { Component } from 'react';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import {
  RouteComponentProps, Route, Switch, Redirect
} from 'react-router-dom';
import { MaterialUiPickersDate } from 'material-ui-pickers/typings/date';
import { DashboardComponent } from './DashboardComponent';
import { ProfileWorkflowContainer } from '../workflow/ProfileWorkflowContainer';
import { DesktopAndMobileBannerLayout } from '../../../common/layouts/desktop-and-mobile-banner-layout';
import {
  QuestionObject,
  DropdownSelectQuestionObject
} from '../workflow/question-forms/question-component';
import { ReviewContainer } from '../review/ReviewContainer';
import { PersonalInformationData }
  from '../workflow/question-forms/person-information/PersonInformationComponent';
import { NotFoundPage } from '../../../common/not-found-page';
import config from '../../../config';
import { LoadingComponent } from '../../../common/loading-component';
import { FileSubmission } from '../review/submission';
import { Option } from '../workflow/question-forms/autocomplete/single-select-autocomplete';
import { ScrollToTop } from '../../../common/scroll-to-top/ScrollToTopComponent';
import {
  State, ProfileResponse, ValidationStatus, Panel, QuestionDetails,
  QuestionSetValidationResult, PanelStatuses, PanelStatus
} from './dashboard-container';
import { calculatePanelStatus } from './panel-status';
import { DisplayValuePair } from '../../../common/types/input-options';
import { ProfileCreationSuccessComponent } from '../success/ProfileCreationSuccessComponent';
import { ProfileExistingComponent } from '../existing-profile/ProfileExistingComponent';
import { 
  AppContext, User 
} from '../../core/AppContext';

export class DashboardContainer extends Component<RouteComponentProps, State> {
  static contextType = AppContext;

  private static fieldPassesRequirednessCheck(
    currentValue: (string | string[] | PersonalInformationData[] | Option | FileSubmission),
    status: ('optional' | 'required')
  ): boolean {
    let hasValue = false;
    if (currentValue !== undefined) {
      if (currentValue instanceof Array) {
        hasValue = currentValue.length > 0;
      } else {
        hasValue = !!currentValue;
      }
    }
    return status !== 'required' || hasValue;
  }

  private static validateQuestion(
    currentValue: string | string[] | PersonalInformationData[] | Option | FileSubmission,
    status: 'optional' | 'required'
  ) {
    if (!DashboardContainer.fieldPassesRequirednessCheck(currentValue, status)) {
      return 'This is a required question';
    }

    return null;
  }

  state: State = {
    panels: [],
    loaded: false,
    responses: {} as ProfileResponse,
    panelStatuses: {} as PanelStatuses,
    validationStatus: {} as ValidationStatus,
    requirednessMarker: 'required',
    currencySymbol: '$',
    referralLink: ''
  }

  async componentDidMount() {
    const [profileWFResponse, autoSaveDataResponses] = await Promise.all([
      this.loadProfileWorkflow(),
      this.getAutoSaveData()
    ]);

    let responses = autoSaveDataResponses;

    // If no autosave data, use default responses from each panel if they exist
    if (Object.keys(autoSaveDataResponses).length === 0 && autoSaveDataResponses.constructor === Object) {
      profileWFResponse.panels.forEach((panel: Panel) => {
        responses = { ...responses, ...panel.defaultReponses };
      });
    }
    this.setState({
      panels: profileWFResponse.panels,
      loaded: true,
      responses,
      requirednessMarker: profileWFResponse.requirednessMarker,
      currencySymbol: profileWFResponse.currencySymbol,
    }, this.recalculateWorkflowState);
  }

  private getPanel(route: string): Panel {
    return this.state.panels.find(panel => panel.workflowStepId === route) as Panel;
  }

  private getNextRoute(currentPanel: Panel): string {
    const index = this.state.panels.findIndex(panel => panel.workflowStepId === currentPanel.workflowStepId);
    const nextPanel = this.state.panels[index + 1];
    const nextPanelComplete = () => this.state.panelStatuses[nextPanel.workflowStepId] === PanelStatus.Complete;

    if (allStepsComplete(this.state.panelStatuses)) {
      return '/profile/review';
    }

    if (!nextPanel || nextPanelComplete()) {
      return '/profile/dashboard';
    }

    return `/profile/workflow/${nextPanel.workflowStepId}`;
  }

  private getOnChangeFunctionForQuestion(question: QuestionDetails):
  ((event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void)
  | ((event: React.ChangeEvent<{}>, value: string) => void)
  | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
  | ((date: MaterialUiPickersDate) => void)
  | ((personalInformation: PersonalInformationData, action: 'update'|'delete') => void) {
    if (question.input === 'multipleChoiceRadio') {
      return (event: React.ChangeEvent<{}>, value: string) => { this.handleChangeEvent(question, value); };
    }

    if (question.input === 'multipleSelectCheckbox') {
      return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.handleChangeEventForMultiSelectInputs(question, event.target.value, checked);
      };
    }

    if (question.input === 'date') {
      return (date: MaterialUiPickersDate) => { this.handleChangeEvent(question, moment(date).format('YYYY-MM-DD')); };
    }

    if (question.input === 'personInformation') {
      return (personalInformation: PersonalInformationData, action: 'update'|'delete') => {
        this.handlePersonalInformationDataChange(question, personalInformation, action);
      };
    }

    if (question.input === 'currency' || question.input === 'fileUpload') {
      return (value: string) => { this.handleChangeEvent(question, value); };
    }

    if (question.type === 'autocomplete') {
      return (event: Option) => {
        this.handleSingleSelectAutocompleteChangeEvent(question, event);
      };
    }
    return (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      this.handleChangeEvent(question, event.target.value);
    };
  }

  private getOnChangeFunctionForFollowupQuestion(question: QuestionDetails, option: string) {
    if (question.followupQuestion!.input === 'currency') {
      return (value: string) => { this.handleFollowupQuestionChangeEvent(question, option, value); };
    }

    return (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      this.handleFollowupQuestionChangeEvent(question, option, event.target.value);
    };
  }

  private getCurrentQuestionValueForBaseQuestion(question: QuestionDetails):
    (string | string[] | PersonalInformationData[] | FileSubmission | Option) {
    return getCurrentQuestionValue(question.key, question.input, this.state.responses);
  }

  private loadProfileWorkflow = async () => {
    try {
      const response: AxiosResponse = await axios(`${config.apiRoot}/internal_api/profile_workflow/`, {
        method: 'GET',
        withCredentials: true,
      });
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  private autoSave = () => {
    try {
      axios(`${config.apiRoot}/internal_api/autosave/marketplace_registration/`, {
        method: 'POST',
        data: this.state.responses,
        withCredentials: true,
      });
    } catch (e) {
      console.warn(e);
    }
  };

  private getAutoSaveData = async () => {
    try {
      const response: AxiosResponse = await axios(`${config.apiRoot}/internal_api/autosave/marketplace_registration/`, {
        method: 'GET',
        withCredentials: true,
      });
      return response.data.responses;
    } catch (e) {
      throw e;
    }
  };

  private validateWorkflowStep = (workflowId: string) => {
    const indexOfWorkflow = this.state.panels.findIndex(element => element.workflowStepId === workflowId);
    let valid = true;
    if (indexOfWorkflow > -1) {
      const { questions } = { ...this.state.panels[indexOfWorkflow] };
      const validationStatus = { ...this.state.validationStatus };
      if (questions) {
        const result = this.validateQuestions(questions);
        const errorKeys = Object.keys(result.questionsWithError);
        result.validQuestionsKeys.forEach(key => delete validationStatus[key]);
        errorKeys.forEach((key) => { validationStatus[key] = result.questionsWithError[key]; });
        this.setState({ validationStatus: { ...validationStatus } });
        valid = errorKeys.length === 0;
      }
    }

    return valid;
  }

  private validateQuestions = (questions: QuestionDetails[]): QuestionSetValidationResult => {
    const result = {
      validQuestionsKeys: [] as string[],
      questionsWithError: {} as { [key: string]: string }
    };

    questions.forEach((question) => {
      const currentValueOfBaseQuestion = this.getCurrentQuestionValueForBaseQuestion(question);
      if (question.input !== 'fileUpload') { // temporarily bypassing this validation as the widget is not yet complete
        let validationMessage = DashboardContainer.validateQuestion(currentValueOfBaseQuestion, question.status);
        if (validationMessage !== null) {
          result.questionsWithError[question.key] = validationMessage;
        } else {
          result.validQuestionsKeys.push(question.key);
        }

        if (question.followupQuestion !== undefined) {
          (currentValueOfBaseQuestion as string[]).forEach((baseResponse) => {
            const followupQuestionKey = `${question.key}___${question.followupQuestion!.key}___${baseResponse}`;
            if (question.followupQuestion!.on.includes(baseResponse)) {
              const currentValue = getCurrentQuestionValueForFollowupQuestion(
                question, baseResponse, this.state.responses
              );
              validationMessage = DashboardContainer.validateQuestion(currentValue, 'required');

              if (validationMessage !== null) {
                result.questionsWithError[followupQuestionKey] = validationMessage;
              } else {
                result.validQuestionsKeys.push(followupQuestionKey);
              }
            } else {
              result.validQuestionsKeys.push(followupQuestionKey);
            }
          });
        }
      }
    });

    return result;
  }

  private afterRegistrationSubmitted = (referralLink: string) => {
    this.setState({
      referralLink
    });
  }

  private recalculateWorkflowState() {
    this.setState(prevState => ({
      panelStatuses: calculatePanelStatus([...prevState.panels], prevState.responses)
    }));
  }

  private handlePersonalInformationDataChange(
    question: QuestionDetails,
    personalInformation: PersonalInformationData,
    action: 'update'|'delete',
  ) {
    const responses = { ...this.state.responses };
    const currentPersonRecords = question.key in this.state.responses
      ? [...responses[question.key] as PersonalInformationData[]]
      : [] as PersonalInformationData[];
    const indexToUpdate = currentPersonRecords.findIndex(record => record.id === personalInformation.id);
    if (action === 'update') {
      if (indexToUpdate > -1) {
        currentPersonRecords[indexToUpdate] = personalInformation;
      } else {
        currentPersonRecords.push(personalInformation);
      }
    } else if (indexToUpdate > -1) {
      currentPersonRecords.splice(indexToUpdate, 1);
    }

    responses[question.key] = currentPersonRecords;
    this.updateStateValues(responses);
  }

  private handleChangeEvent(question: QuestionDetails, value: string): void {
    const responses = { ...this.state.responses };
    if (value === undefined) {
      delete responses[question.key];
    } else {
      responses[question.key] = value;
    }

    this.updateStateValues(responses);
  }

  private handleSingleSelectAutocompleteChangeEvent(question: QuestionDetails, selected: Option): void {
    const responses = { ...this.state.responses };
    responses[question.key] = selected;
    this.updateStateValues(responses);
  }

  private handleFollowupQuestionChangeEvent(question: QuestionDetails, option: string, value: string): void {
    const responses = { ...this.state.responses };
    responses[`${question.key}___${question.followupQuestion!.key}___${option}`] = value;
    this.updateStateValues(responses);
  }

  private handleChangeEventForMultiSelectInputs(question: QuestionDetails, value: string, checked: boolean): void {
    const responses = { ...this.state.responses };
    const currentValues = question.key in this.state.responses
      ? [...responses[question.key] as string[]]
      : [] as string[];

    if (value === 'selectAllCheckbox') {
      currentValues.length = 0;
      if (checked) {
        (question.options as DisplayValuePair[]).forEach((a) => {
          currentValues.push(a.value);
        });
      }
    } else if (!checked) {
      while (currentValues.indexOf(value, 0) > -1) {
        currentValues.splice(currentValues.indexOf(value, 0), 1);
      }
    } else if (currentValues.indexOf(value, 0) === -1) {
      currentValues.push(value);
    }

    responses[question.key] = currentValues;
    this.updateStateValues(responses);
  }

  private updateStateValues(responses: ProfileResponse) {
    this.setState({
      responses: { ...responses }
    }, this.recalculateWorkflowState);
  }

  private createQuestionsPropsForWorkflowContainer(panel: Panel): (QuestionObject | DropdownSelectQuestionObject)[] {
    if (panel == null || panel.questions == null) {
      return [] as QuestionObject[];
    }

    return panel.questions.map((question) => {
      const questionToUpdate = { ...question };
      const followupQuestions = {} as { [key: string]: QuestionObject };
      if (questionToUpdate.followupQuestion) {
        // create only if part of 'on' collection
        questionToUpdate.followupQuestion.on.forEach((option) => {
          const followupKey = `${question.key}___${question.followupQuestion!.key}___${option}`;

          followupQuestions[option] = {
            ...questionToUpdate.followupQuestion,
            onChangeFunction: this.getOnChangeFunctionForFollowupQuestion(question, option),
            value: getCurrentQuestionValueForFollowupQuestion(question, option, this.state.responses),
            error: followupKey in this.state.validationStatus ? this.state.validationStatus[followupKey] : undefined
          } as QuestionObject;
        });
      }

      return {
        ...questionToUpdate,
        followupQuestions: { ...followupQuestions },
        onChangeFunction: this.getOnChangeFunctionForQuestion(question),
        value: this.getCurrentQuestionValueForBaseQuestion(question),
        error: question.key in this.state.validationStatus ? this.state.validationStatus[question.key] : undefined
      } as QuestionObject | DropdownSelectQuestionObject;
    });
  }

  render() {
    if (this.state.loaded) {
      const allPanelsComplete = allStepsComplete(this.state.panelStatuses);
      const { user }: { user: User } = this.context;

      return (
        <DesktopAndMobileBannerLayout>
          <ScrollToTop>
            <Switch>
              <Redirect exact from="/profile" to="/profile/dashboard" />
              <Route
                path="/profile/dashboard"
                render={() => {
                  if (user && user.hasMarketplaceCrewProfile) {
                    return <ProfileExistingComponent />;
                  }

                  return (
                    <DashboardComponent
                      panels={this.state.panels}
                      isWorkflowComplete={allPanelsComplete}
                      panelStatuses={this.state.panelStatuses}
                    />
                  );
                }}
              />
              <Route
                path="/profile/review"
                render={() => {
                  if (!allPanelsComplete) { return <Redirect to="/profile/dashboard" />; }
                  return (
                    <ReviewContainer
                      panels={this.state.panels}
                      responses={this.state.responses}
                      allStepsComplete={allPanelsComplete}
                      currencySymbol={this.state.currencySymbol}
                      panelStatuses={this.state.panelStatuses}
                      onExit={() => this.props.history.push('/profile/dashboard')}
                      onComplete={this.afterRegistrationSubmitted}
                    />
                  );
                }}
              />
              <Route
                path="/profile/workflow/:workflowStepId"
                render={(props) => {
                  const { workflowStepId } = props.match.params;
                  const panel = this.getPanel(workflowStepId);
                  if (panel && panel.questions) {
                    return (
                      <ProfileWorkflowContainer
                        header={panel.questionPageHeader}
                        helpText={panel.helpText}
                        questions={this.createQuestionsPropsForWorkflowContainer(this.getPanel(workflowStepId))}
                        onSubmit={() => {
                          if (this.validateWorkflowStep(panel.workflowStepId)) {
                            this.autoSave();
                            this.props.history.push(this.getNextRoute(panel));
                          }
                        }}
                        onExit={() => {
                          this.autoSave();
                          this.props.history.push('/profile/dashboard');
                        }}
                        requirednessMarker={this.state.requirednessMarker}
                        allowProgress={this.state.panelStatuses[panel.workflowStepId] === PanelStatus.Complete}
                        panelStatuses={this.state.panelStatuses}
                        workflowStepId={workflowStepId}
                      />
                    );
                  }
                  return <NotFoundPage {...props} />;
                }}
              />

              <Route
                exact
                path="/profile/profilecreationsuccess"
                render={() => <ProfileCreationSuccessComponent referralLink={this.state.referralLink} />} 
              />
            </Switch>
          </ScrollToTop>
        </DesktopAndMobileBannerLayout>
      );
    }

    return <LoadingComponent />;
  }
}

export function getCurrentQuestionValueForFollowupQuestion(
  question: QuestionDetails,
  option: string,
  responses: ProfileResponse
): (string | string[] | PersonalInformationData[] | FileSubmission | Option) {
  return getCurrentQuestionValue(
    `${question.key}___${question.followupQuestion!.key}___${option}`,
    question.followupQuestion!.input,
    responses
  );
}

function getCurrentQuestionValue(
  key: string,
  inputType: string,
  responses: ProfileResponse
): (string | string[] | PersonalInformationData[] | FileSubmission | Option) {
  if (key in responses) {
    return responses[key];
  }

  if (inputType === 'multipleSelectCheckbox') {
    return [] as string[];
  }

  if (inputType === 'personInformation') {
    return [] as PersonalInformationData[];
  }

  return '';
}

function allStepsComplete(statuses: PanelStatuses) {
  return Object.values(statuses).every(status => status === PanelStatus.Complete);
}
