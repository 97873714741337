import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Checkbox, 
  FormControlLabel, 
  Grid, 
  TextField, 
  Typography 
} from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import EmailIcon from '@material-ui/icons/MailOutline';
import PersonIcon from '@material-ui/icons/PersonOutline';
import { NormalLayout } from '../../../common/layouts/NormalLayout';
import { RegistrationHeader } from '../common/RegistrationHeader';
import { PhoneNumberInput } from '../../../common/phone-number-input';
import { SignupErrorComponent } from '../common/SignupErrorComponent';
import { Button } from '../../../common/button';
import { LogInLink } from '../../../common/login-link';
import { CreatePasswordInput } from '../common/CreatePasswordInput';
import { SignupForm } from './SignupInitContainer';
import styles from './signup-init.module.scss';
import { PolicyAgreementComponent, PolicyObject } from './service-terms/PolicyAgreementComponent';
import { FieldErrors } from '../common/FormErrors';

interface Props {
  onChange(change: { [fieldName: string]: any }): void;
  onSubmit(): Promise<void>;
  values: SignupForm;
  policiesNeeded: PolicyObject[];
  canContinue: boolean;
  canSubmit: boolean;
  error?: string;
  fieldErrors: FieldErrors;
}

interface LocalState {
  showPageOne: boolean;
  showPassword: boolean;
  validatedPassword: boolean;
}

export const SignupInitComponent: React.FC<Props> = ({ 
  onChange, onSubmit, values, error, fieldErrors,
  policiesNeeded, canContinue, canSubmit 
}) => {
  const [state, setState] = useState<LocalState>({ showPageOne: true, showPassword: false, validatedPassword: false });
  const { t } = useTranslation();

  return (
    <NormalLayout>
      <Grid container justify="center">
        <Grid
          item
          container
          direction="column"
          justify="flex-start"
        >
          {error && (
            <SignupErrorComponent
              title={t('AREAS.ACCOUNT.SIGNUP.ERRORS.TITLE')}
              text={error}
            />
          )}

          {state.showPageOne && (
          <Grid item>
            <RegistrationHeader
              title={t('AREAS.ACCOUNT.SIGNUP.TITLE')}
            />
          </Grid>
          )}
        </Grid>

        <form 
          noValidate 
          onSubmit={async (e) => { 
            e.preventDefault(); 
            await onSubmit(); 
          }}
        >
          {state.showPageOne ? (
            <Grid item>
              <TextField
                label={t('AREAS.ACCOUNT.SIGNUP.FIRST_NAME')}
                variant="filled"
                fullWidth
                autoFocus
                margin="normal"
                value={values.firstName}
                onChange={e => onChange({ firstName: e.target.value })}
                InputProps={{
                  endAdornment: <PersonIcon /> 
                }}
                inputProps={{ maxLength: 100 }}
                name="first_name"
              />
              <TextField
                label={t('AREAS.ACCOUNT.SIGNUP.LAST_NAME')}
                variant="filled"
                fullWidth
                margin="normal"
                value={values.lastName}
                onChange={e => onChange({ lastName: e.target.value })}
                InputProps={{
                  endAdornment: <PersonIcon /> 
                }}
                inputProps={{ maxLength: 100 }}
                name="last_name"
              />
              <TextField
                type="email"
                label={t('AREAS.ACCOUNT.SIGNUP.EMAIL')}
                variant="filled"
                fullWidth
                margin="normal"
                value={values.email}
                onChange={e => onChange({ email: e.target.value })}
                InputProps={{
                  endAdornment: <EmailIcon /> 
                }}
                inputProps={{ maxLength: 254 }}
                name="email"
                helperText={fieldErrors.email}
              />
              <PhoneInput
                className={styles.phoneNumber}
                inputComponent={PhoneNumberInput as any}
                onChange={value => onChange({ phone: value })}
                value={values.phone}
                country={values.country}
                countries={['US', 'CA', 'GB']}
                limitMaxLength
                international={false}
                onCountryChange={country => onChange({ country })}            
              />
              <Typography variant="caption" gutterBottom align="center">
                {t('AREAS.ACCOUNT.SIGNUP.SMS_MESSAGE')}
              </Typography>
              <Grid container spacing={24} direction="column" justify="center">
                <Grid item container xs={12} justify="center">
                  <Button
                    wide
                    disabled={!canContinue}
                    onClick={() => { setState({ ...state, showPageOne: false }); }}
                  >
                    {t('AREAS.ACCOUNT.SIGNUP.CONTINUE')}
                  </Button>
                </Grid>
                <Grid item>
                  <LogInLink />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <Button variant="text" size="medium" onClick={() => { setState({ ...state, showPageOne: true }); }}>
                <ArrowLeftIcon />
                {t('AREAS.ACCOUNT.SIGNUP.BACK')}
              </Button>
              <TextField
                label={t('AREAS.ACCOUNT.SIGNUP.USERNAME')}
                variant="filled"
                fullWidth
                margin="normal"
                value={values.username}
                onChange={e => onChange({ username: e.target.value })}
                InputProps={{
                  endAdornment: <PersonIcon /> 
                }}
                inputProps={{ maxLength: 100 }}
                name="username"
              />
              
              <CreatePasswordInput
                password={values.password} 
                onChange={password => onChange({ password })}
                onValid={(value: boolean) => { setState({ ...state, validatedPassword: value }); }}
              />
              <Grid container spacing={24} direction="column" justify="center">
                <Grid item xs={12} className={styles.policyHeader}>
                  <Typography variant="body1">
                    {t('AREAS.ACCOUNT.SIGNUP.POLICY_HEADER')}
                  </Typography>
                  <PolicyAgreementComponent
                    handleCheckedbox={() => onChange({ policiesAccepted: !values.policiesAccepted })}
                    policiesNeeded={policiesNeeded}
                    checked={values.policiesAccepted}
                  />
                </Grid>
                <Grid item container xs={12} justify="center">
                  <Button
                    wide
                    disabled={!canSubmit || !state.validatedPassword}
                    type="submit"
                  >
                    {t('AREAS.ACCOUNT.SIGNUP.CREATE')}
                  </Button>
                </Grid>
                <Grid item>
                  <LogInLink />
                </Grid>
                {false && (
                  <Grid item>
                    <Typography variant="body1">For testing purposes only</Typography>
                    <FormControlLabel
                      label="Auto-verify"
                      control={(
                        <Checkbox
                          checked={values.autoVerify}
                          onChange={e => onChange({ autoVerify: e.target.checked })}
                          value="autoVerify"
                          color="primary"
                        />
                      )}
                    />                  
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </form>
      </Grid>
    </NormalLayout>
  );
};
