/* eslint-disable max-len */

import React, { Component } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export class PDFFileIcon extends Component<SvgIconProps> {
  render() {
    return (
      <SvgIcon {...this.props}>
        <path d="M18.75,3 L5.25,3 C4.0125,3 3,4.0125 3,5.25 L3,18.75 C3,19.9875 4.0125,21 5.25,21 L18.75,21 C19.9875,21 21,19.9875 21,18.75 L21,5.25 C21,4.0125 19.9875,3 18.75,3 Z M9.1875,11.4375 C9.1875,12.37125 8.43375,13.125 7.5,13.125 L6.375,13.125 L6.375,15.375 L4.6875,15.375 L4.6875,8.625 L7.5,8.625 C8.43375,8.625 9.1875,9.37875 9.1875,10.3125 L9.1875,11.4375 Z M14.8125,13.6875 C14.8125,14.62125 14.05875,15.375 13.125,15.375 L10.3125,15.375 L10.3125,8.625 L13.125,8.625 C14.05875,8.625 14.8125,9.37875 14.8125,10.3125 L14.8125,13.6875 Z M19.3125,10.3125 L17.625,10.3125 L17.625,11.4375 L19.3125,11.4375 L19.3125,13.125 L17.625,13.125 L17.625,15.375 L15.9375,15.375 L15.9375,8.625 L19.3125,8.625 L19.3125,10.3125 Z M6.375,11.4375 L7.5,11.4375 L7.5,10.3125 L6.375,10.3125 L6.375,11.4375 Z M12,13.6875 L13.125,13.6875 L13.125,10.3125 L12,10.3125 L12,13.6875 Z" />
      </SvgIcon>
    );
  }
}
