import { 
  Panel, ProfileResponse, PanelStatuses, PanelStatus, QuestionDetails
} from './dashboard-container';

export function calculatePanelStatus(panels: Panel[], responses: ProfileResponse): PanelStatuses {
  const status: PanelStatuses = {};
  panels.forEach((panel, i) => {      
    const questionKeys = (panel.questions || []).map(q => q.key);
    const panelHasResponses = questionKeys.some(key => hasValue(responses[key]));

    if (panelHasResponses) {
      const allRequiredQuestionsAnswered = isPanelComplete(panel, responses);
      if (allRequiredQuestionsAnswered) {
        status[panel.workflowStepId] = PanelStatus.Complete;
      } else {
        status[panel.workflowStepId] = PanelStatus.InProgress;
      }
    } else {
      const previousPanel = panels[i - 1];
      const previousPanelComplete = previousPanel 
        ? status[previousPanel.workflowStepId] === PanelStatus.Complete : true;

      if (previousPanelComplete) {
        status[panel.workflowStepId] = PanelStatus.GetStarted;
      } else {
        status[panel.workflowStepId] = PanelStatus.Disabled;
      }
    }
    
    // if panel has responses, are all required questions answered?
    //   yes -> COMPLETE, no -> IN_PROGRESS
    // if panel has no responses
    //   is previous panel COMPLETE?
    //     yes -> GET_STARTED
    //     no -> DISABLED
  });
  return status;
}

function isPanelComplete(panel: Panel, responses: ProfileResponse): boolean {
  const questions = panel.questions || [];
  const requiredQuestions = questions.filter(q => q.status === 'required');
  const allRequiredQuestionsAnswered = requiredQuestions.every(q => hasValue(responses[q.key]));
  const questionsWithFollowup = requiredQuestions
    .filter(q => q.followupQuestion && q.followupQuestion.status === 'required');

  if (!allRequiredQuestionsAnswered) {
    return false;
  }

  if (allRequiredQuestionsAnswered && !questionsWithFollowup.length) {
    return true;
  }

  if (allRequiredQuestionsAnswered && questionsWithFollowup.length) {            
    return allRequiredFollowupQuestionsAnswered(questionsWithFollowup, responses);
  }
  
  return false;
}

function hasValue(value: any): boolean {
  if (value === undefined || value === null) {
    return false;
  }
  
  if (value.trim && value.trim() === '') {
    return false;
  }

  if (value instanceof Array) {
    return value.length > 0;
  }

  return true;
}

function allRequiredFollowupQuestionsAnswered(
  questionsWithFollowup: QuestionDetails[], 
  responses: ProfileResponse
): boolean {
  return questionsWithFollowup.every((q) => {
    const questionResponse = responses[q.key];        
    
    // For now the only follow up question we can support is for the
    // positions question. TODO: remove the "followupQuestion" abstraction
    // entirely.
    if (q.key === 'requested_positions') {
      // does each checked position have a pay rate added?
      const payRatesAnswered = (questionResponse as string[]).every((positionId) => {
        if (q.followupQuestion!.on.includes(positionId)) {
          const followupQuestionKey = `${q.key}___${q.followupQuestion!.key}___${positionId}`;
          const payRateAdded = hasValue(responses[followupQuestionKey]);
          return payRateAdded;
        }
        return true;
      });

      return payRatesAnswered;
    }        

    return true;
  });
}
