import React from 'react';
import { Redirect } from 'react-router-dom';
import { WelcomeComponent } from './WelcomeComponent';
import config from '../../config';
import { LoadingComponent } from '../../common/loading-component';
import { AppContext, IAppContext } from '../core/AppContext';

interface State {
  redirect?: string;
  loadingWelcomeText: boolean;
}

export class WelcomeContainer extends React.Component<any, State> {  
  static contextType = AppContext;

  state: State = {
    loadingWelcomeText: true
  }    

  async componentDidMount() {
    // TODO: Fetch welcome text for this account
    setTimeout(() => {
      this.setState({ loadingWelcomeText: false });
    }, 200);      
  }
  
  render() {
    if (this.state.loadingWelcomeText) {
      return <LoadingComponent />;
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    
    const { account } = this.context as IAppContext;
    return (
      <>
        {!config.enableCognito ? (
          <WelcomeComponent
            loginRedirect="/profile"
            onContinue={() => this.setState({ redirect: `/account/legacy/signup/${account.slug}` })}
          />
        ) : (
          <WelcomeComponent
            loginRedirect="/profile"
            onContinue={() => this.setState({ redirect: `/account/signup/${account.slug}` })}
          />
        )}
      </>
    );
  }
}
