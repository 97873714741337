import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { NormalLayout } from '../../../common/layouts/NormalLayout';
import { RegistrationHeader } from '../common/RegistrationHeader';
import { Button } from '../../../common/button';
import { InputErrorIcon } from '../../../common/input-error-icon';
import { FormErrors } from '../common/FormErrors';


interface Props {
  onChange(username: string): void;
  onContinue(password: string): void;
  password: string;
  canContinue: boolean;
  error?: FormErrors;
}

export const PasswordComponent: React.FC<Props> = (props) => {
  const {
    onChange, onContinue, password, error
  } = props;

  const hasCodeError = showCodeError(error);

  return (
    <NormalLayout>
      <Grid container direction="column" spacing={8}>
        <Grid item>
          <RegistrationHeader
            title="Log in"
          />
        </Grid>
        <Grid item>
          <form onSubmit={(e) => {
            e.preventDefault();
            onContinue(password);
          }}
          >
            <Grid container spacing={16} direction="column" alignItems="stretch">
              <Grid item>
                <TextField
                  label="Password"
                  variant="filled"
                  fullWidth
                  autoFocus
                  type="password"
                  margin="normal"
                  value={password}
                  onChange={e => onChange(e.target.value)}
                  error={hasCodeError}
                  helperText={
                    (props.error && props.error.fieldErrors)
                      ? (
                        <span data-test-id="password-textfield-password-error">
                          {props.error.fieldErrors.code}
                        </span>
                      )
                      : null
                  }
                  InputProps={{
                    ...(hasCodeError && { endAdornment: <InputErrorIcon /> })
                  }}
                  inputProps={{ maxLength: 128 }}
                  data-test-id="password-textfield-password"
                />
              </Grid>
              <Grid item container xs={12} justify="center">
                <Button
                  wide
                  disabled={!props.canContinue}
                  type="submit"
                  data-test-id="continue"
                >
                  Continue
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="body1" align="center" data-test-id="password-link-createAccount">
                  Don&rsquo;t have an account?&nbsp;
                  <Link to="/account/legacy/signup">Create an account</Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </NormalLayout>
  );
};

function showCodeError(errors?: FormErrors): boolean {
  if (!errors) return false;

  if (errors.fieldErrors && errors.fieldErrors.code) {
    return true;
  }

  return false;
}
