import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { AppContext } from '../../areas/core/AppContext';
import { ExternalRedirect } from '../external-redirect';

export interface AccountRestrictedRouteProps extends RouteProps {
  accounts: string[],
  redirect: string
}

export const AccountRestrictedRoute: React.FC<AccountRestrictedRouteProps> = ({
  accounts, redirect, ...rest 
}) => {
  const context = useContext(AppContext);
  if (!accounts.includes(context.account.slug)) {
    return <Route render={() => <ExternalRedirect to={redirect} />} />;
  }
  
  return <Route {...rest} />;
};
