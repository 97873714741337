import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TickInsideCircleSVG } from '../../../common/icons/tick-inside-circle';
import styles from './profile-existing-component.module.scss';

export const ProfileExistingComponent: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      spacing={40}
      className={styles.outerContainer}
    >
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        spacing={24}
      >
        <Grid item>
          <TickInsideCircleSVG />
        </Grid>
        <Grid
          container
          item
          direction="column"
          spacing={16}
        >
          <Grid item>
            <Typography align="center" variant="h5">{t('AREAS.EXISTING_CREW_PROFILE.WELCOME_TO_MP')}</Typography>
          </Grid>
          <Grid item>
            <Typography align="center" variant="subtitle2">
              {t('AREAS.EXISTING_CREW_PROFILE.WELCOME_CONTENT')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justify="center"
            className={styles.buttonContainer}
          >
            <a
              href="mailto:support@lasso.io"
              data-test-id="profileexisting__a--support"
            >
              {t('AREAS.EXISTING_CREW_PROFILE.SUPPORT')}
            </a>
          </Grid>

          <Grid
            item
            container
            justify="center"
            className={styles.buttonContainer}
          >
            <a
              href="https://www.lasso.io/lasso-marketplace-frequently-asked-questions/"
              data-test-id="profileexisting__a--learnmore"
            >
              {t('AREAS.EXISTING_CREW_PROFILE.LEARN')}
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
