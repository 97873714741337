import React from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import { LoginInitContainer } from './LoginInitContainer';
import { PasswordContainer } from './PasswordContainer';
import { LoginCodeVerificationContainer } from './LoginCodeVerificationContainer';
import { ExternalRedirect } from '../../../common/external-redirect';
import { ForgotPasswordContainer } from './ForgotPasswordContainer';
import { DjangoSessionContainer } from '../common/DjangoSessionContainer';
import { CognitoSignInResponse } from './cognito';

interface Props {
  returnUrl: string;
}

export enum LoginStep {
  Init = 'INIT',
  Password = 'PASSWORD',
  Code = 'CODE',
  SAML = 'SAML',
  Complete = 'COMPLETE',
  Forgot = 'FORGOT_PASSWORD'
}

interface State {
  username: string;
  password: string;
  loginStep: LoginStep;
  migrateUser: boolean;
  user?: CognitoUser;
  samlRedirect?: string;
}

export class LoginContainer extends React.PureComponent<Props, State> {
  state: State = {
    username: '',
    password: '',
    loginStep: LoginStep.Init,
    migrateUser: false
  }

  private afterLoginInit = (username: string, nextStep: LoginStep, migrateUser: boolean, user?: CognitoUser) => {    
    this.setState({ 
      user, username, loginStep: nextStep, migrateUser 
    });    
  }

  private afterPassword = (user: CognitoUser, password: string, nextStep: LoginStep, migrateUser: boolean) => {
    this.setState({ 
      user, password, loginStep: nextStep, migrateUser 
    });
  }

  private afterVerificationCode = (user: CognitoUser, nextStep: LoginStep) => {
    this.setState({ user, loginStep: nextStep });
  }

  private afterForgotPassword = () => {
    this.setState({ loginStep: LoginStep.Init });
  }

  render() {
    switch (this.state.loginStep) {
      case LoginStep.Init: {
        return <LoginInitContainer onComplete={this.afterLoginInit} />;        
      }
      case LoginStep.Password: {
        return (
          <PasswordContainer
            user={this.state.user}  
            username={this.state.username}
            migrateUser={this.state.migrateUser} 
            onComplete={this.afterPassword}
          />
        );
      }
      case LoginStep.Code: {
        return (
          <LoginCodeVerificationContainer 
            user={this.state.user as CognitoSignInResponse} 
            username={this.state.username}
            password={this.state.password}
            onComplete={this.afterVerificationCode} 
          />
        );
      }
      case LoginStep.SAML: {
        return <ExternalRedirect to={this.state.samlRedirect!} />;
      }
      case LoginStep.Complete: {       
        return <DjangoSessionContainer returnUrl={this.props.returnUrl} />;
      }
      case LoginStep.Forgot: {
        return (
          <ForgotPasswordContainer 
            username={this.state.username}
            migrateUser={this.state.migrateUser}
            onComplete={this.afterForgotPassword} 
          />
        );
      }
      default: {
        return null;
      }
    }
  }
}
