import React, { Component } from 'react';
import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { OptionObject } from '../../../../../common/types/input-options';
import {
  QuestionObject,
  DropdownSelectQuestionObject,
  QuestionComponent
} from '../question-component/QuestionComponent';

interface Props {
  option: OptionObject;
  onChange?: (event: React.ChangeEvent<{}>, checked: boolean) => void;
  checked: boolean;
  followupQuestion?: (QuestionObject | DropdownSelectQuestionObject);
  disabled: boolean;
}

export class CheckBoxElement extends Component<Props> {
  render() {
    const { option } = this.props;
    let followupQuestion = null;
    if (this.props.checked && this.props.followupQuestion) {
      followupQuestion = (
        <QuestionComponent
          question={this.props.followupQuestion}
          lesserQuestionType={this.props.followupQuestion!.status}
          renderAsFollowup
        />
      );
    }
    return (
      <>
        <FormControlLabel
          control={
            <Checkbox color="primary" value={option.value} disabled={this.props.disabled} />
          }
          label={<span data-test-class="cb">{option.display}</span>}
          onChange={this.props.onChange}
          checked={this.props.checked}
        />
        {followupQuestion}
      </>
    );
  }
}
