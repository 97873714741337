import React from 'react';
import { Grid, Divider, Typography } from '@material-ui/core';
import { Checkmark } from '../../../../common/icons/checkmark';
import { EditLink } from '../../../../common/edit-link';
import { PanelStatus } from '../dashboard-container';
import { Button } from '../../../../common/button';
import styles from './panel.module.scss';


interface Props {
  header: string;
  description: string;
  route: string;
  isSummary: boolean;
  status: PanelStatus;
  workflowStepId: string;
}

export const PanelComponent: React.FC<Props> = ({
  header, description, route, status, workflowStepId
}) => {
  const textColor = status === PanelStatus.Disabled ? 'textSecondary' : 'textPrimary';

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        justify="flex-start"
        spacing={24}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="stretch"
          justify="flex-start"
          spacing={8}
        >
          <Grid
            item
            container
            direction="row"
            alignItems="flex-start"
            justify="space-between"
          >
            <Grid item xs={9}>
              <Typography
                variant="h6"
                color={textColor}
              >
                <b>{header}</b>
              </Typography>
            </Grid>
            <PanelStatusIndicator status={status} />
          </Grid>
          <Grid item xs={11} sm={11}>
            <Typography
              variant="body1"
              color={textColor}
            >
              {description}
            </Typography>
          </Grid>
          <PanelLink status={status} route={route} workflowStepId={workflowStepId} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="stretch"
        justify="center"
        spacing={24}
      >
        <Grid item>
          <Divider variant="fullWidth" light />
        </Grid>
      </Grid>
    </>
  );
};

const PanelStatusIndicator: React.FC<{ status: PanelStatus }> = ({ status }) => {
  switch (status) {
    case PanelStatus.InProgress: {
      return (
        <Grid item xs={3} style={{ alignSelf: 'center' }}>
          <Typography align="right" variant="body2" className={styles.inProgress}>(In Progress)</Typography>
        </Grid>
      );
    }
    case PanelStatus.Complete: {
      return (
        <Grid item>
          <Checkmark />
        </Grid>
      );
    }
    default: {
      return null;
    }
  }
};

const PanelLink: React.FC<{ status: PanelStatus, route: string, workflowStepId: string }> = (
  { status, route, workflowStepId }
) => {
  switch (status) {
    case PanelStatus.GetStarted: {
      return (
        <Grid item>
          <Button to={route} data-test-id="panel__button--getStarted">Get started</Button>
        </Grid>
      );
    }
    case PanelStatus.InProgress:
    case PanelStatus.Complete: {
      return (
        <Grid item>
          <EditLink linkPath={route} justify="flex-start" testingKey={`panel__editlink--${workflowStepId}`}>
            Edit
          </EditLink>
        </Grid>
      );
    }
    default: {
      return null;
    }
  }
};
