import React from 'react';
import { SnackbarProps } from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar as MatSnackbar, IconButton } from '@material-ui/core';


export const Snackbar: React.SFC<SnackbarProps> = props => (
  <MatSnackbar
    {...props}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    message={<span>{props.message}</span>}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={e => props.onClose && props.onClose(e, 'click')}
      >
        <CloseIcon />
      </IconButton>
    ]}
  />
);
