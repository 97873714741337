import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid, Typography } from '@material-ui/core';
import { PersonalInformationData } from '../workflow/question-forms/person-information/PersonInformationComponent';
import { PersonSummary } from '../../../common/person-summary';
import { FileSubmission } from './submission';
import { Option } from '../workflow/question-forms/autocomplete/single-select-autocomplete';
import { EditLink } from '../../../common/edit-link';
import styles from './summary-panel.module.scss';

export interface QuestionResponse {
  key: string;
  question: string;
  type: 'shortAnswer'
  | 'multipleSelectCheckbox'
  | 'multipleChoiceRadio'
  | 'fileUpload'
  | 'date'
  | 'dropdownSelect'
  | 'personInformation'
  | 'currency'
  | 'singleSelectAutocomplete'
  | 'airportAutocomplete';
  answer: (string | string[] | PersonalInformationData[] | Option | FileSubmission);
}

interface Props {
  header: string;
  linkPath: string;
  responses: QuestionResponse[];
  testingKey?: string;
}

export class SummaryPanel extends Component<Props> {
  private static renderAnswer(questionResponse: QuestionResponse) {
    if (questionResponse.answer) {
      const { answer } = questionResponse;
      if (questionResponse.type === 'personInformation') {
        return (questionResponse.answer as PersonalInformationData[]).map(
          personalInformationData => <PersonSummary person={personalInformationData} key={personalInformationData.id} />
        );
      }

      if (questionResponse.type === 'multipleSelectCheckbox') {
        const formattedAnswer = (answer as string[]).map((partialAnswer: string) => (
          <Grid item key={uuid()}>
            <Typography variant="body1">{partialAnswer}</Typography>
          </Grid>
        ));
        return (
          <Grid container direction="column" spacing={8}>
            {formattedAnswer}
          </Grid>
        );
      }

      return <Typography variant="body1">{answer.toString()}</Typography>;
    }

    return <Typography variant="body1" />;
  }

  render() {
    const pairings = this.props.responses.map(response => (
      <Grid
        item
        container
        direction="row"
        key={response.key}
      >
        <Grid item xs={7}>
          <Typography variant="body1" color="textSecondary" className={styles.question}>{response.question}</Typography>
        </Grid>
        <Grid item xs={5}>
          {SummaryPanel.renderAnswer(response)}
        </Grid>
      </Grid>
    ));

    return (
      <React.Fragment>
        <Grid
          item
          container
          direction="column"
          alignItems="stretch"
          justify="flex-start"
          spacing={16}
        >
          <Grid
            item
            container
            direction="row"
            alignItems="flex-end"
            justify="space-between"
          >
            <Grid item xs={9}>
              <Typography className={styles.header} variant="h6">{this.props.header}</Typography>
            </Grid>
            <Grid item xs={3}>
              <EditLink
                linkPath={this.props.linkPath}
                justify="flex-end"
                testingKey={`summarypanel__link--edit${this.props.testingKey ? `--${this.props.testingKey}` : ''}`}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            spacing={8}
          >
            {pairings}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
