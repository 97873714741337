import React from 'react';
import i18next from 'i18next';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { LoginStep } from './LoginContainer';
import { CodeVerificationComponent } from '../common/CodeVerificationComponent';
import { CognitoSignInResponse } from './cognito';


interface Props {
  user: CognitoSignInResponse;
  username: string;
  password: string;
  onComplete(user: CognitoUser, nextStep: LoginStep): void;
}

interface State {
  code: string;
  error?: string;
}

export class LoginCodeVerificationContainer extends React.PureComponent<Props, State> {
  state: State = {
    code: ''
  };

  private submitCode = async (code: string) => {
    try {
      let user: CognitoUser;
      if (this.props.user.challengeParam && this.props.user.challengeParam.type === 'passwordless') {
        // Handle passwordless
        user = await Auth.sendCustomChallengeAnswer(this.props.user, code);
      } else {
        // Handle MFA.
        user = await Auth.confirmSignIn(this.props.user, code, 'SMS_MFA');
      }
      this.props.onComplete(user, LoginStep.Complete);
    } catch (e) {
      this.setState({ error: i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.INVALID_CODE') });
    }
  }

  private resendCode = async () => {
    try {
      let user = null;
      if (this.props.user.challengeParam && this.props.user.challengeParam.type === 'passwordless') {
        // Call sign in again to resend the code.
        user = await Auth.signIn(this.props.username);
      } else {
        // Call sign in again to resend the MFA code.
        user = await Auth.signIn(this.props.username, this.props.password);
      }
      this.props.onComplete(user, LoginStep.Code);
    } catch (e) {
      this.setState({ error: i18next.t('AREAS.ACCOUNT.LOGIN.ERRORS.RESENDING_CODE') });
    }
  }

  private handleChange = (code: string) => {
    this.setState({ 
      code,
      error: ''
    });
  }

  render() {
    let codeDestination: string = '';
    if (this.props.user.challengeName === 'CUSTOM_CHALLENGE' || this.props.user.challengeName === 'SMS_MFA') {
      codeDestination = this.props.user.challengeParam.CODE_DELIVERY_DESTINATION;
    }

    return (
      <CodeVerificationComponent
        onChange={this.handleChange} 
        error={this.state.error}
        onResendCode={this.resendCode}
        onSubmit={this.submitCode}
        codeDestination={codeDestination}
        code={this.state.code}
      />
    );
  }
}
