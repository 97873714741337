import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import config from '../../../../../config';
import { FileUploadComponent } from './FileUploadComponent';
import { FileSubmission } from '../../../review/submission';


interface Props {
  question: string;
  helpText?: string;
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6';
  status: 'required'|'optional';
  displayRequirednessMarker: boolean;
  acceptedFileTypes?: string[];
  maxInputs?: number;
  value?: FileSubmission;
  errorMessage?: string;
  testingKey?: string;
  onChange(value?: FileSubmission): void;
  disableButton?: () => void;
  enableButton?: () => void;
}

export interface FileObject {
  key: string;
  name: string;
  type: string;
  file?: File;
  documentId?: number;
  loading: boolean;
}

interface State {
  selectedFile?: FileObject;
  errorMessage?: string;
}

export class FileUploadContainer extends Component<Props, State> {
  state: State = {
    selectedFile: this.props.value ? {
      name: this.props.value.name,
      type: this.props.value.type,
      documentId: this.props.value.document_id,
      loading: false,
      key: uuid()
    } : undefined,
    errorMessage: this.props.errorMessage || undefined
  }

  private handleChange = async (selectorFiles: FileList|null) => {
    if (selectorFiles && selectorFiles[0] !== undefined) {
      const file = selectorFiles[0];
      const newFile: FileObject = {
        key: uuid(),
        name: file.name,
        type: file.type,
        file,
        loading: true
      };

      this.setState({
        selectedFile: newFile,
        errorMessage: ''
      });

      try {
        newFile.documentId = await this.uploadFile(file);
      } catch (e) {
        console.warn(e);
        const newState: State = {
          selectedFile: undefined
        };
        if (e.response.status === 400) {
          [newState.errorMessage] = e.response.data.file;
        }

        this.setState(newState);

        return;
      }

      this.setState({
        selectedFile: { ...newFile, loading: false }
      });

      this.props.onChange(this.getChangeObject());
    }
  };

  private getChangeObject = (): FileSubmission | undefined => {
    if (this.state.selectedFile) {
      return {
        document_id: this.state.selectedFile.documentId!,
        name: this.state.selectedFile.name,
        type: this.state.selectedFile.type
      };
    }
    return undefined;
  }

  private uploadFile = async (file: File) => {
    (this.props.disableButton || Function)();
    const data = new FormData();
    data.append('file', file);
    const response = await axios(`${config.apiRoot}/internal_api/file/`, {
      withCredentials: true,
      method: 'POST',
      data
    });
    (this.props.enableButton || Function)();
    return response.data.document_id;
  }

  handleChipDelete = async () => {
    (this.props.disableButton || Function)();
    const fileId = this.state.selectedFile!.documentId;

    try {
      await axios(`${config.apiRoot}/internal_api/file/${fileId}/`, {
        withCredentials: true,
        method: 'DELETE'
      });
    } catch (e) {
      console.warn(e);
    }

    this.setState({
      selectedFile: undefined
    });

    this.props.onChange(this.getChangeObject());
    (this.props.enableButton || Function)();
  };

  render() {
    return (
      <FileUploadComponent
        question={this.props.question}
        helpText={this.props.helpText}
        textVariant={this.props.textVariant}
        status={this.props.status}
        displayRequirednessMarker={this.props.displayRequirednessMarker}
        acceptedFileTypes={this.props.acceptedFileTypes!.toString()}
        selectedFile={this.state.selectedFile}
        maxInputs={1}
        handleFileInputChange={this.handleChange}
        handleChipDelete={this.handleChipDelete}
        errorMessage={this.state.errorMessage}
        testingKey={this.props.testingKey}
      />
    );
  }
}
