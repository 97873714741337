import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { LoginComponent } from './LoginComponent';
import config from '../../../config';
import { InitAuthResponse } from './InitAuthResponse';

export interface LoginState {
  phone: string | null;
  email: string | null;
  submitting?: boolean;
  error?: string;
  initAuthResponse?: InitAuthResponse;
}

interface Props {
  onComplete(loginState: LoginState): void;
  loginState?: LoginState;
}

export class LoginContainer extends React.Component<Props, LoginState> {
  state: LoginState = {
    phone: this.props.loginState ? this.props.loginState.phone : null,
    email: this.props.loginState ? this.props.loginState.email : null,
    submitting: false
  }

  private handleChange = (emailPhone: string) => {
    if (emailPhone.includes('@')) {
      this.setState({
        phone: null,
        email: emailPhone
      });
    } else {
      this.setState({
        phone: emailPhone,
        email: null
      });
    }
  }

  private initializeAuthentication = async () => {
    this.setState({ submitting: true });

    let response: AxiosResponse;
    try {
      response = await axios(`${config.apiRoot}/internal_api/auth/login_init/`, {
        method: 'POST',
        withCredentials: true,
        data: {
          email: this.state.email,
          phone: this.state.phone
        }
      });
    } catch (e) {
      this.setState({
        error: 'Invalid phone or email',
        submitting: false
      });
      return;
    }

    const data: InitAuthResponse = {
      otpRequired: response.data.otp_required,
      passwordRequired: response.data.password_required,
      newAccount: response.data.new_account,
      lastFour: response.data.last_four
    };
    this.setState({ initAuthResponse: data });

    this.props.onComplete(this.state);
  }

  private canSubmit() {
    const { phone, email, submitting } = this.state;
    return !submitting
    && !((phone == null || phone === '') && (email == null || email === ''));
  }

  render() {
    return (
      <LoginComponent
        onChange={this.handleChange}
        onContinue={this.initializeAuthentication}
        phoneEmail={this.state.phone || this.state.email || ''}
        canSubmit={this.canSubmit()}
        error={this.state.error}
      />
    );
  }
}
