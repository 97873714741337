import React from 'react';
import i18next from 'i18next';
import Auth from '@aws-amplify/auth';
import { CodeVerificationComponent } from '../common/CodeVerificationComponent';

interface Props {
  signUpParams: any;
  signUpResult: any;
  onComplete(signUpResult: any): void;
}

interface State {
  code: string;
  error?: string;
}

export class SignupCodeVerificationContainer extends React.PureComponent<Props, State> {
  state: State = {
    code: ''
  };

  private submitCode = async (code: string) => {
    try {
      const user = await Auth.confirmSignUp(this.props.signUpResult.user.username, code);
      this.props.onComplete(user);
    } catch (e) {
      this.setState({ error: i18next.t('AREAS.ACCOUNT.SIGNUP.ERRORS.INVALID_CODE', { message: e.message }) });
    }
  }

  private resendCode = async () => {
    try {
      await Auth.resendSignUp(this.props.signUpResult.user.username);
    } catch (e) {
      this.setState({ error: i18next.t('AREAS.ACCOUNT.SIGNUP.ERRORS.RESENDING_CODE', { message: e.message }) });
    }
  }

  private handleChange = (code: string) => {
    this.setState({ 
      code,
      error: ''
    });
  }

  render() {
    let codeDestination: string = '';
    if (this.props.signUpResult.codeDeliveryDetails) {
      codeDestination = this.props.signUpResult.codeDeliveryDetails.Destination;
    }

    return (
      <CodeVerificationComponent
        onChange={this.handleChange}
        error={this.state.error}
        onResendCode={this.resendCode}
        onSubmit={this.submitCode}
        codeDestination={codeDestination}
        code={this.state.code}
      />
    );
  }
}
