import React from 'react';
import { PasswordComponent } from './PasswordComponent';
import { FormErrors } from '../common/FormErrors';

interface State {
  password: string;
  error?: FormErrors;
}

interface Props {
  onContinue(password: string): void;
  error?: FormErrors;
  isContinuing?: boolean;
}

export class PasswordContainer extends React.Component<Props, State> {
  state: State = {
    password: ''
  }

  private handleChange = (password: string) => {
    this.setState({
      password
    });
  }

  private canContinue() {
    const { password } = this.state;
    return !this.props.isContinuing
    && !((password == null || password === ''));
  }

  render() {
    return (
      <PasswordComponent
        onChange={this.handleChange}
        onContinue={this.props.onContinue}
        canContinue={this.canContinue()}
        error={this.props.error}
        password={this.state.password}
      />
    );
  }
}
