import React, { Component } from 'react';
import { MaterialUiPickersDate } from 'material-ui-pickers/typings/date';
import { DateComponent } from './DateComponent';

interface Props {
  question: string,
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6',
  status: 'required'|'optional',
  displayRequirednessMarker: boolean,
  originalValue?: string,
  onChange?: (date: MaterialUiPickersDate) => void,
  errorMessage?: string,
}
interface State {
  selectedDate: string,
}

export class DateContainer extends Component<Props, State> {
  componentWillMount(): void {
    const dateToDisplay: string = this.props.originalValue ? this.props.originalValue! : '';
    this.setState({ selectedDate: dateToDisplay });
  }

  handleDateChange = (date: any) => {
    this.setState({ selectedDate: date });
    if (this.props.onChange) {
      this.props.onChange(date);
    }
  };

  render() {
    return (
      <DateComponent
        question={this.props.question}
        textVariant={this.props.textVariant}
        status={this.props.status}
        displayRequirednessMarker={this.props.displayRequirednessMarker}
        selectedDate={this.state.selectedDate}
        handleDateChange={this.handleDateChange}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}
