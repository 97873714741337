import React, { Component } from 'react';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { QuestionHeading } from '../question-heading';
import { OptionObject } from '../../../../../common/types/input-options';

interface Props {
  question: string,
  textVariant: 'body1'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6',
  status: 'required'|'optional',
  displayRequirednessMarker: boolean,
  options: OptionObject[],
  onChange?: (event: React.ChangeEvent<{}>, value: string) => void,
  value: string,
  errorMessage?: string
}

export class MultipleChoiceComponent extends Component<Props> {
  render() {
    let errorMessage = null;
    if (this.props.errorMessage !== undefined) {
      errorMessage = <FormHelperText error>{this.props.errorMessage!}</FormHelperText>;
    }
    return (
      <>
        <QuestionHeading
          question={this.props.question}
          textVariant={this.props.textVariant}
          status={this.props.status}
          displayRequirednessMarker={this.props.displayRequirednessMarker}
        />
        <RadioGroup onChange={this.props.onChange} value={this.props.value}>
          {this.props.options.map(option => (
            <FormControlLabel
              key={option.key}
              value={option.value}
              control={<Radio color="primary" />}
              label={option.display}
            />
          ))}
          {errorMessage}
        </RadioGroup>
      </>
    );
  }
}
