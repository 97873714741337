import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Button } from '../../../common/button';
import { InputErrorIcon } from '../../../common/input-error-icon';
import { NormalLayout } from '../../../common/layouts/NormalLayout';
import { Snackbar } from '../../../common/snackbar';
import { RegistrationHeader } from './RegistrationHeader';
import styles from './code-verification.module.scss';

interface Props {
  code: string;
  codeDestination: string;
  error?: string;
  onChange(code: string): void;
  onSubmit(code: string): Promise<void>;
  onResendCode(): Promise<void>;
}

interface LocalState {
  showSnackbar: boolean;
}

export const CodeVerificationComponent: React.FC<Props> = (props) => {
  const [state, setState] = useState<LocalState>({ showSnackbar: false });
  const { t } = useTranslation();

  return (
    <NormalLayout>
      <Grid container direction="column" spacing={8}>
        <Grid item>
          <RegistrationHeader
            title={t('AREAS.ACCOUNT.CODE_VERIFICATION.TITLE')}
          />
        </Grid>
        <Grid item>
          <form onSubmit={async (e) => {
            e.preventDefault();
            await props.onSubmit(props.code);
          }}
          >
            <Grid container spacing={16} direction="column" alignItems="stretch">
              <Grid item container direction="row" alignItems="center" justify="center">
                <Typography align="center" variant="body1">
                  {t('AREAS.ACCOUNT.CODE_VERIFICATION.CODE_DESTINATION', { codeDestination: props.codeDestination })}
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  label={t('AREAS.ACCOUNT.CODE_VERIFICATION.VERIFICATION_CODE')}
                  type="tel"
                  placeholder="######"
                  variant="filled"
                  fullWidth
                  autoFocus
                  margin="normal"
                  value={props.code}
                  onChange={e => props.onChange(e.target.value)}
                  error={!!props.error}
                  helperText={props.error}
                  InputProps={{
                    ...(props.error && { endAdornment: <InputErrorIcon /> })
                  }}
                  inputProps={{ maxLength: 6 }}
                />
              </Grid>
              <Grid item container justify="center" className={styles.resendLink}>
                <Button
                  variant="text"
                  size="small"
                  onClick={async () => { 
                    await props.onResendCode();
                    setState({ ...state, showSnackbar: true });
                  }}
                >
                  {t('AREAS.ACCOUNT.CODE_VERIFICATION.RESEND_CODE')}
                </Button>
              </Grid>
              <Grid item container justify="center" xs={12}>
                <Button wide disabled={props.code.trim().length !== 6} type="submit">
                  {t('AREAS.ACCOUNT.CODE_VERIFICATION.SUBMIT')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Snackbar
        open={state.showSnackbar}
        autoHideDuration={3000}
        message={t('AREAS.ACCOUNT.CODE_VERIFICATION.CODE_RESENT')}
        onClose={() => setState({ ...state, showSnackbar: false })}
      />
    </NormalLayout>
  );
};
