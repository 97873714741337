import React from 'react';
import axios, { AxiosError } from 'axios';
import { Snackbar } from '../../common/snackbar';


interface State {
  open: boolean;
  message: string;
}

export class GenericRequestError extends React.Component<any, State> {
  private requestInterceptor: number | undefined

  private responseInterceptor: number | undefined

  state: State = {
    open: false,
    message: 'There was a problem with your request.',
  }

  componentDidMount() {
    this.requestInterceptor = axios.interceptors.request.use(undefined, this.interceptError);
    this.responseInterceptor = axios.interceptors.response.use(undefined, this.interceptError);
  }

  componentWillUnmount() {
    if (this.requestInterceptor !== undefined) {
      axios.interceptors.request.eject(this.requestInterceptor);
    }
    if (this.responseInterceptor !== undefined) {
      axios.interceptors.response.eject(this.responseInterceptor);
    }
  }

  private interceptError = (error: AxiosError) => {
    if (!axios.isCancel(error) && (!error.response || error.response.status >= 500)) {
      console.warn('Error processing request.', error.message);
      this.setState({
        open: true,
        message: `There was a problem with your request,
          please check your internet connection and try again.`
      });
    } else if (error.response && error.response.status === 404) {
      console.warn('Resource not found.', error.message);
      this.setState({
        open: true,
        message: 'There was a problem with your request, resource not found.'
      });
    }

    return Promise.reject(error);
  }

  render() {
    return (
      <Snackbar
        open={this.state.open}
        message={this.state.message}
        onClose={() => this.setState({ open: false })}
      />
    );
  }
}
