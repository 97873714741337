import React, { 
  useContext, useEffect, useState 
} from 'react';
import axios, { AxiosResponse } from 'axios';
import Auth from '@aws-amplify/auth';
import { withRouter } from 'react-router-dom';
import { Config } from '../../config';
import { 
  AppContext, IAppContext, WritableAppState 
} from './AppContext';
import { LoadingComponent } from '../../common/loading-component';
import { NotFoundPage } from '../../common/not-found-page';
import { local } from '../../common/local-storage';


Auth.configure({
  region: process.env.REACT_APP_COGNITO_REGION,
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_COGNITO_SIGNIN_CB,
    redirectSignOut: process.env.REACT_APP_COGNITO_SIGNOUT_CB,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
});

interface LocalState {
  loading: boolean;
  invalidAccount: boolean;
}

const InitializeApp: React.FC<any> = (props) => {  
  const appState = useContext(AppContext);
  const [localState, setLocalState] = useState<LocalState>({
    loading: true,
    invalidAccount: false
  });
  
  useEffect(() => {
    initializeReact(appState.config, appState, setLocalState);
  }, [appState.config.accountSlug]);

  if (localState.invalidAccount) {
    return <NotFoundPage />;
  }

  if (localState.loading) {
    return <LoadingComponent />;
  }

  return props.children;
};

async function initializeReact(
  config: Config, appState: IAppContext, setState: React.Dispatch<React.SetStateAction<LocalState>>
) {
  let appContext: WritableAppState;
  setState({ loading: true, invalidAccount: false });

  try {
    const response: AxiosResponse = await axios(`${config.apiRoot}/internal_api/initialize_react/`, {
      method: 'GET',
      withCredentials: true
    });
    if (response.data) {
      appContext = response.data;
      appState.setAppContext(appContext);  
    }      
    setState({ loading: false, invalidAccount: false });
  } catch (e) {    
    if (e.response && e.response.status === 400 && e.response.data.error === 'Invalid account') {
      local.removeItem('account');
      setState({ loading: false, invalidAccount: true });
    } else {
      throw e;
    }
  }   
}

export const InitializeAppContainer = withRouter(InitializeApp);
