import React, { Component } from 'react';
import Select from 'react-select';
import { Typography } from '@material-ui/core';
import { ValueType, ActionMeta, InputActionMeta } from 'react-select/lib/types';
import { ReactSelectCustomComponents, customStyles } from '../ReactSelectCustomComponents';
import { QuestionHeading } from '../../question-heading';
import styles from './single-select-autocomplete.module.scss';

export interface Option {
  value: string | number;
  label: string;
  isDisabled?: boolean;
}

export interface SingleSelectAutocompleteProps {
  placeholder?: string;
  options: Option[];
  selected?: Option;
  question: string,
  textVariant: 'body1' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  helpText?: string;
  status: 'required' | 'optional',
  displayRequirednessMarker: boolean,
  testingKey?: string;
  onChange?: (value: ValueType<Option>, action: ActionMeta) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
}

export class SingleSelectAutocomplete extends Component<SingleSelectAutocompleteProps> {
  render() {
    return (
      <React.Fragment>
        <QuestionHeading
          question={this.props.question}
          textVariant={this.props.textVariant}
          status={this.props.status}
          displayRequirednessMarker={this.props.displayRequirednessMarker}
        />
        <Typography variant="body1" color="textSecondary" className={styles.helpText}>
          {this.props.helpText}
        </Typography>
        <span data-test-id={`singleselectautocomplete__parentofselect--${this.props.testingKey}`}>
          <Select
            options={this.props.options}
            menuPlacement="auto"
            minMenuHeight={250}
            isClearable
            blurInputOnSelect
            styles={customStyles}
            components={ReactSelectCustomComponents}
            onChange={this.props.onChange}
            onInputChange={this.props.onInputChange}
            defaultValue={this.props.selected}
            placeholder={this.props.placeholder}
            textFieldProps={{ dataTestId: `select__control--${this.props.testingKey}` }}
          />
        </span>
      </React.Fragment>
    );
  }
}
