import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  QuestionComponent,
  QuestionObject,
  DropdownSelectQuestionObject,
} from './question-forms/question-component';
import { ClearButton } from '../../../common/clear-button';
import { Button } from '../../../common/button';
import {
  LinearProgressContainer
} from '../LinearProgressContainer';
import { PanelStatuses } from '../dashboard/dashboard-container';

interface Props {
  header: string;
  questions: (QuestionObject | DropdownSelectQuestionObject)[],
  helpText?: string;
  requirednessMarker: 'required' | 'optional';
  allowProgress: boolean;
  panelStatuses: PanelStatuses;
  workflowStepId: string;
  onSubmit(): void;
  onExit(): void;
}

interface State {
  disableButton: boolean;
}

class InternalProfileWorkflowContainer extends Component<Props & RouteComponentProps & WithTranslation, State> {
  state: State = {
    disableButton: false,
  }

  disableButton = () => {
    this.setState({ disableButton: true });
  };

  enableButton = () => {
    this.setState({ disableButton: false });
  };

  getTestingKey = (workflowStepId: string, questionKey: string) => (
    `${workflowStepId}${questionKey ? `--${questionKey}` : ''}`
  );

  render() {
    let questions = null;
    if (this.props.questions != null) {
      questions = this.props.questions.map(question => (
        <QuestionComponent
          key={question.key}
          question={question}
          lesserQuestionType={this.props.requirednessMarker}
          disableButton={this.disableButton}
          enableButton={this.enableButton}
          testingKey={this.getTestingKey(this.props.workflowStepId, question.key)}
        />
      ));
    }

    return (
      <form noValidate onSubmit={(event) => { event.preventDefault(); this.props.onSubmit(); }}>
        <LinearProgressContainer panelStatuses={this.props.panelStatuses} />
        <Grid
          container
          direction="column"
          justify="space-between"
          spacing={32}
        >
          <Grid
            item
            container
            direction="column"
            alignItems="flex-end"
          >
            <Grid item>
              <ClearButton
                onClick={this.props.onExit}
                linkText="Save and exit"
                testingKey={this.props.workflowStepId}
              />
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant="h5" gutterBottom={!!this.props.helpText}>
              {this.props.header}
            </Typography>
            {this.props.helpText && (
              <Typography variant="body1">
                {this.props.helpText}
              </Typography>
            )}
          </Grid>

          {this.props.requirednessMarker === 'optional' && (
            <Grid item>
              <Typography variant="body2">
                {this.props.t('AREAS.CREW_PROFILE.REQUIRED_DEF')}
              </Typography>
            </Grid>
          )}

          {questions}
          {this.props.requirednessMarker === 'required' && (
            <Grid item>
              <Typography variant="body2" color="error">*Required</Typography>
            </Grid>
          )}

          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ padding: 16 }}
          >
            <Button
              wide
              type="submit"
              disabled={!this.props.allowProgress || this.state.disableButton}
              data-test-id="continue"
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export const ProfileWorkflowContainer = withRouter(withTranslation()(InternalProfileWorkflowContainer));
