import React, { Component } from 'react';
import { LassoLogo } from '../icons/lasso-logo';
import styles from './loading.module.scss';

export class LoadingComponent extends Component {
  render() {
    return (
      <div className={styles.loadingContainer}>
        <LassoLogo className={styles.logo} />
      </div>
    );
  }
}
