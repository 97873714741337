import React, { Component } from 'react';
import { UploadedFileChip } from '../../../../../common/uploaded-file-chip';

interface Props {
  fileType: string;
  fileChipLabel: string;
  handleChipDelete: any;
  loading: boolean;
}
interface State {
  truncatedLabel: string;
}

export class UploadedFileChipContainer extends Component<Props, State> {
  state: State = {
    truncatedLabel: '',
  };

  componentDidMount(): void {
    const { fileChipLabel } = this.props;
    // eslint-disable-next-line prefer-template
    const truncatedLabel = fileChipLabel.length > 18 ? fileChipLabel.substring(0, 18) + '...' : fileChipLabel;
    this.setState({ truncatedLabel });
  }

  render() {
    return (
      <UploadedFileChip
        loading={this.props.loading}
        fileType={this.props.fileType}
        fileChipLabel={this.state.truncatedLabel}
        handleChipDelete={this.props.handleChipDelete}
      />
    );
  }
}
