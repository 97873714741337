import React from 'react';
import axios from 'axios';
import { CodeVerificationComponent } from './CodeVerificationComponent';
import config from '../../../config';
import { FormErrors } from '../common/FormErrors';

interface State {
  code: string;
  showSnackbar: boolean;
}

interface Props {
  email: string | null;
  phone: string | null;
  edit: () => void; 
  lastFour: string;
  error?: FormErrors;
  onSubmit(otp: string): void;
  isSubmitting?: boolean,
}

export class LoginCodeVerificationContainer extends React.Component<Props, State> {
  state: State = {
    code: '',
    showSnackbar: false,
  }

  private submit = () => {
    this.props.onSubmit(this.state.code);
  }
  
  private resendCode = async () => {
    const { email, phone } = this.props;
    try {
      await axios(`${config.apiRoot}/internal_api/auth/login_init/`, {
        method: 'POST',
        withCredentials: true,
        data: {
          email,
          phone
        }
      }).then(() => this.setState({ showSnackbar: true }));
    } catch (e) {
      console.warn(e);
    }
  }

  handleClose = () => { this.setState({ showSnackbar: false }); }

  private canSubmit() {
    return !this.props.isSubmitting && this.state.code !== '';
  }

  render() {
    return (
      <CodeVerificationComponent
        onChange={code => this.setState({ code })}
        onSubmit={this.submit}
        onResendCode={this.resendCode}
        codeDestination={this.props.lastFour}
        error={this.props.error}
        code={this.state.code}
        canSubmit={this.canSubmit()}
        editPath={this.props.edit}
        showSnackbar={this.state.showSnackbar}
        handleClose={this.handleClose}
      />
    );
  }
}
