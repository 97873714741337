import React from 'react';
import i18next from 'i18next';
import Auth from '@aws-amplify/auth';
import { SignUpParams } from '@aws-amplify/auth/lib/types';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import uuidv4 from 'uuid/v4';
import { PolicyObject } from './service-terms/PolicyAgreementComponent';
import { SignupInitComponent } from './SignupInitComponent';
import { FieldErrors } from '../common/FormErrors';
import { SignupStep } from './SignupContainer';

interface Props {
  policiesNeeded: Array<PolicyObject>;
  onComplete(user: any, params: any, nextStep: SignupStep): void;
}

interface State {
  values: SignupForm;
  error?: string;
  fieldErrors: FieldErrors;
  submitting: boolean;
}

export interface SignupForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  username: string;
  password: string;
  country: string | undefined;
  policiesAccepted: boolean;
  autoVerify: boolean;
}

export class SignupInitContainer extends React.PureComponent<Props, State> {
  state: State = {
    values: { ...defaultFormValues },
    fieldErrors: {},
    submitting: false
  }

  private handleSubmit = async () => {
    this.setState({ submitting: true });

    const attributes: any = {
      given_name: this.state.values.firstName,
      family_name: this.state.values.lastName,
      email: this.state.values.email,
      phone_number: this.state.values.phone
    };

    if (this.state.values.username && this.state.values.username.length > 0) {
      attributes['custom:original_username'] = this.state.values.username;
    }

    const { language } = i18next;
    if (language) {
      attributes['custom:preferred_language'] = language;
    }

    const params: SignUpParams = {
      username: uuidv4(),
      password: this.state.values.password,
      attributes
    };

    // Use the 'validationData' property to handle testing/auto-verify. 
    if (this.state.values.autoVerify) {
      const validationData = [];
      validationData.push(new CognitoUserAttribute({
        Name: 'auto_verify',
        Value: 'true'
      }));
      params.validationData = validationData;      
    }

    try {
      const user = await Auth.signUp(params);
      this.setState({ submitting: false });

      const nextStep: SignupStep = (this.state.values.autoVerify) ? SignupStep.Complete : SignupStep.Code;
      this.props.onComplete(user, params, nextStep);
    } catch (err) {
      // The registration could fail if the user is trying to register with
      // a duplicate username, email address, or phone number.
      // Check err.message (which is a string). Check if the string has one of these
      // error codes: 'duplicate_email', 'duplicate_phone_number', 'duplicate_username', or
      // saml_domain.
      let errorMessage = i18next.t('AREAS.ACCOUNT.SIGNUP.ERRORS.REGISTRATION', { message: err.message });
      if (err.code === 'UserLambdaValidationException') {
        if (err.message.includes('duplicate_email')) {
          errorMessage = i18next.t('AREAS.ACCOUNT.SIGNUP.ERRORS.DUPLICATE_EMAIL');
        } else if (err.message.includes('duplicate_phone_number')) {
          errorMessage = i18next.t('AREAS.ACCOUNT.SIGNUP.ERRORS.DUPLICATE_PHONE');
        } else if (err.message.includes('duplicate_username')) {
          errorMessage = i18next.t('AREAS.ACCOUNT.SIGNUP.ERRORS.DUPLICATE_USERNAME');
        } else if (err.message.includes('saml_domain')) {
          errorMessage = i18next.t('AREAS.ACCOUNT.SIGNUP.ERRORS.DUPLICATE_SAML');
        } 
      }

      this.setState({
        error: errorMessage,
        submitting: false 
      });
    }
  }

  private handleChange = (change: { [fieldName: string]: any }) => {
    this.setState(prevState => ({ 
      values: { ...prevState.values, ...change },
      error: '',
      fieldErrors: {}
    }));
  }

  private canSubmit = () => {
    const values = {
      password: this.state.values.password || '',
      policiesAccepted: this.state.values.policiesAccepted
    };

    // Required fields: firstName, lastName, email, phone, password, and policies.
    return this.canContinue() 
      && !Object.values(values).includes('') 
      && (values.policiesAccepted || this.props.policiesNeeded.length === 0);
  }

  private canContinue = () => {
    const values = {
      firstName: this.state.values.firstName || '',
      lastName: this.state.values.lastName || '',
      email: this.state.values.email || '',
      phone: this.state.values.phone || ''
    };
    return !Object.values(values).includes('') && !this.state.submitting;
  }

  render() {   
    return (
      <SignupInitComponent
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        values={this.state.values}
        policiesNeeded={this.props.policiesNeeded}
        canSubmit={this.canSubmit()}
        canContinue={this.canContinue()}
        error={this.state.error}
        fieldErrors={this.state.fieldErrors}
      />
    );
  }
}

const defaultFormValues: SignupForm = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  phone: '',
  password: '',
  country: 'US',
  policiesAccepted: false,
  autoVerify: false
};
