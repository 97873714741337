import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@material-ui/core';
import { Button } from '../../../common/button';
import { AppContext } from '../AppContext';
import { InputErrorIcon } from '../../../common/input-error-icon';
import { validatePhone } from './validatePhone';

interface Props {
  onContinue(username: string): void;
}

export const EmailPhoneComponent: React.FC<Props> = ({ onContinue }) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const { user } = useContext(AppContext);

  const validate = () => {
    if (username !== user.email && !validatePhone(username, user.phone)) {
      setError('Incorrect email or phone number');
      return false;
    }
    return true;
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      if (validate()) {
        onContinue(username);
      }
    }}
    >
      <Grid container spacing={16} direction="column" alignItems="stretch">
        <Grid item>
          <TextField
            label={t('AREAS.ACCOUNT.LOGIN.USERNAME')}
            variant="filled"
            fullWidth
            autoFocus
            margin="normal"
            value={username}
            onChange={e => setUsername(e.target.value)}
            inputProps={{ maxLength: 254 }}
            helperText={
              error && <span data-test-id="emailphone__reset--username--error">{error}</span>
            }
            error={!!error}
            InputProps={{
              ...(error && { endAdornment: <InputErrorIcon /> })
            }}
            data-test-id="emailphone__reset--username"
          />
        </Grid>
        <Grid item container xs={12} justify="center">
          <Button
            wide
            disabled={!username.length}
            type="submit"
          >
            {t('AREAS.ACCOUNT.PASSWORD_RESET.CONTINUE')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
