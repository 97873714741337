import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { Button } from '../../../../common/button';
import { ConfirmDecline } from './ConfirmDeclineComponent';
import { EndUserLicenseAgreement } from './EndUserLicenseAgreementComponent';
import { EqualEmploymentOpportunity } from './EqualEmploymentOpportunityComponent';
import { NormalLayout } from '../../../../common/layouts/NormalLayout';
import { RegistrationHeader } from '../../common/RegistrationHeader';

interface Props {
  EULAAccepted: boolean;
  EEOAccepted: boolean;
}

interface State {
  EULAAccepted: boolean;
  EEOAccepted: boolean;
  termsComponent: any,
  decline: boolean,
  declineConfirmed: boolean
}

export const ServiceTermsComponent = class extends Component<Props, State> {
  static defaultProps = {
    EULAAccepted: false,
    EEOAccepted: false,
  };

  state: State = {
    EULAAccepted: this.props.EULAAccepted,
    EEOAccepted: this.props.EEOAccepted,
    termsComponent: <EndUserLicenseAgreement />,
    decline: false,
    declineConfirmed: false,
  };

  handleAccept = () => {
    if (this.state.EULAAccepted) {
      if (!this.state.EEOAccepted) {
        this.setState({ EEOAccepted: true });
        // logic for redirect to successful account creation
      }
    } else {
      this.setState({ EULAAccepted: true });
      this.setState({ termsComponent: <EqualEmploymentOpportunity /> });
    }
  };

  handleDecline = () => {
    this.setState({ decline: true });
  };

  handleDeclineConfirmation = (confirmed:boolean) => {
    if (confirmed) {
      this.setState({ declineConfirmed: true });
    } else {
      this.setState({ decline: false });
    }
  };

  render() {
    if (this.state.declineConfirmed) {
      return <Redirect to="./" />;
    }
    return (
      <NormalLayout>
        <Grid container justify="center" direction="column" spacing={8}>
          <Grid item>
            <RegistrationHeader />
          </Grid>
          <Grid item>
            {this.state.termsComponent}
          </Grid>
          <Grid item container justify="center">
            <Button wide onClick={this.handleAccept}>Accept</Button>
          </Grid>
          <Grid item container justify="center">
            <Button wide variant="outlined" onClick={this.handleDecline}>Decline</Button>
          </Grid>
        </Grid>
        <Dialog open={this.state.decline} maxWidth="xs">
          <DialogContent>
            <ConfirmDecline handler={() => this.handleDeclineConfirmation} />
          </DialogContent>
        </Dialog>
      </NormalLayout>
    );
  }
};
