import React from 'react';
import qs from 'qs';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LegacyAccountContainer } from './django-auth/LegacyAccountContainer';
import config from '../../config';
import { WelcomeContainer } from './WelcomeContainer';
import { LoginContainer } from './login/LoginContainer';
import { SignupContainer } from './signup/SignupContainer';
import { AccountManagementContainer } from './account-management/AccountManagementContainer';
import { VerificationPageContainer } from './signup/VerificationPageContainer';
import { SwitchBusinessContainer } from './SwitchBusinessContainer';
import { AccountRestrictedRoute } from '../../common/account-restricted-route';


export class AccountContainer extends React.Component<any> {
  render() {
    return (
      <>
        {!config.enableCognito && (
        <Switch>
          <Route 
            path="/account/legacy" 
            render={(props) => {
              const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
              const { returnUrl } = query;
              return <LegacyAccountContainer returnUrl={returnUrl} {...props} />;
            }} 
          />
          <AccountRestrictedRoute
            accounts={config.accountsWithNewRegistration}
            redirect={`${config.lassoUrlBase}/register`}
            path="/account/welcome/:slug" 
            render={props => <Redirect to={`/account/legacy/welcome/${props.match.params.slug}`} />} 
          />
          <AccountRestrictedRoute
            accounts={config.accountsWithNewRegistration}
            redirect={`${config.lassoUrlBase}/register`} 
            path="/account/signup/:slug"
            render={props => <Redirect to={`/account/legacy/signup/${props.match.params.slug}`} />}
          />
          <Route 
            path="/account/login" 
            render={props => (
              <Redirect to={`/account/legacy/login${props.location.search}`} />
            )}
          />
          <Route path="/account/switch" component={SwitchBusinessContainer} />
        </Switch>      
        )}
        {config.enableCognito && (
        <Switch>
          <AccountRestrictedRoute
            accounts={config.accountsWithNewRegistration}
            redirect={`${config.lassoUrlBase}/register`} 
            path="/account/welcome"
            component={WelcomeContainer} 
          />
          <Route 
            path="/account/login" 
            render={(props) => {
              const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
              const returnUrl = query.returnUrl || '/profile';

              return <LoginContainer returnUrl={returnUrl} />;
            }}
          />
          <Route path="/account/signup" component={SignupContainer} />
          <Route path="/account/verify" component={VerificationPageContainer} />
          <Route path="/account/manage" component={AccountManagementContainer} />
        </Switch>
        )}
      </>
    );
  }
}
