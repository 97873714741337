import React from 'react';
import Auth from '@aws-amplify/auth';
import { SignUpParams } from '@aws-amplify/auth/lib/types';
import axios, { AxiosResponse } from 'axios';
import { Redirect } from 'react-router-dom';
import config from '../../../config';
import { SignupInitContainer } from './SignupInitContainer';
import { SignupCodeVerificationContainer } from './SignupCodeVerificationContainer';
import { createDjangoSession } from '../common/DjangoSessionContainer';
import { PolicyObject } from './service-terms/PolicyAgreementComponent';
import { LoadingComponent } from '../../../common/loading-component';
import { AppContext, User } from '../../core/AppContext';

interface Props {
  returnUrl: string;
}

export enum SignupStep {
  Init = 'INIT',
  Code = 'CODE',
  Complete = 'COMPLETE'
}

interface State {
  signupStep: SignupStep;
  signUpResult: any;
  signUpParams: SignUpParams;
  policiesNeeded: PolicyObject[];
  policiesLoading: boolean;
  error: boolean;
}

export class SignupContainer extends React.PureComponent<Props, State> {
  static contextType = AppContext;

  state: State = {
    signupStep: SignupStep.Init,
    signUpResult: null,
    policiesNeeded: [],
    policiesLoading: true,
    signUpParams: {} as SignUpParams,
    error: false
  }

  async componentDidMount() {
    try {
      const response: AxiosResponse = await axios(`${config.apiRoot}/internal_api/policies/`, {
        method: 'GET',
        withCredentials: true,
      });
      this.setState({ 
        policiesNeeded: response.data.policies, 
        policiesLoading: false 
      });
    } catch (e) {
      console.warn(e);
    }
  }

  private afterSignupInit = (signUpResult: any, params: SignUpParams, nextStep: SignupStep) => {    
    this.setState({ 
      signUpResult, signUpParams: params, signupStep: nextStep 
    });
  }

  private afterVerificationCode = async () => {    
    try {
      await Auth.signIn(this.state.signUpParams.username, this.state.signUpParams.password);
      await createDjangoSession();
      await this.acceptPolicies();
      // TODO: this.context.setUser(user);
    } catch (e) {
      this.setState({ error: true });
    }
  }

  private createAccountUserRelationship = async (): Promise<User> => {
    try {
      const response = await axios(`${config.apiRoot}/internal_api/create_aur`, {
        method: 'POST',
        withCredentials: true
      });
      
      return response.data.user;    
    } catch (e) {      
      console.error(e);
      throw e;
    }
  }

  private acceptPolicies = async () => {
    try {
      await axios(`${config.apiRoot}/internal_api/policies/accept/`, {
        method: 'POST',
        withCredentials: true,
        data: {
          policies: this.state.policiesNeeded.map((p : any) => p.id)
        }
      });
    } catch (e) {
      console.warn(e);
      this.setState({ error: true });
    }
  }

  render() {
    if (this.state.policiesLoading) {
      return <LoadingComponent />;
    }

    if (this.state.error) {
      return <Redirect to="/account/login" />;
    }

    switch (this.state.signupStep) {
      case SignupStep.Init: {
        return (
          <SignupInitContainer 
            policiesNeeded={this.state.policiesNeeded} 
            onComplete={this.afterSignupInit} 
          />
        );
      }
      case SignupStep.Code: {
        return (
          <SignupCodeVerificationContainer
            signUpParams={this.state.signUpParams}
            signUpResult={this.state.signUpResult} 
            onComplete={this.afterVerificationCode} 
          />
        );
      }
      case SignupStep.Complete: {
        return <Redirect to={this.props.returnUrl} />;
      }
      default: {
        return null;
      }
    }
  }
}
