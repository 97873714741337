import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '../../../common/button';
import { DialogConfirmation } from '../../../common/dialog-confirmation';
import styles from './profile-confirmation.module.scss';

interface Props {
  onClose(): void;
  onConfirm(): void;
  show: boolean;
}


export const ProfileConfirmationComponent: React.FC<Props> = ({ onClose, onConfirm, show }) => {
  const [clickedSubmit, setClickedSubmit] = useState(false);
  return (
    <DialogConfirmation
      showDialog={show}
      dialogTitle="Profile Confirmation"
      dialogBody={`Do you certify that the 
      information you provided in this profile is true and complete to the best of your knowledge?`}
      handleDialogClose={onClose}
    >
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={16}
        className={styles.buttonGrid}
      >
        <Grid item container justify="center">
          <Button 
            disabled={clickedSubmit} 
            wide 
            onClick={() => {
              setClickedSubmit(true);
              onConfirm();
            }} 
            className={styles.correctButton}
            data-test-id="profileconfirmation__button--yes"
          >
            Yes, it&apos;s correct
          </Button>
        </Grid>
        <Grid item container justify="center">
          <Button 
            wide 
            variant="outlined" 
            onClick={onClose} 
            className={styles.changesButton}
            disabled={clickedSubmit}
            data-test-id="profileconfirmation__button--no"
          >
            No, it needs changes
          </Button>
        </Grid>
      </Grid>
    </DialogConfirmation>
  );
};
