import React from 'react';
import { Redirect } from 'react-router-dom';
import { 
  AppContext, Account, IAppContext, User 
} from '../core/AppContext';
import { SwitchBusinessComponent } from './SwitchBusinessComponent';


interface State {
  redirect: string;
}

export class SwitchBusinessContainer extends React.PureComponent<any, State> {
  static contextType = AppContext;

  state: State = {
    redirect: ''
  }
  
  componentDidMount() {
    const appState = this.context as IAppContext;
    if (appState.user.accountRelationships.length === 1) {
      this.setAccount(appState.user.accountRelationships[0].account);
    }
  }

  private setAccount = (account: Account) => {
    this.context.setAppContext({ account });
    this.setState({ redirect: '/' });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    const { user }: { user: User } = this.context;
    return (
      <SwitchBusinessComponent 
        accounts={user.accountRelationships.map(aur => aur.account).sort((accA, accB) => {
          if (accA.name.toLowerCase() < accB.name.toLowerCase()) {
            return -1;
          } 
          if (accA.name.toLowerCase() === accB.name.toLowerCase()) {
            return 0;
          }
          return 1;          
        })}
        onSelectBusiness={this.setAccount} 
      />
    );
  }
}
